import {
  deleteIssue,
  getIssue,
  getIssues,
  getIssuesParams,
  updateIssue,
  UpdateIssuePayload,
} from '@/api/issues.api';
import { Issue, IssuesResponse } from '@/types/api/response/issues';
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const getIssuesQuery = (params: getIssuesParams = {}) =>
  queryOptions({
    queryKey: ['issues', params],
    queryFn: () => getIssues(params),
  });

export const useGetIssue = (issueId: number) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['issue', issueId],
    queryFn: async () => {
      const issuesQueries = queryClient.getQueriesData<IssuesResponse>({
        queryKey: ['issues'],
        exact: false,
      });

      for (const [, data] of issuesQueries) {
        const foundIssue = data?.entries.find((issue) => issue.issue_number === issueId);
        if (foundIssue) return foundIssue;
      }

      return getIssue(issueId);
    },
  });
};

export const useUpdateIssueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ issueId, updates }: { issueId: number; updates: UpdateIssuePayload }) => {
      return updateIssue(issueId, updates);
    },
    onMutate: async ({ issueId, updates }) => {
      await queryClient.cancelQueries({ queryKey: ['issue', issueId] });
      await queryClient.cancelQueries({ queryKey: ['issues'], exact: false });

      const previousIssue = queryClient.getQueryData<Issue>(['issue', issueId]);

      queryClient.setQueryData(['issue', issueId], (old: Issue) => ({
        ...old,
        ...updates,
      }));

      queryClient.setQueriesData(
        { queryKey: ['issues'], exact: false },
        (old: IssuesResponse | undefined) => {
          if (!old) return old;
          return {
            ...old,
            entries: old.entries.map((issue) =>
              issue.issue_number === issueId ? { ...issue, ...updates } : issue,
            ),
          };
        },
      );

      return { previousIssue };
    },
    onError: (err, { issueId }, context) => {
      if (context?.previousIssue) {
        queryClient.setQueryData(['issue', issueId], context.previousIssue);
      }
      queryClient.setQueriesData(
        { queryKey: ['issues'], exact: false },
        (old: IssuesResponse | undefined) => {
          if (!old) return old;
          return {
            ...old,
            entries: old.entries.map((issue) =>
              issue.issue_number === issueId ? context?.previousIssue : issue,
            ),
          };
        },
      );
    },
    onSuccess: (updatedIssue, { issueId }) => {
      queryClient.setQueryData(['issue', issueId], updatedIssue);

      queryClient.setQueriesData(
        { queryKey: ['issues'], exact: false },
        (old: IssuesResponse | undefined) => {
          if (!old) return old;
          return {
            ...old,
            entries: old.entries.map((issue) =>
              issue.issue_number === issueId ? updatedIssue : issue,
            ),
          };
        },
      );
    },
  });
};

export const useInvalidateIssuesQuery = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: ['issues'], exact: false });
};

export const useDeleteIssueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteIssue,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['issues'], exact: false });
    },
  });
};

export const useUpdateIssuesCache = () => {
  const queryClient = useQueryClient();

  return (updatedIssue: Issue) => {
    queryClient.setQueriesData(
      { queryKey: ['issues'], exact: false },
      (old: IssuesResponse | undefined) => {
        if (!old) {
          return { entries: [updatedIssue], total_count: 1, page_number: '1', total_pages: 1 };
        }

        const updatedEntries = old.entries.some(
          (issue) => issue.issue_number === updatedIssue.issue_number,
        )
          ? old.entries.map((issue) =>
              issue.issue_number === updatedIssue.issue_number ? updatedIssue : issue,
            )
          : [updatedIssue, ...old.entries];

        return {
          ...old,
          entries: updatedEntries,
          total_count: updatedEntries.length,
        };
      },
    );
  };
};

export const useDeleteIssueCache = () => {
  const queryClient = useQueryClient();

  return (deletedIssueId: string | number) => {
    queryClient.setQueriesData(
      { queryKey: ['issues'], exact: false },
      (old: IssuesResponse | undefined) => {
        if (!old) return old;

        const updatedEntries = old.entries.filter((issue) => issue.issue_number !== deletedIssueId);

        return {
          ...old,
          entries: updatedEntries,
          total_count: Math.max(old.total_count - 1, 0),
        };
      },
    );
  };
};
