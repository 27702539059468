import AssetSources from '@/GridComponents/AssetSources';
import { DomainResponse } from '@/types/api/response/domains';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { ElementWithActions } from '@/v2/components/ElementWithActions/ElementWithActions';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { getDomainsQuery } from '@/v2/queries/domains';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Filter } from 'lucide-react';
import { parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { Ellipsis } from '@/components/common/Ellipsis';

const Domains = () => {
  const location = useLocation();
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [domainSearchValue, setDomainSearchValue] = useQueryState(
    'domain_search_value',
    parseAsString.withDefault(''),
  );
  const [domainSearchType, setDomainSearchType] = useQueryState(
    'domain_search_type',
    parseAsString.withDefault('startswith'),
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams && searchParams.get('domain_search_value')) {
      const domainValue = searchParams.get('domain_search_value')?.split('/')[0];
      setDomainSearchValue(domainValue);
    }
  }, []);

  const searchParamsState = domainSearchValue
    ? { domainname: domainSearchValue, filter_type: domainSearchType, page }
    : { page };

  const { data, isLoading, error } = useQuery(getDomainsQuery(searchParamsState));

  const handleSelectDomains = (value: string | string[]) => {
    setDomainSearchValue(value as string);
    setPage(1);
  };

  const handleSelectDomainType = (value: string) => {
    setDomainSearchType(value);
    setPage(1);
  };

  const columns: ColumnDef<DomainResponse['entries'][0]>[] = [
    {
      header: 'Domain',
      accessorKey: 'domainname',
      cell: ({ row }) => {
        return (
          <div className="ml-2">
            <ElementWithActions
              className="text-sm font-medium"
              actions={[
                {
                  label: 'Set Domain Filter',
                  onClick: () => handleSelectDomains(row.original.domainname),
                  icon: <Filter className="h-4 w-4" />,
                },
              ]}
            >
              <Ellipsis maxLength={25} text={row.original.domainname} />
            </ElementWithActions>
          </div>
        );
      },
    },
    {
      header: 'DNS Record',
      accessorKey: 'dns_record',
      cell: ({ row }) => {
        const dnsRecord = row.original.dns_record;
        if (!dnsRecord)
          return <div className="text-sm font-medium text-gray-400">No DNS Record</div>;

        return (
          <div className="flex flex-col gap-1 items-start">
            <div className="flex flex-row gap-1 mb-2">
              <span className="text-muted-foreground font-medium">CNAME: </span>
              <Ellipsis maxLength={25} text={dnsRecord.cname || 'N/A'} />
            </div>
            <div className="grid grid-cols-3 gap-6 mb-2">
              {dnsRecord.ipaddress.map((ip) => (
                <div key={ip.id}>
                  <div className="flex flex-row gap-1">
                    <span className="text-muted-foreground font-medium"> IP: </span>
                    {ip.ipaddress}
                  </div>

                  {ip.asn && (
                    <div className="mt-2 mb-2">
                      <div className="flex flex-row gap-1">
                        <span className="text-muted-foreground font-medium">ASN: </span>
                        <Badge variant="outline">{ip.asn.owner}</Badge>
                      </div>
                      <div className="flex flex-row gap-1 mt-2">
                        <span className="text-muted-foreground font-medium">Range: </span>
                        {ip.asn.iprange}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      header: 'Sources',
      accessorKey: 'sources',
      cell: ({ row }) => {
        if (!row.original.sources || Object.keys(row.original.sources).length === 0)
          return <div className="text-sm font-medium text-gray-400">No Sources</div>;
        return <AssetSources entry={row.original} />;
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const filters: AvailableFilter[] = [
    {
      label: 'Domain',
      state: domainSearchValue,
      setState: handleSelectDomains,
      setValueType: handleSelectDomainType,
      valueType: domainSearchType,
      type: 'domainSearch',
      key: 'domain_search_value',
      placeholder: 'Search by domain',
      onClear: () => {
        setDomainSearchValue('');
        setDomainSearchType('startswith');
      },
      options: [
        {
          label: 'Starts with',
          value: 'startswith',
        },
        {
          label: 'Contains',
          value: 'contains',
        },
      ],
    },
  ];

  const handleClearAllFilters = () => {
    setDomainSearchValue('');
    setDomainSearchType('startswith');
  };

  return (
    <div className="mx-4 mt-4 max-w-[2000px]">
      <Helmet>
        <title>{` Assets > Domains `}</title>
      </Helmet>
      <div className="flex flex-row gap-4 w-full">
        <UniversalFilter
          filters={filters}
          clearAllFilters={handleClearAllFilters}
          className="mb-4"
        />
      </div>
      <DataTable
        columns={columns}
        data={data?.entries || []}
        currentPage={page}
        totalPages={data?.total_pages}
        totalEntries={data?.total_count}
        onPageChange={handlePageChange}
        loading={isLoading}
        error={error}
        tableHeight="calc(100vh - 11rem)"
      />
    </div>
  );
};

export default Domains;
