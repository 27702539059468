import fetchWrapper from '@/lib/fetchWrapper';
import {
  AssetsStatistics,
  IssuesResolutionStatistics,
  IssuesStatistics,
  RemediationStatistics,
  VulnerabilitiesStatistics,
  VulnerabilitiesTrend,
} from '../types/api/response/analytics';

export interface FetchVulnerabilitiesTrendParams {
  /** Start date in YYYY-MM-DD format */
  start_date: string;
  /** End date in YYYY-MM-DD format */
  end_date: string;
}

export const fetchVulnerabilitiesTrendApi = async (
  params: FetchVulnerabilitiesTrendParams,
): Promise<VulnerabilitiesTrend> => {
  const response = await fetchWrapper.get<VulnerabilitiesTrend>(`/resourcevulnerabilities/trends`, {
    params: {
      start_date: params.start_date,
      end_date: params.end_date,
    },
  });
  return response.data;
};

export interface FetchVulnerabilitiesStatisticsParams {
  aggregate: string;
  field: string;
  remediated_start_date?: string;
  remediated_end_date?: string;
  dashboard_period?: number;
}

export const fetchVulnerabilitiesStatisticsApi = async (
  params: FetchVulnerabilitiesStatisticsParams,
): Promise<VulnerabilitiesStatistics | RemediationStatistics> => {
  const params_body: Record<string, string | number> = {
    aggregate: params.aggregate,
    field: params.field,
  };

  if (params.remediated_start_date) {
    params_body.remediated_start_date = params.remediated_start_date;
  }

  if (params.remediated_end_date) {
    params_body.remediated_end_date = params.remediated_end_date;
  }

  if (params.dashboard_period) {
    params_body.dashboard_period_optional = params.dashboard_period;
  }

  const response = await fetchWrapper.get<VulnerabilitiesStatistics | RemediationStatistics>(
    `/vulnerabilities/statistics`,
    {
      params: params_body,
    },
  );
  return response.data;
};

export interface FetchAssetsStatisticsParams {
  start_date: string;
  end_date: string;
  dashboard_period: number;
}

export interface FetchIssuesResolutionStatisticsParams {
  dashboard_period: number;
}

export const fetchAssetsStatisticsApi = async (
  params: FetchAssetsStatisticsParams,
): Promise<AssetsStatistics> => {
  const response = await fetchWrapper.get<AssetsStatistics>(`/assets/statistics`, {
    params: {
      start_date: params.start_date,
      end_date: params.end_date,
      dashboard_period: params.dashboard_period,
    },
  });
  return response.data;
};

export interface FetchIssuesStatisticsParams {
  dashboard_period: number;
}

export const fetchIssuesStatisticsApi = async (
  params: FetchIssuesStatisticsParams,
): Promise<IssuesStatistics> => {
  const response = await fetchWrapper.get<IssuesStatistics>('/issues/statistics', {
    params: {
      dashboard_period: params.dashboard_period,
    },
  });
  return response.data;
};

export const fetchIssuesResolutionStatisticsApi = async (
  params: FetchIssuesResolutionStatisticsParams,
): Promise<IssuesResolutionStatistics> => {
  const response = await fetchWrapper.get<IssuesResolutionStatistics>(
    '/issues/resolution-statistics',
    {
      params: {
        dashboard_period: params.dashboard_period,
      },
    },
  );
  return response.data;
};
