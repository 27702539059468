import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { useToast } from '@/hooks/use-toast';
import { cn } from '@/lib/utils';
import { Mail } from 'lucide-react';

interface ContactSupportProps {
  className?: string;
  text?: string;
  title?: string;
  buttonText?: string;
  buttonClassName?: string;
  buttonVariant?: 'default' | 'outline' | 'secondary' | 'ghost' | 'link';
  showIcon?: boolean;
  buttonIcon?: React.ReactNode;
}

export const ContactSupport = ({
  className,
  text,
  title,
  buttonText,
  buttonClassName,
  buttonVariant = 'outline',
  showIcon = true,
  buttonIcon,
}: ContactSupportProps) => {
  const { toast } = useToast();
  return (
    <div className={cn('p-5 mt-auto border-t bg-muted/20', className)}>
      <Dialog>
        <DialogTrigger asChild>
          <Button variant={buttonVariant} className={cn('w-full group', buttonClassName)}>
            {buttonIcon ||
              (showIcon && <Mail className="h-4 w-4 mr-2 group-hover:animate-pulse" />)}
            {buttonText || 'Contact Support'}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-xl m-auto">{title || 'Need Help?'}</DialogTitle>
            <DialogDescription className="pt-4">
              <div className="flex flex-col items-center space-y-4">
                <div className="bg-[#FF6B53]/10 p-3 rounded-full">
                  <Mail className="h-6 w-6 text-[#FF6B53]" />
                </div>
                {text && <p className="text-center text-base w-2/3 mb-4">{text}</p>}
                {!text && (
                  <p className="text-center text-base w-2/3 mb-4 p-3">
                    Reach out to our support team at{' '}
                    <span
                      className="font-medium underline cursor-pointer"
                      title="Copy email"
                      onClick={() => {
                        navigator.clipboard.writeText('support@specular.ai');
                        toast({
                          title: 'Email copied',
                          description: 'Support email copied to clipboard',
                          duration: 2000,
                        });
                      }}
                    >
                      support@specular.ai
                    </span>
                  </p>
                )}
                <img src="/static/logos/png/logo_blue.png" alt="Specular Logo" className="h-4" />
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};
