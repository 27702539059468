import { Ellipsis } from '@/components/common/Ellipsis';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction.tsx';
import NavSpecularLogo from '@/v2/icons/nav-specular-logo.svg?react';
import { useFetchChatHistory, useResetConversation, useStreamMessage } from '@/v2/queries/messages';
import {
  Bug,
  ClipboardList,
  Globe,
  Link,
  Link as LinkIcon,
  RefreshCcw,
  Search,
  Sparkles,
  X,
} from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Resizable } from 're-resizable';

interface Message {
  chatId: string;
  role: 'user' | 'assistant';
  content: string;
  status: 'sending' | 'sent' | 'error';
}

interface Context {
  type: 'issue' | 'vulnerability' | 'nuclei' | 'httpasset' | 'ipaddress';
  id?: string;
  name: string;
  from?: string;
  url?: string;
  title?: string;
}

interface ExpertChatProps {
  onClose?: () => void;
  onOpen?: () => void;
  className?: string;
  chatId: string;
  title: string;
  context: Context[];
}

export default function ExpertChat({ chatId, title, context, onClose, onOpen }: ExpertChatProps) {
  const { mutate: sendMessage, isPending, isError, error } = useStreamMessage();
  const { mutate: resetConversation } = useResetConversation(chatId);
  const { data: chatHistory, isLoading: isChatHistoryLoading } = useFetchChatHistory(chatId);

  const [isMinimized, setIsMinimized] = useState(false);
  const [input, setInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamingContent, setStreamingContent] = useState('');
  const defaultMessage = {
    chatId,
    role: 'assistant' as Message['role'],
    content: `Hello! I'm here to help you with the issue: "${title.slice(0, 20) + '...'}". What would you like to know?`,
    status: 'sent' as Message['status'],
  };

  const [messages, setMessages] = useState<Message[]>([defaultMessage]);

  useEffect(() => {
    if (chatHistory?.history) {
      setMessages([
        defaultMessage,
        ...chatHistory.history.map(
          (msg): Message => ({
            chatId: msg.chatId,
            role: msg.role,
            content: msg.content,
            status: 'sent',
          }),
        ),
      ]);
    }
  }, [chatHistory, chatId, title]);

  useEffect(() => {
    if (!isPending && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === 'user' && lastMessage.status === 'sending') {
        setMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[updatedMessages.length - 1] = {
            ...lastMessage,
            status: isError ? 'error' : 'sent',
          };
          return updatedMessages;
        });
      }
    }
  }, [isPending, isError, messages]);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // Focus input when chat is opened or after sending a message
  useEffect(() => {
    if (!isMinimized && !isPending) {
      inputRef.current?.focus();
    }
  }, [isMinimized, isPending]);

  // Handle streaming chunks
  useEffect(() => {
    const handleStreamChunk = (event: CustomEvent<{ content: string; chatId: string }>) => {
      if (event.detail.chatId === chatId) {
        setStreamingContent(event.detail.content);
        // Scroll to bottom on each chunk
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };

    window.addEventListener('ai-message-chunk', handleStreamChunk as EventListener);
    return () => {
      window.removeEventListener('ai-message-chunk', handleStreamChunk as EventListener);
    };
  }, [chatId]);

  // Keyboard shortcuts
  useHotkeys('esc', () => setIsMinimized(true), {
    enabled: !isMinimized,
    enableOnFormTags: true,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return; // Allow new line
      }
      e.preventDefault();
      handleSubmit(e as unknown as React.FormEvent);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isPending) return;

    const userMessage: Message = {
      chatId,
      role: 'user',
      content: input.trim(),
      status: 'sending',
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput('');

    // Add an initial assistant message for streaming
    setMessages((prev) => [
      ...prev,
      {
        chatId,
        role: 'assistant',
        content: '',
        status: 'sending',
      },
    ]);
    setIsStreaming(true);
    setStreamingContent('');

    sendMessage(
      {
        chatId,
        content: userMessage.content,
      },
      {
        onSuccess: (response) => {
          setMessages((prev) => {
            const newMessages = [...prev];
            newMessages[newMessages.length - 1] = {
              chatId: response.chatId,
              role: response.role,
              content: response.content,
              status: 'sent',
            };
            return newMessages;
          });
          setIsStreaming(false);
          setStreamingContent('');
        },
        onError: () => {
          setIsStreaming(false);
          setStreamingContent('');
        },
      },
    );
  };

  const handleClose = () => {
    setIsMinimized(true);
    onClose?.();
  };

  const handleRestore = () => {
    setIsMinimized(false);
    onOpen?.();
  };

  const handleResetConversation = () => {
    resetConversation(undefined, {
      onSuccess: () => {
        setMessages([defaultMessage]);
      },
    });
  };

  const renderContext = (context: Context) => {
    if (context.type === 'issue') {
      return (
        <Badge variant="secondary" className="text-xs flex items-center gap-1">
          <ClipboardList className="h-3 w-3" />
          <Ellipsis text={context.name} maxLength={30} />
        </Badge>
      );
    }
    if (context.type === 'vulnerability') {
      return context.name ? (
        <>
          <LinkWithAction
            to={context.url || `/vulnerabilities/${context.name}?from=${context.from}`}
            dataClickBypass={true}
            title={context.title || 'View CVE details'}
            actions={[]}
            showAsButton={true}
            buttonIcon={<LinkIcon className="h-4 w-4" />}
            buttonFontClass="font-bold text-xs"
          >
            {context.name}
          </LinkWithAction>
        </>
      ) : null;
    }
    if (context.type === 'nuclei') {
      return context.name ? (
        <Badge variant="secondary" className="text-xs flex items-center gap-1">
          <Bug className="h-3 w-3" />
          {context.name.trim().slice(0, 20) + (context.name.length > 20 ? '...' : '')}
        </Badge>
      ) : null;
    }
    if (context.type === 'httpasset') {
      return context.name ? (
        <LinkWithAction
          to={`/assets/${context.id}?from=${context.from}`}
          dataClickBypass={true}
          title="View Asset details"
          actions={[]}
          showAsButton={true}
          buttonIcon={<Globe className="h-4 w-4" />}
          buttonFontClass="font-bold text-xs"
        >
          {context.name}
        </LinkWithAction>
      ) : null;
    }
  };

  const renderMessage = (message: Message, index: number) => {
    // If this is the last message and we're streaming, show the streaming content
    const content =
      isStreaming && index === messages.length - 1 ? streamingContent : message.content;

    return (
      <div key={`${message.chatId}-${index}`} className="py-4 first:pt-4 last:pb-0">
        {message.role === 'user' ? (
          <div className="flex justify-end gap-2 text-sm items-start">
            <div className="bg-gray-200 text-accent-foreground rounded-lg px-4  max-w-[85%]">
              <div className="prose-sm break-words">
                <MarkdownViewer markdown={content} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex gap-2 text-sm items-start">
            <div className="bg-muted h-6 w-6 rounded-full flex items-center justify-center flex-shrink-0">
              <NavSpecularLogo className="h-4 w-4" />
            </div>
            <div
              className={cn(
                'bg-accent/50 rounded-lg px-4 py-2 max-w-[85%]',
                'prose-sm prose-pre:bg-muted prose-pre:p-4 prose-pre:rounded-lg',
                message.status === 'error' && 'bg-destructive/10 text-destructive',
              )}
            >
              <MarkdownViewer markdown={content} />

              {isStreaming && index === messages.length - 1 && (
                <span className="inline-block w-1 h-4 ml-1 bg-primary animate-pulse" />
              )}

              {message.role === 'assistant' && content.includes('Related resources:') && (
                <div className="mt-2 not-prose space-y-1">
                  <div className="flex items-center gap-2 text-sm">
                    <Link className="h-4 w-4" />
                    <a href="#" className="text-primary hover:underline">
                      View affected host
                    </a>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <Search className="h-4 w-4" />
                    <a href="#" className="text-primary hover:underline">
                      Search Perplexity
                    </a>
                  </div>
                </div>
              )}
              {message.status === 'error' && (
                <div className="text-xs text-destructive mt-1">Failed to send message</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderChatContent = () => (
    <Sheet open={!isMinimized} onOpenChange={(open) => !open && handleClose()} modal={false}>
      <SheetContent
        side="right"
        className={cn(
          'p-0 shadow-none border-none h-[calc(100vh-56px)] mr-2 mt-auto',
          '[&>button:first-child]:hidden',
          'flex justify-end',
        )}
        style={{ width: 'auto' }}
        onInteractOutside={(event) => event.preventDefault()}
      >
        <Resizable
          defaultSize={{
            height: '100%',
            width: 400,
          }}
          minWidth={400}
          maxWidth={800}
          enable={{ left: true }}
          handleStyles={{
            left: {
              width: '10px',
              backgroundColor: 'transparent',
              cursor: 'col-resize',
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
            },
          }}
          style={{
            position: 'relative',
            marginLeft: 'auto',
            border: '1px solid #e0e0e0',
          }}
        >
          <div className="h-full flex flex-col bg-card">
            <div className="flex-none flex flex-row items-center justify-between space-y-0 p-4 border-b">
              <div className="text-base font-semibold flex items-center gap-2">
                <NavSpecularLogo className="h-5 w-5" />
                Specular AI
              </div>
              <div className="flex items-center gap-1">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={handleResetConversation}
                  aria-label="Reset conversation"
                >
                  <RefreshCcw className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={handleClose}
                  aria-label="Close chat"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </div>
            <ScrollArea className="flex-1">
              <div className="divide-y px-4">
                {isChatHistoryLoading ? (
                  <div className="space-y-4 py-4">
                    <div className="flex gap-2">
                      <Skeleton className="h-6 w-6 rounded-full flex-shrink-0" />
                      <div className="space-y-2 flex-1">
                        <Skeleton className="h-4 w-3/4" />
                        <Skeleton className="h-4 w-1/2" />
                      </div>
                    </div>
                    <div className="flex justify-end gap-2">
                      <div className="space-y-2 w-3/4">
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-2/3 ml-auto" />
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <Skeleton className="h-6 w-6 rounded-full flex-shrink-0" />
                      <div className="space-y-2 flex-1">
                        <Skeleton className="h-4 w-1/2" />
                        <Skeleton className="h-4 w-3/4" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {messages.map(renderMessage)}
                    <div ref={messagesEndRef} />
                  </>
                )}
              </div>
            </ScrollArea>
            <form onSubmit={handleSubmit} className="flex-none p-4 space-y-4 border-t bg-card">
              <div className="space-y-2">
                <div className="flex flex-wrap gap-2">
                  {context.map((item) => renderContext(item))}
                </div>
                <Textarea
                  ref={inputRef}
                  placeholder="Ask about this issue... (Enter to send, Shift+Enter for new line)"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="min-h-[80px] resize-none"
                  disabled={isPending || isChatHistoryLoading}
                />
              </div>
              {isError && (
                <span className="text-sm text-destructive">
                  {error instanceof Error ? error.message : 'Failed to send message'}
                </span>
              )}
            </form>
          </div>
        </Resizable>
      </SheetContent>
    </Sheet>
  );

  if (isMinimized) {
    return (
      <div className="fixed right-2 bottom-0 h-full flex items-center group">
        <div className="bg-v2-orange border border-v2-orange/50 p-2 rounded-l-lg shadow-lg flex flex-col gap-2">
          <Button
            variant="ghost"
            aria-label="Restore chat"
            className="group-hover:brightness-110 transition-all hover:bg-v2-orange/50"
            onClick={handleRestore}
          >
            <Sparkles className="h-8 w-8 text-white" />
            <span className="text-white font-medium text-sm">Specular AI</span>
          </Button>
        </div>
      </div>
    );
  }

  return renderChatContent();
}
