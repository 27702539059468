import { GetVulnerabilitiesScanResultsParams } from '@/api/investigations.api';
import { Ellipsis } from '@/components/common/Ellipsis';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { VulnerabilitiesScanResultsEntry } from '@/types/api/response/investigations';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { getVulnerabilitiesScanResultsQuery } from '@/v2/queries/investigation';
import { formatShortDateTime } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Globe } from 'lucide-react';
import { parseAsInteger, useQueryState } from 'nuqs';

export default function InfosTable({ assetId }: { assetId: number }) {
  const [page, setPage] = useQueryState('infos_page', parseAsInteger.withDefault(1));

  const getVulnerabilitiesScanResultsQueryParams: GetVulnerabilitiesScanResultsParams = {
    page,
    http_asset: assetId,
    severity: 'info',
  };

  const { data: infos, isFetching } = useQuery(
    getVulnerabilitiesScanResultsQuery(getVulnerabilitiesScanResultsQueryParams),
  );

  const { navigateWithParams } = useNavigateWithParams();

  const columns: ColumnDef<VulnerabilitiesScanResultsEntry>[] = [
    {
      header: 'Hostname',
      accessorKey: 'http_asset.hostname',
      cell: ({ row }) => (
        <LinkWithAction
          to={navigateWithParams('assets', assetId.toString(), 'investigation')}
          dataClickBypass={true}
          title="View Asset details"
          actions={[]}
          showAsButton={true}
          buttonIcon={<Globe className="h-4 w-4" />}
        >
          {row.original.domain}
        </LinkWithAction>
      ),
    },
    {
      header: 'Port',
      accessorKey: 'port',
      cell: ({ row }) => <div className="text-left">{row.original.port}</div>,
    },
    {
      header: () => <div className="text-left">Name</div>,
      accessorKey: 'vulnerability_name',
      cell: ({ row }) => <Ellipsis text={row.original.name} maxLength={50} />,
    },
    {
      header: () => <div className="text-left">Description</div>,
      accessorKey: 'vulnerability_name',
      cell: ({ row }) => <Ellipsis text={row.original.description || '-'} maxLength={50} />,
    },
    {
      id: 'first_identified',
      accessorFn: (row) => row.first_identified,
      header: () => <div className="text-left">First Identified</div>,
      cell: ({ row }) => (
        <span className="text-xs">{formatShortDateTime(row.original.first_identified)}</span>
      ),
    },
    {
      id: 'last_seen',
      accessorFn: (row) => row.scanned_at,
      header: () => <div className="text-left">Service Last Seen</div>,
      cell: ({ row }) => (
        <span className="text-xs">{formatShortDateTime(row.original.scanned_at)}</span>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  if (isFetching) return null;

  return (
    <div className="mx-4 max-w-[2000px] flex flex-col gap-2">
      <div className="w-full flex flex-col gap-4">
        <DataTable
          columns={columns}
          data={infos.entries}
          loading={isFetching}
          currentPage={page}
          totalPages={1}
          totalEntries={infos.entries.length}
          onPageChange={handlePageChange}
          error={null}
          tableHeight="calc(100vh - 15rem)"
          maxWidth="w-full"
          onRowClick={null}
        />
      </div>
    </div>
  );
}
