import React from 'react';
import { NotesList } from '@/v2/components/Notes/NotesList';
import { useAuth } from '@/Helpers/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { useDeleteNoteMutation, getUserNotesQuery, useDeleteNoteCache } from '@/v2/queries/notes';
import { toast } from '@/hooks/use-toast';

interface NotesTabDisplayProps {
  assetId: number;
  hostname: string;
  showCreateNoteButton?: boolean;
}

const NotesTabDisplay: React.FC<NotesTabDisplayProps> = ({
  assetId,
  hostname,
  showCreateNoteButton = true,
}) => {
  const { userData } = useAuth();
  const { mutate: deleteNote } = useDeleteNoteMutation();
  const deleteNoteCache = useDeleteNoteCache();

  const { data: notes = [], isPending: isLoading } = useQuery(
    getUserNotesQuery({ httpAsset: assetId }),
  );

  const handleDeleteNote = async (noteId: string) => {
    deleteNote(noteId);
    deleteNoteCache(noteId);
    toast({
      title: 'Note deleted',
      description: (
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">Note deleted successfully</span>
          </div>
        </div>
      ),
      duration: 5000,
    });
  };

  return (
    <div>
      <NotesList
        notes={notes}
        onDelete={handleDeleteNote}
        assetId={assetId}
        hostname={hostname}
        currentUserEmail={userData.email}
        showCreateNoteButton={showCreateNoteButton}
        isLoading={isLoading}
      />
    </div>
  );
};

export default NotesTabDisplay;
