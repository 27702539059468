import { useAuth } from '@/Helpers/AuthContext';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';
import { Loader2, Lock, Shield } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useToast } from '@/hooks/use-toast';
import { startMfaSetup, verifyMfaCode, disableMfa } from '@/api/mfa.api';
import QRCode from 'qrcode';
import { useNavigate } from 'react-router-dom';

export const CustomInputOTPGroup = (
  <InputOTPGroup>
    <InputOTPSlot index={0} className="h-12 w-12 text-2xl" />
    <InputOTPSlot index={1} className="h-12 w-12 text-2xl" />
    <InputOTPSlot index={2} className="h-12 w-12 text-2xl" />
    <InputOTPSlot index={3} className="h-12 w-12 text-2xl" />
    <InputOTPSlot index={4} className="h-12 w-12 text-2xl" />
    <InputOTPSlot index={5} className="h-12 w-12 text-2xl" />
  </InputOTPGroup>
);

export default function SecuritySettings() {
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [isSetupDialogOpen, setIsSetupDialogOpen] = useState(false);
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [mfaData, setMfaData] = useState<Awaited<ReturnType<typeof startMfaSetup>> | null>(null);
  const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const { toast } = useToast();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (userData?.mfa_enabled) {
      setIsMfaEnabled(true);
    }
  }, [userData?.mfa_enabled]);

  useEffect(() => {
    if (mfaData?.qr_code) {
      QRCode.toDataURL(mfaData.qr_code)
        .then((url) => {
          setQrCodeUrl(url);
        })
        .catch((err) => {
          console.error('Error generating QR code:', err);
          toast({
            title: 'Error generating QR code',
            description: 'Please try again.',
            variant: 'destructive',
            duration: 3000,
          });
        });
    }
  }, [mfaData?.qr_code, toast]);

  async function handleEnableMfa() {
    try {
      setIsLoading(true);
      const data = await startMfaSetup();
      setMfaData(data);
      setIsSetupDialogOpen(true);
    } catch {
      toast({
        title: 'Failed to start MFA setup',
        description: 'Please try again.',
        variant: 'destructive',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleVerifyMfa() {
    if (!verificationCode) {
      toast({
        title: 'Verification code required',
        description: 'Please enter the 6-digit code from your authenticator app.',
        variant: 'destructive',
        duration: 3000,
      });
      return;
    }

    setIsVerifying(true);
    try {
      const data = await verifyMfaCode(verificationCode);
      setBackupCodes(data.backup_codes);
      setIsMfaEnabled(true);
      setIsSetupDialogOpen(false);
      toast({
        title: 'MFA enabled successfully',
        description: 'Your account is now protected with two-factor authentication.',
        duration: 3000,
      });
    } catch {
      toast({
        title: 'Failed to verify MFA code',
        description: 'Please try again.',
        variant: 'destructive',
        duration: 3000,
      });
    } finally {
      setIsVerifying(false);
    }
  }

  async function handleDisableMfa() {
    if (!verificationCode) {
      toast({
        title: 'Verification code required',
        description: 'Please enter the 6-digit code from your authenticator app.',
        variant: 'destructive',
        duration: 3000,
      });
      return;
    }

    setIsVerifying(true);
    try {
      await disableMfa(verificationCode);
      setIsMfaEnabled(false);
      setIsDisableDialogOpen(false);
      setVerificationCode('');
      toast({
        title: 'MFA disabled successfully',
        description: 'Two-factor authentication has been disabled for your account.',
        duration: 3000,
      });
    } catch {
      toast({
        title: 'Failed to disable MFA',
        description: 'Please check your verification code and try again.',
        variant: 'destructive',
        duration: 3000,
      });
    } finally {
      setIsVerifying(false);
    }
  }

  return (
    <div className="container max-w-4xl px-6 pb-8">
      <h1 className="text-2xl font-bold mb-8">Security Settings</h1>

      <div className="space-y-6">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Shield className="h-5 w-5" />
              Two-Factor Authentication
            </CardTitle>
            <CardDescription className="font-medium">
              Add an extra layer of security to your account by enabling two-factor authentication.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-muted-foreground">
                  {isMfaEnabled
                    ? 'Two-factor authentication is currently enabled for your account.'
                    : 'Two-factor authentication is currently disabled for your account.'}
                </p>
              </div>
              <Button
                variant={isMfaEnabled ? 'destructive' : 'default'}
                onClick={isMfaEnabled ? () => setIsDisableDialogOpen(true) : handleEnableMfa}
              >
                {isLoading ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : isMfaEnabled ? (
                  'Disable 2FA'
                ) : (
                  'Enable 2FA'
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Lock className="h-5 w-5" />
              Change Password
            </CardTitle>
            <CardDescription className="font-medium">
              Change your password to protect your account.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-muted-foreground">
                  You will be redirected to the login page to change your password.
                </p>
              </div>
              <Button
                variant="default"
                onClick={() => {
                  navigate('/change-password');
                }}
              >
                {isLoading ? <Loader2 className="w-4 h-4 animate-spin" /> : 'Change Password'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      <Dialog open={isSetupDialogOpen} onOpenChange={setIsSetupDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Set up Two-Factor Authentication</DialogTitle>
            <DialogDescription>
              Scan this QR code with your authenticator app (like Google Authenticator or Authy) to
              enable 2FA.
            </DialogDescription>
          </DialogHeader>

          <div className="flex flex-col items-center gap-4 py-2">
            {qrCodeUrl && (
              <img
                src={qrCodeUrl}
                alt="MFA QR Code"
                className="w-48 h-48 rounded-lg border border-gray-200"
              />
            )}
            <p className="text-sm text-muted-foreground">
              Enter the 6-digit code from your authenticator app to verify and enable 2FA.
            </p>
            <InputOTP
              maxLength={6}
              value={verificationCode}
              onChange={setVerificationCode}
              pattern="[0-9]*"
              inputMode="numeric"
              className="text-2xl w-full"
              autoFocus
              autoComplete="off"
              data-form-type="other"
            >
              {CustomInputOTPGroup}
            </InputOTP>
          </div>

          <DialogFooter>
            <div className="flex flex-row gap-2 justify-center w-full">
              <Button
                variant="outline"
                onClick={() => setIsSetupDialogOpen(false)}
                disabled={isVerifying}
              >
                Cancel
              </Button>
              <Button
                onClick={handleVerifyMfa}
                disabled={isVerifying || verificationCode.length !== 6}
              >
                {isVerifying ? 'Verifying...' : 'Verify and Enable'}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isDisableDialogOpen} onOpenChange={setIsDisableDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
            <DialogDescription>
              Enter your 6-digit verification code to disable two-factor authentication.
            </DialogDescription>
          </DialogHeader>

          <div className="flex flex-col justify-center text-center items-center gap-4">
            <div className="flex flex-col justify-center items-center w-full">
              <InputOTP
                maxLength={6}
                value={verificationCode}
                onChange={setVerificationCode}
                pattern="[0-9]*"
                inputMode="numeric"
                className="text-2xl w-full"
                autoFocus
                autoComplete="off"
                data-form-type="other"
              >
                {CustomInputOTPGroup}
              </InputOTP>
            </div>
          </div>

          <DialogFooter>
            <div className="flex flex-row gap-2 justify-center w-full">
              <Button
                variant="outline"
                onClick={() => {
                  setIsDisableDialogOpen(false);
                  setVerificationCode('');
                }}
                disabled={isVerifying}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={handleDisableMfa}
                disabled={isVerifying || verificationCode.length !== 6}
              >
                {isVerifying ? 'Verifying...' : 'Disable 2FA'}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {backupCodes.length > 0 && (
        <Dialog open={true} onOpenChange={() => setBackupCodes([])}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>Backup Codes</DialogTitle>
              <DialogDescription>
                Save these backup codes in a secure place. You can use them to access your account
                if you lose your authenticator device.
              </DialogDescription>
            </DialogHeader>

            <div className="bg-muted p-4 rounded-lg">
              <ul className="space-y-2">
                {backupCodes.map((code, index) => (
                  <li key={index} className="font-mono text-sm">
                    {code}
                  </li>
                ))}
              </ul>
            </div>

            <DialogFooter>
              <div className="flex flex-row gap-2 justify-center w-full">
                <Button onClick={() => setBackupCodes([])}>I have saved these codes</Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
