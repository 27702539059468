import { AuthProvider } from '@/Helpers/AuthContext';
import '@/common/index.css';
import '@/common/sentry-setup';
import { Toaster } from '@/components/ui/toaster';
import { FeatureFlagProvider } from '@/context/FeatureFlagContext';
import { AnalyticsProvider } from '@/providers/AnalyticsProvider';
import '@fontsource/lato/700.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import { RouterProvider } from 'react-router-dom';
import { useQueryBaseConfig } from './baseConfig';
import { router } from './views/router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: useQueryBaseConfig,
  },
});

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <FeatureFlagProvider>
          <AnalyticsProvider>
            <NuqsAdapter>
              <RouterProvider router={router} />
              <Toaster />
              <ReactQueryDevtools />
            </NuqsAdapter>
          </AnalyticsProvider>
        </FeatureFlagProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default Root;
