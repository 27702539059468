import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { ArrowDown, BrainCircuit, FileCode, ListChecks, Shield, Terminal } from 'lucide-react';
import { useState } from 'react';
import { ReferencesCard } from '../GenAITabs/AIReferences';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';

interface ValidationStep {
  step: string;
}

interface AIValidationProps {
  brief?: string;
  description?: string;
  steps?: ValidationStep[] | string[];
  code?: string;
  validationReferences?: string[];
  curlCommand?: string;
  response?: string;
  aiThreatActorActivity?: string;
  aiThreatActorActivityReferences?: string[] | null;
  aiKEV?: boolean;
}

export function AIValidationSection({
  brief,
  description,
  steps,
  code,
  validationReferences,
  curlCommand,
  response,
  aiThreatActorActivity,
  aiThreatActorActivityReferences,
  aiKEV,
}: AIValidationProps) {
  const isExploit = !!code;
  const [showResponse, setShowResponse] = useState(false);

  return (
    <Card className="h-full w-full overflow-y-auto min-h-[calc(100vh-32rem)]">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2">
            <BrainCircuit className="h-5 w-5 text-red-500" />
            <CardTitle>Analysis</CardTitle>
          </div>
        </CardTitle>
        <CardDescription>
          <span className="flex items-center gap-2">
            AI-powered vulnerability analysis with validation steps and threat insights.
            <InfoTooltip
              content="Comprehensive AI analysis providing detailed insights into the vulnerability's
                    nature, including verification procedures, potential exploitation methods, and
                    observed threat patterns."
            />
          </span>
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {brief || description ? (
            <Alert variant="destructive" className="bg-orange-50 border-orange-200">
              <div className="flex items-center gap-2">
                <Terminal className="h-4 w-4 text-orange-500" />
                <AlertTitle className="text-orange-700">Overview</AlertTitle>
              </div>
              <AlertDescription className="text-orange-900">
                <MarkdownViewer
                  markdown={brief || description}
                  classNameBody="text-sm text-orange-900 -mt-2 -mb-4"
                />
              </AlertDescription>
            </Alert>
          ) : (
            <div className="flex flex-col gap-4 items-center justify-center p-4">
              <div className="text-gray-500">No AI Analysis available yet</div>
            </div>
          )}

          {curlCommand && (
            <Card className="min-w-0">
              <CardHeader>
                <CardTitle>CURL Command</CardTitle>
              </CardHeader>
              <CardContent>
                <MarkdownViewer
                  markdown={`\`\`\`bash\n${curlCommand}\n\`\`\``}
                  classNameBody="-mt-6"
                />
                {response && (
                  <div className="mt-4">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setShowResponse(!showResponse)}
                      className="w-full justify-between bg-gray-50 hover:bg-gray-100"
                    >
                      Response
                      <ArrowDown
                        className={cn('h-4 w-4 transition-transform', showResponse && 'rotate-180')}
                      />
                    </Button>
                    {showResponse && (
                      <div className="mt-2">
                        <MarkdownViewer markdown={`\`\`\`text\n${response}\n\`\`\``} />
                      </div>
                    )}
                  </div>
                )}
              </CardContent>
            </Card>
          )}

          {brief || description ? (
            <>
              {aiThreatActorActivity && aiKEV ? (
                <Card className="min-w-0">
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <Shield className="h-5 w-5 text-red-500" />
                      Threat Actor Activity
                    </CardTitle>
                    <CardDescription>
                      Known threat actor activity related to this vulnerability
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="grid gap-2">
                    <MarkdownViewer markdown={aiThreatActorActivity} className="-mt-4" />
                    {aiKEV &&
                      aiThreatActorActivityReferences &&
                      aiThreatActorActivityReferences.length > 0 && (
                        <ReferencesCard
                          references={aiThreatActorActivityReferences}
                          tab="analysis"
                          defaultOpen={false}
                        />
                      )}
                  </CardContent>
                </Card>
              ) : (
                <Card className="min-w-0">
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <Shield className="h-5 w-5 text-red-500" />
                      Threat Actor Activity
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="grid gap-6">
                    <span className="text-sm text-muted-foreground">
                      No known threat actor activity related to this vulnerability has been reported
                      in the wild.
                    </span>
                  </CardContent>
                </Card>
              )}
            </>
          ) : null}

          {steps && steps.length > 0 && (
            <Card className="min-w-0">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <ListChecks className="h-5 w-5 text-red-500" />
                  {isExploit ? 'Proof of Concept' : 'Validation Steps'}
                </CardTitle>
                <CardDescription>
                  Step-by-step {isExploit ? 'exploitation' : 'validation'} process with examples
                </CardDescription>
              </CardHeader>
              <CardContent className="grid gap-6">
                {steps.map((step, idx) => (
                  <div key={idx} className="grid gap-2 min-w-0">
                    <div className="flex gap-3 items-start min-w-0">
                      <Badge
                        variant="outline"
                        className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-red-50 text-red-700 border-red-200"
                      >
                        {idx + 1}
                      </Badge>
                      <MarkdownViewer
                        className="min-w-0 -mt-6"
                        markdown={typeof step === 'string' ? step : step.step}
                      />
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}

          {code && (
            <Card className="min-w-0">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <FileCode className="h-5 w-5 text-red-500" />
                  Exploit Code
                </CardTitle>
                <CardDescription>AI generated proof of concept code</CardDescription>
              </CardHeader>
              <CardContent>
                <MarkdownViewer markdown={`\`\`\`python\n${code}\n\`\`\``} />
              </CardContent>
            </Card>
          )}

          {validationReferences && validationReferences.length > 0 && (
            <ReferencesCard references={validationReferences} tab="analysis" />
          )}
        </div>
      </CardContent>
    </Card>
  );
}
