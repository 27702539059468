import { DateTime } from 'luxon';

export function formatTimestamp(timestamp: string, options?: Intl.DateTimeFormatOptions): string {
  const date = DateTime.fromISO(timestamp);
  return date.toLocaleString(options);
}

// MMM DD, YYYY at hh:mm AM/PM
export function formatShortDateTime(timestamp: string) {
  const date = DateTime.fromISO(timestamp);
  const datePart = date.toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const timePart = date.toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${datePart} at ${timePart}`;
}

export function checkIfEmpty(value: string): boolean {
  return value === '' || value === null || value === undefined;
}

export const getTimezones = () => {
  // Major timezones
  const majorTimezones = [
    'UTC',
    // North & Central America
    'America/New_York', // EST/EDT
    'America/Chicago', // CST/CDT
    'America/Denver', // MST/MDT
    'America/Los_Angeles', // PST/PDT
    'America/Toronto', // Eastern Canada
    'America/Mexico_City', // Central Mexico
    // South America
    'America/Sao_Paulo', // Brazil
    'America/Buenos_Aires', // Argentina
    'America/Santiago', // Chile
    'America/Lima', // Peru
    'America/Bogota', // Colombia
    // Europe
    'Europe/London', // GMT/BST
    'Europe/Paris', // Central European
    'Europe/Berlin', // Central European
    // Asia & Oceania
    'Asia/Dubai', // Gulf States
    'Asia/Singapore', // Singapore
    'Asia/Tokyo', // Japan
    'Asia/Shanghai', // China
    'Australia/Sydney', // Eastern Australia
    'Pacific/Auckland', // New Zealand
  ];

  return majorTimezones.map((zone) => ({
    value: zone,
    label:
      zone === 'UTC'
        ? 'UTC'
        : zone
            .replace('_', ' ')
            .split('/')
            .pop()!
            .replace(/([A-Z])/g, ' $1')
            .trim(),
  }));
};

export const getCVSSScoreColor = (score: string) => {
  const numScore = parseFloat(score);
  if (numScore >= 9.0) return '#DB4E4E';
  if (numScore >= 7.0) return '#D68466';
  if (numScore >= 4.0) return '#E6E67E';
  return '#008001';
};

export function pluralize(value: number, text: string): string {
  if (value < 0) return `- ${text}s`;

  if (text.endsWith('y') && !/[aeiou]y$/i.test(text)) {
    const pluralForm = text.slice(0, -1) + 'ies';
    return value === 1 ? `${value} ${text}` : `${value} ${pluralForm}`;
  }

  return value === 1 ? `${value} ${text}` : `${value} ${text}s`;
}

export function prependArticle(str: string) {
  if (!str || typeof str !== 'string') {
    throw new Error('Input must be a non-empty string');
  }

  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstLetter = str.charAt(0).toLowerCase();

  if (vowels.includes(firstLetter)) {
    return `an ${str}`;
  } else {
    return `a ${str}`;
  }
}

export function pluralizeWithText(value: number, text: string, customText: string): string {
  if (value <= 0) return `${customText} ${text}s`;
  if (value === 1) return `${customText} ${text}`;
  return `${customText} ${text}s`;
}
