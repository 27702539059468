/**
 * SourcesDisplay Component
 *
 * Contains utilities and components for displaying source references
 * embedded within markdown content.
 */

// Types for sources metadata
export interface SourceMetadata {
  url: string;
  title?: string;
  description?: string;
  image?: string;
}

// Regex pattern to extract sources
export const sourcesRegexPattern = /\{["']sources["']\s*:\s*(\[.*?\])\}/;

// Function to transform raw extracted data into SourceMetadata format
export function transformSourcesData(parsedData: any): SourceMetadata[] | undefined {
  if (parsedData.sources && Array.isArray(parsedData.sources)) {
    return parsedData.sources.map((source: any) => ({
      url: source.url ?? source,
      title: source.title ?? source,
      image: `https://www.google.com/s2/favicons?domain=${source.url ?? source}`,
    }));
  }

  return undefined;
}

// Component to display sources
interface SourcesDisplayProps {
  sources: SourceMetadata[];
}

export function SourcesDisplay({ sources }: SourcesDisplayProps) {
  if (!sources || sources.length === 0) return null;

  return (
    <div className="border-t border-border/30 pt-2">
      <div className="text-sm font-medium mb-1.5">Sources</div>
      <div className="flex flex-wrap gap-3 flex-col">
        {sources.map((source, idx) => {
          let displayText;
          try {
            displayText = new URL(source.url).hostname;
          } catch {
            displayText = source.url;
          }

          return (
            <a
              key={idx}
              href={source.url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-1 px-2 py-0.5 text-xs bg-secondary/50 hover:bg-secondary text-secondary-foreground rounded transition-colors"
              title={source.title || source.description || source.url}
            >
              <span className="flex items-center justify-center w-4 h-4">
                {source.image ? (
                  <img src={source.image} alt="" className="max-h-4 max-w-4 object-contain" />
                ) : (
                  <span>🔗</span>
                )}
              </span>
              {source.title || displayText}
            </a>
          );
        })}
      </div>
    </div>
  );
}
