import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { CloudIntegration } from '@/connectors/CloudIntegration';
import { Cloud } from 'lucide-react';
import { Helmet } from 'react-helmet';

export default function OrganizationSettings() {
  return (
    <div className="container max-w-4xl px-6 pb-8">
      <Helmet>
        <title>Organization Settings</title>
      </Helmet>
      <h1 className="text-2xl font-bold mb-8">Organization Settings</h1>

      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold mb-2">Data Integrations</h2>
          <p className="text-sm text-muted-foreground mb-6">
            Configure organizational integrations with external services and cloud providers.
          </p>
          <Accordion type="single" defaultValue="item-1" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>
                <div className="flex items-center gap-2">
                  <Cloud className="h-5 w-5 text-primary" />
                  Cloud Provider Integrations
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <CloudIntegration />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </section>
      </div>
    </div>
  );
}
