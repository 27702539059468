import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/Helpers/AuthContext';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircle, Loader2 } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { verifyMfaLogin } from '@/api/mfa.api';
import { useQueryClient } from '@tanstack/react-query';
import { InputOTP } from '@/components/ui/input-otp';
import { CustomInputOTPGroup } from '../User/SecuritySettings';

interface LoginResponse {
  requires_mfa?: boolean;
}

interface AuthErrorData {
  error?: {
    detail?: string;
    [key: string]: unknown;
  };
}

interface AuthError extends Error {
  data: AuthErrorData;
}

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Please enter a valid email address' })
    .trim()
    .toLowerCase(),
  password: z.string().min(1, { message: 'Password is required' }),
});

const mfaSchema = z.object({
  code: z
    .string()
    .min(6, { message: 'Code must be 6 digits' })
    .max(6, { message: 'Code must be 6 digits' }),
});

const Login = () => {
  const [error, setError] = useState<ReactNode>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requiresMfa, setRequiresMfa] = useState(false);
  const [mfaError, setMfaError] = useState<string>('');
  const [isVerifyingMfa, setIsVerifyingMfa] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const isVerification = searchParams.get('verification') === 'true';
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const mfaForm = useForm<z.infer<typeof mfaSchema>>({
    resolver: zodResolver(mfaSchema),
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
  });

  const { isValid, isDirty } = form.formState;

  useEffect(() => {
    if (mfaCode.length === 6) {
      handleMfaVerification();
    }
  }, [mfaCode]);

  useEffect(() => {
    if (isAuthenticated) {
      const redirect = searchParams.get('redirect');
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/issues');
      }
    }
  }, [isAuthenticated, navigate, searchParams]);

  const handleMfaVerification = async () => {
    setIsVerifyingMfa(true);
    setMfaError('');

    try {
      await verifyMfaLogin(mfaCode);
      await queryClient.invalidateQueries({ queryKey: ['userData'] });
      await queryClient.invalidateQueries({ queryKey: ['organizations'] });

      setRequiresMfa(false);
    } catch (error) {
      if ((error as AuthError).data) {
        const errorData = (error as AuthError).data;
        const errorDetail = errorData?.error?.detail;
        setMfaError(
          typeof errorDetail === 'string'
            ? errorDetail
            : 'Invalid verification code. Please try again.',
        );
      } else {
        setMfaError('Invalid verification code. Please try again.');
      }
    } finally {
      setIsVerifyingMfa(false);
    }
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (isSubmitting || !isValid) return;
    setError('');
    setIsSubmitting(true);

    try {
      const result = formSchema.safeParse(data);
      if (!result.success) {
        const formattedErrors = result.error.issues.map((issue) => issue.message).join('. ');
        setError(formattedErrors);
        return;
      }

      const { email, password } = result.data;
      const response = (await login(email, password)) as LoginResponse;

      if (response?.requires_mfa) {
        setRequiresMfa(true);
        return;
      }

      navigate('/issues');
    } catch (error) {
      if ((error as AuthError).data) {
        const errorData = (error as AuthError).data;
        if (typeof errorData?.error === 'object' && errorData.error !== null) {
          const errorMessages = Object.entries(errorData.error)
            .map(([field, message]) => {
              if (field === '0' || field === 'detail') return message as string;
              return `${field}: ${Array.isArray(message) ? message[0] : message}`;
            })
            .filter(Boolean)
            .join('. ');
          setError(errorMessages || 'Invalid credentials. Please try again.');
        } else {
          setError('Invalid credentials. Please try again.');
        }
        form.setValue('password', '');
        form.setFocus('password');
      } else {
        setError('Invalid credentials. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <Helmet>
        <title>Specular</title>
      </Helmet>
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px] space-y-6">
            <h1 className="text-v2-white text-2xl mb-8 uppercase tracking-wider text-center font-lato font-bold">
              Log in
            </h1>

            {isVerification && (
              <Alert className="mb-6 bg-yellow-500/10 border border-yellow-500/20">
                <AlertCircle className="h-4 w-4 text-yellow-500" />
                <AlertTitle className="text-v2-white font-medium">
                  Email verification required
                </AlertTitle>
                <AlertDescription className="text-v2-white/80">
                  Please check your email for the verification link before logging in.
                </AlertDescription>
              </Alert>
            )}

            {error && (
              <div className="p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500 animate-in fade-in duration-200">
                {error}
              </div>
            )}

            <div className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="email"
                        required
                        placeholder="Email"
                        autoComplete="email"
                        disabled={isSubmitting}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500 animate-in fade-in duration-200">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your email</FormDescription>
                    )}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Password"
                        autoComplete="current-password"
                        disabled={isSubmitting}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500 animate-in fade-in duration-200">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-end">
              <Link
                to="/forgot-password"
                className="text-v2-white/50 text-sm hover:text-v2-orange transition-colors"
                tabIndex={isSubmitting ? -1 : 0}
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isSubmitting || !isValid || !isDirty}
              className={`w-full py-3 px-3 text-v2-white text-base rounded-md
                cursor-pointer transition-colors font-poppins font-semibold disabled:cursor-not-allowed flex items-center justify-center bg-v2-orange hover:bg-v2-orange/90`}
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Logging in...
                </>
              ) : (
                'Log in'
              )}
            </button>
          </form>
        </Form>
      </div>
      <AuthBackground />

      <Dialog
        open={requiresMfa}
        onOpenChange={(open) => {
          if (!open) {
            mfaForm.reset();
            setMfaCode('');
            setMfaError('');
          }
          setRequiresMfa(open);
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Two-Factor Authentication</DialogTitle>
            <DialogDescription>
              Please enter the 6-digit code from your authenticator app to complete login.
            </DialogDescription>
          </DialogHeader>

          <Form {...mfaForm}>
            <form
              onSubmit={mfaForm.handleSubmit(() => {
                handleMfaVerification();
              })}
            >
              <div className="flex flex-col items-center gap-4 mb-8">
                <div className="flex flex-col justify-center items-center w-full">
                  <FormField
                    control={mfaForm.control}
                    name="code"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <InputOTP
                            maxLength={6}
                            {...field}
                            id="mfa-code"
                            value={field.value}
                            onChange={(e) => {
                              const value = e.replace(/\D/g, '').slice(0, 6);
                              field.onChange(value);
                              setMfaCode(value);
                            }}
                            pattern="[0-9]*"
                            inputMode="numeric"
                            className="text-2xl w-full"
                            autoFocus
                            autoComplete="off"
                            data-form-type="other"
                          >
                            {CustomInputOTPGroup}
                          </InputOTP>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  {mfaError && (
                    <p className="text-sm mt-4 text-red-500 animate-in fade-in duration-200">
                      {mfaError}
                    </p>
                  )}
                </div>
              </div>

              <DialogFooter>
                <div className="flex justify-center items-center w-full">
                  <button
                    type="submit"
                    disabled={isVerifyingMfa || !mfaForm.formState.isValid}
                    className={`w-[250px] py-3 px-3 text-v2-white text-base rounded-md
                    cursor-pointer transition-colors font-poppins font-semibold disabled:cursor-not-allowed flex items-center justify-center bg-v2-black hover:bg-v2-black/90`}
                  >
                    {isVerifyingMfa ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Verifying...
                      </>
                    ) : (
                      'Verify'
                    )}
                  </button>
                </div>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;
