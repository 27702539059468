import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFeatureFlags } from '@/context/FeatureFlagContext';
import { useToast } from '@/hooks/use-toast';
import { useWindowSize } from '@/hooks/useWindowSize';
import { cn } from '@/lib/utils';
import { InfoTooltip } from '@/v2/components/InfoTooltip/InfoTooltip';
import { severityConfig } from '@/v2/components/SeverityBadge/SeverityBadge';
import {
  useGetAssetsStatisticsQuery,
  useGetIssuesResolutionStatisticsQuery,
  useGetIssuesStatisticsQuery,
  useGetVulnerabilitiesStatisticsQuery,
  useGetVulnerabilitiesTrendQuery,
} from '@/v2/queries/analytics';
import {
  ArrowDown,
  ArrowUp,
  Clock,
  Download,
  LineChartIcon,
  ListChecks,
  Server,
  ShieldAlert,
} from 'lucide-react';
import { parseAsString, useQueryState } from 'nuqs';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import generatePDF from 'react-to-pdf';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

interface VulnerabilitiesTrend {
  high?: number[];
  low?: number[];
  start_date?: string;
  end_date?: string;
  unknown?: number[];
  medium?: number[];
  critical?: number[];
}

interface VulnerabilitiesStatistics {
  statistics?: {
    severity?: {
      critical?: number;
      high?: number;
      medium?: number;
      low?: number;
      unclassified?: number;
    };
  };
}

interface RemediationStatistics {
  statistics?: {
    remediation_status?: {
      critical?: { mean?: number; median?: number };
      high?: { mean?: number; median?: number };
      medium?: { mean?: number; median?: number };
      low?: { mean?: number; median?: number };
    };
  };
}

export default function ExecDashboard() {
  const [timeframe, setTimeframe] = useQueryState('timeframe', parseAsString.withDefault('7d'));
  const { toast } = useToast();
  const [isPrinting, setIsPrinting] = useState(false);
  const { hasFeatureFlag } = useFeatureFlags();
  const { width } = useWindowSize();
  const isSmallScreen = width <= 1400;

  const showRealData = hasFeatureFlag('SHOW_REAL_DATA');

  const mockVulnerabilitiesTrend: VulnerabilitiesTrend = {
    critical: Array(timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90)
      .fill(0)
      .map((_, i, arr) => {
        const max = timeframe === '7d' ? 8 : timeframe === '30d' ? 6 : 4;
        return Math.max(0, max - (i / arr.length) * max);
      }),
    high: Array(timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90)
      .fill(0)
      .map((_, i, arr) => {
        const max = timeframe === '7d' ? 15 : timeframe === '30d' ? 12 : 10;
        return Math.max(0, max - (i / arr.length) * max);
      }),
    medium: Array(timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90)
      .fill(0)
      .map((_, i, arr) => {
        const max = timeframe === '7d' ? 25 : timeframe === '30d' ? 20 : 15;
        return Math.max(0, max - (i / arr.length) * max);
      }),
    low: Array(timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90)
      .fill(0)
      .map((_, i, arr) => {
        const max = timeframe === '7d' ? 35 : timeframe === '30d' ? 30 : 25;
        return Math.max(0, max - (i / arr.length) * max);
      }),
    unknown: Array(timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90)
      .fill(0)
      .map((_, i, arr) => {
        const max = timeframe === '7d' ? 4 : timeframe === '30d' ? 3 : 2;
        return Math.max(0, max - (i / arr.length) * max);
      }),
    start_date: new Date(
      Date.now() - (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) * 24 * 60 * 60 * 1000,
    )
      .toISOString()
      .split('T')[0],
  };

  const formatMockData = (mockData: VulnerabilitiesTrend) => {
    const formattedData = [];
    const dataLength = mockData.critical?.length || 0;

    for (let i = 0; i < dataLength; i++) {
      const date = new Date(mockData.start_date || '');
      date.setDate(date.getDate() + i);

      formattedData.push({
        date: date.toISOString().split('T')[0],
        critical: mockData.critical?.[i] || 0,
        high: mockData.high?.[i] || 0,
        medium: mockData.medium?.[i] || 0,
        low: mockData.low?.[i] || 0,
        unknown: mockData.unknown?.[i] || 0,
      });
    }

    return formattedData;
  };

  const { data: vulnerabilitiesTrend, isLoading: vulnerabilitiesTrendLoading } =
    useGetVulnerabilitiesTrendQuery({
      start_date: new Date(
        Date.now() - (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) * 24 * 60 * 60 * 1000,
      )
        .toISOString()
        .split('T')[0],
      end_date: new Date().toISOString().split('T')[0],
    });

  const { data: vulnerabilitiesStatistics, isLoading: vulnerabilitiesStatisticsLoading } =
    useGetVulnerabilitiesStatisticsQuery({
      aggregate: 'count',
      field: 'severity',
      dashboard_period: timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90,
    }) as { data: VulnerabilitiesStatistics; isLoading: boolean };

  const {
    data: vulnerabilitiesStatisticsRemediation,
    isLoading: vulnerabilitiesStatisticsRemediationLoading,
  } = useGetVulnerabilitiesStatisticsQuery({
    aggregate: 'count',
    field: 'remediation_status',
    remediated_start_date: new Date(
      Date.now() - (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) * 24 * 60 * 60 * 1000,
    )
      .toISOString()
      .split('T')[0],
    remediated_end_date: new Date().toISOString().split('T')[0],
  }) as { data: RemediationStatistics; isLoading: boolean };

  const { data: assetsStatistics, isLoading: assetsStatisticsLoading } =
    useGetAssetsStatisticsQuery({
      start_date: new Date(
        Date.now() - (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) * 24 * 60 * 60 * 1000,
      )
        .toISOString()
        .split('T')[0],
      end_date: new Date().toISOString().split('T')[0],
      dashboard_period: timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90,
    });

  const { data: issuesStatistics, isLoading: issuesStatisticsLoading } =
    useGetIssuesStatisticsQuery({
      dashboard_period: timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90,
    });

  const { data: issuesResolutionStatistics, isLoading: issuesResolutionStatisticsLoading } =
    useGetIssuesResolutionStatisticsQuery({
      dashboard_period: timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90,
    });

  const formattedVulnerabilitiesTrend = [];

  vulnerabilitiesTrend?.critical.forEach((item, index) => {
    if (index === vulnerabilitiesTrend.critical.length - 1) return;

    const date = new Date(vulnerabilitiesTrend?.start_date);
    date.setDate(date.getDate() + index);
    formattedVulnerabilitiesTrend.push({
      date: date.toISOString().split('T')[0],
      critical: item,
      high: vulnerabilitiesTrend?.high[index],
      medium: vulnerabilitiesTrend?.medium[index],
      low: vulnerabilitiesTrend?.low[index],
      unknown: vulnerabilitiesTrend?.unknown[index],
    });
  });

  const vulnerabilitiesStatisticsData = {
    critical:
      (vulnerabilitiesStatistics as VulnerabilitiesStatistics)?.statistics?.severity?.critical ?? 0,
    high: (vulnerabilitiesStatistics as VulnerabilitiesStatistics)?.statistics?.severity?.high ?? 0,
    medium:
      (vulnerabilitiesStatistics as VulnerabilitiesStatistics)?.statistics?.severity?.medium ?? 0,
    low: (vulnerabilitiesStatistics as VulnerabilitiesStatistics)?.statistics?.severity?.low ?? 0,
    unclassified:
      (vulnerabilitiesStatistics as VulnerabilitiesStatistics)?.statistics?.severity
        ?.unclassified ?? 0,
    total: vulnerabilitiesStatistics
      ? (vulnerabilitiesStatistics as VulnerabilitiesStatistics).statistics?.severity?.critical +
        (vulnerabilitiesStatistics as VulnerabilitiesStatistics).statistics?.severity?.high +
        (vulnerabilitiesStatistics as VulnerabilitiesStatistics).statistics?.severity?.medium +
        (vulnerabilitiesStatistics as VulnerabilitiesStatistics).statistics?.severity?.low +
        (vulnerabilitiesStatistics as VulnerabilitiesStatistics).statistics?.severity?.unclassified
      : 0,
  };
  const issuesStatisticsData = issuesStatistics
    ? {
        critical: issuesStatistics.critical ?? 0,
        high: issuesStatistics.high ?? 0,
        medium: issuesStatistics.medium ?? 0,
        low: issuesStatistics.low ?? 0,
        informational: issuesStatistics.informational ?? 0,
        total_issues: issuesStatistics.total_issues ?? 0,
        totalWithoutInfo:
          (issuesStatistics.critical || 0) +
          (issuesStatistics.high || 0) +
          (issuesStatistics.medium || 0) +
          (issuesStatistics.low || 0),
      }
    : {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        informational: 0,
        total_issues: 0,
        totalWithoutInfo: 0,
      };

  const timeframeText = {
    '7d': '7 day',
    '30d': '30 day',
    '90d': '90 day',
  };

  // Helper function for safe percentage calculation
  const safePercentage = (value: number, total: number) => {
    if (!total) return 0;
    return Math.round((value / total) * 100);
  };

  const targetRef = useRef<HTMLDivElement>(null);

  const handleDownload = () => {
    if (targetRef.current) {
      setIsPrinting(true);

      setTimeout(() => {
        generatePDF(targetRef, {
          filename: `security-overview-${timeframe}-${new Date().toLocaleDateString()}.pdf`,
          page: {
            margin: 10,
            format: 'a4',
            orientation: 'landscape',
          },
          canvas: {
            mimeType: 'image/jpeg',
            qualityRatio: 1,
          },
          overrides: {
            canvas: {
              useCORS: true,
            },
            pdf: {
              compress: true,
            },
          },
        }).then(() => {
          setIsPrinting(false);

          toast({
            title: 'PDF Exported Successfully',
            description: `Security overview has been exported as PDF for ${timeframeText[timeframe]} period.`,
            variant: 'default',
          });
        });
      }, 100);
    }
  };

  const handleTabChange = (tab: string) => {
    if (tab !== timeframe) {
      setTimeframe(tab);
    }
  };

  const CustomTooltip = ({ active, payload, label, total }: any) => {
    if (active && payload && payload.length) {
      // Special handling for Issues Comparison chart
      if (payload[0]?.name === 'Current Period' && payload[1]?.name === 'Previous Period') {
        const currentValue = payload[0].value;
        const previousValue = payload[1].value;
        const changeValue = currentValue - previousValue;
        const changePercent = previousValue ? Math.round((changeValue / previousValue) * 100) : 0;
        const isIncrease = changeValue > 0;

        return (
          <div className="bg-popover border border-border rounded p-3 shadow-md max-w-[250px]">
            <p className="text-sm font-medium mb-2">{label} Severity Issues</p>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1">
                <p className="text-xs text-muted-foreground">Current:</p>
                <p className="text-sm font-medium">{currentValue}</p>
              </div>
              <div className="col-span-1">
                <p className="text-xs text-muted-foreground">Previous:</p>
                <p className="text-sm font-medium">{previousValue}</p>
              </div>
            </div>
            <div className="mt-2 pt-2 border-t border-border">
              <p className="text-xs flex items-center gap-1">
                <span className={isIncrease ? 'text-red-500' : 'text-green-500'}>
                  {isIncrease ? '+' : ''}
                  {changeValue} ({isIncrease ? '+' : ''}
                  {changePercent}%)
                </span>
                <span className="text-muted-foreground">since last period</span>
              </p>
            </div>
          </div>
        );
      }

      // Default tooltip for other charts
      return (
        <div className="bg-popover border border-border rounded p-2 shadow-md">
          <p className="text-sm font-medium">{label || `${payload[0].name}`}</p>
          {payload.map((entry: any, index: number) => (
            <p
              key={`item-${index}`}
              className="text-xs"
              style={{ color: entry.color || entry.fill }}
            >
              {entry.value} {entry.unit} ({safePercentage(entry.value, total)}%)
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const trendsData = [
    {
      name: 'Critical',
      value: vulnerabilitiesStatisticsData.critical,
      color: severityConfig.critical.color,
    },
    {
      name: 'High',
      value: vulnerabilitiesStatisticsData.high,
      color: severityConfig.high.color,
    },
    {
      name: 'Medium',
      value: vulnerabilitiesStatisticsData.medium,
      color: severityConfig.medium.color,
    },
    { name: 'Low', value: vulnerabilitiesStatisticsData.low, color: severityConfig.low.color },
  ];

  const issuesData = [
    {
      name: 'Critical',
      value: issuesStatisticsData.critical,
      color: severityConfig.critical.color,
    },
    { name: 'High', value: issuesStatisticsData.high, color: severityConfig.high.color },
    { name: 'Medium', value: issuesStatisticsData.medium, color: severityConfig.medium.color },
    { name: 'Low', value: issuesStatisticsData.low, color: severityConfig.low.color },
  ];

  const pieChartSkeleton = (
    <div className="bg-card border rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <div className="h-6 w-36 bg-muted animate-pulse rounded mb-2" />
          <div className="h-4 w-48 bg-muted animate-pulse rounded" />
        </div>
        <div className="h-6 w-6 bg-muted animate-pulse rounded-full" />
      </div>
      <div className="relative">
        <div className="h-[250px] w-full bg-muted animate-pulse rounded" />
        <div className="absolute right-0 top-1/2 -translate-y-1/2 space-y-2">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="flex items-center gap-2">
              <div className="h-3 w-3 bg-muted animate-pulse rounded-full" />
              <div className="h-4 w-32 bg-muted animate-pulse rounded" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const assetsSkeleton = (
    <div className="bg-card border rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="h-5 w-24 bg-muted animate-pulse rounded" />
        <div className="h-6 w-6 bg-muted animate-pulse rounded-full" />
      </div>
      <div className="flex flex-col space-y-6">
        <div className="text-center mb-8">
          <div className="h-12 w-16 bg-muted animate-pulse rounded mx-auto mb-2" />
          <div className="h-4 w-48 bg-muted animate-pulse rounded mx-auto" />
        </div>
        <div className="flex justify-between items-center">
          <div className="text-center flex-1">
            <div className="h-8 w-16 bg-muted animate-pulse rounded mx-auto mb-2" />
            <div className="h-4 w-24 bg-muted animate-pulse rounded mx-auto" />
          </div>
          <div className="h-12 w-px bg-border" />
          <div className="text-center flex-1">
            <div className="h-8 w-16 bg-muted animate-pulse rounded mx-auto mb-2" />
            <div className="h-4 w-24 bg-muted animate-pulse rounded mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );

  const trendsSkeleton = (
    <div className="bg-card border rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <div className="h-6 w-36 bg-muted animate-pulse rounded mb-2" />
          <div className="h-4 w-48 bg-muted animate-pulse rounded" />
        </div>
        <div className="h-6 w-6 bg-muted animate-pulse rounded-full" />
      </div>
      <div className="h-[250px] w-full bg-muted animate-pulse rounded" />
    </div>
  );

  const barChartSkeleton = (
    <div className="bg-card border rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <div className="h-6 w-36 bg-muted animate-pulse rounded mb-2" />
          <div className="h-4 w-48 bg-muted animate-pulse rounded" />
        </div>
        <div className="h-6 w-6 bg-muted animate-pulse rounded-full" />
      </div>
      <div className="h-[250px] w-full bg-muted animate-pulse rounded">
        <div className="flex justify-between h-full pt-4 px-10">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="flex flex-col items-center justify-end h-full w-16 gap-2">
              <div
                className="w-10 bg-muted-foreground/20 animate-pulse rounded-t"
                style={{ height: `${Math.random() * 60 + 20}%` }}
              />
              <div className="h-4 w-full bg-muted animate-pulse rounded" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const issueComparisonData = [
    {
      severity: 'Critical',
      current:
        issuesResolutionStatistics?.statistics?.critical?.current_period_total_issues_resolved,
      previous:
        issuesResolutionStatistics?.statistics?.critical?.previous_period_total_issues_resolved,
    },
    {
      severity: 'High',
      current: issuesResolutionStatistics?.statistics?.high?.current_period_total_issues_resolved,
      previous: issuesResolutionStatistics?.statistics?.high?.previous_period_total_issues_resolved,
    },
    {
      severity: 'Medium',
      current: issuesResolutionStatistics?.statistics?.medium?.current_period_total_issues_resolved,
      previous:
        issuesResolutionStatistics?.statistics?.medium?.previous_period_total_issues_resolved,
    },
    {
      severity: 'Low',
      current: issuesResolutionStatistics?.statistics?.low?.current_period_total_issues_resolved,
      previous: issuesResolutionStatistics?.statistics?.low?.previous_period_total_issues_resolved,
    },
  ];

  const dataCollectionInProgress = (
    <div className="flex flex-col items-center justify-center h-[250px] text-center space-y-4 px-4">
      <div className="rounded-full bg-blue-100 p-3 dark:bg-blue-900">
        <Clock className="h-8 w-8 text-blue-600 dark:text-blue-300" />
      </div>
      <h3 className="text-xl font-semibold">Data Collection In Progress</h3>
      <p className="text-muted-foreground max-w-md">
        We&apos;re still collecting data for your {timeframeText[timeframe]} view. Please check back
        later to see your resolution efficiency metrics.
      </p>
    </div>
  );

  const emptyPieChartCount = (pieChartTitle: string, pieChartIcon: React.ReactNode) => (
    <div className="flex flex-col items-center justify-center h-full text-center space-y-4">
      <div className="rounded-full bg-muted p-3">{pieChartIcon}</div>
      <div>
        <h3 className="text-lg font-semibold">No {pieChartTitle} found</h3>
        <p className="text-sm text-muted-foreground">
          No {pieChartTitle} have been detected for this period.
        </p>
      </div>
    </div>
  );

  return (
    <div className="flex min-h-screen flex-col bg-background">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className={cn('flex-1', isPrinting && 'pdf-export')} ref={targetRef}>
        <div className={cn('space-y-8 p-8 pt-6', isPrinting && 'p-2 pt-2 space-y-6')}>
          <div
            className={cn(
              'flex flex-col md:flex-row md:items-center justify-between gap-4 bg-card rounded-lg',
              isPrinting && 'px-0 py-4 border-none',
            )}
          >
            <div className="flex items-center gap-4">
              <div>
                <h2 className="text-3xl font-bold tracking-tight">Security Overview</h2>
              </div>
              {!isPrinting && (
                <Button
                  variant="outline"
                  size="sm"
                  className="flex items-center gap-2 hover:bg-muted transition-colors"
                  onClick={handleDownload}
                >
                  <Download className="h-4 w-4" />
                  Export PDF
                </Button>
              )}
            </div>
            {isPrinting ? (
              <div className="text-right font-medium">
                Timeframe:{' '}
                {timeframe === '7d' ? '7 days' : timeframe === '30d' ? '30 days' : '90 days'}
              </div>
            ) : (
              <Tabs
                defaultValue="7d"
                value={timeframe}
                onValueChange={handleTabChange}
                className="w-full md:w-[400px]"
              >
                <TabsList className="grid w-full grid-cols-3">
                  <TabsTrigger value="7d">7 days</TabsTrigger>
                  <TabsTrigger value="30d">30 days</TabsTrigger>
                  <TabsTrigger value="90d">90 days</TabsTrigger>
                </TabsList>
              </Tabs>
            )}
          </div>

          {/* Issues Section */}
          <div>
            <div className="flex items-center gap-2 mb-2">
              <ListChecks className="h-5 w-5 flex-shrink-0" />
              <h3 className="text-xl font-semibold cursor-help">Issues Overview</h3>
            </div>
            <div className="flex flex-row items-center gap-2 text-muted-foreground text-sm mb-4">
              An Issue is an exploitable vulnerability or misconfiguration that leads to an impact
              <InfoTooltip content="Security findings that require attention and resolution across your environment. These include code vulnerabilities, misconfigurations, and potential attack vectors." />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {!issuesStatisticsLoading ? (
                <Card className={cn('shadow-sm', isPrinting && 'shadow-none')}>
                  <CardHeader className={cn('pb-2', isPrinting && 'p-3')}>
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="text-base font-semibold">Issues</CardTitle>
                        <CardDescription className="text-xs mt-1">
                          Distribution by severity
                        </CardDescription>
                      </div>
                      <ListChecks className="h-5 w-5 text-muted-foreground" />
                    </div>
                  </CardHeader>
                  <CardContent>
                    {issuesStatistics?.customer_created_at &&
                    new Date(issuesStatistics.customer_created_at) >
                      new Date(
                        Date.now() -
                          (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) *
                            24 *
                            60 *
                            60 *
                            1000,
                      ) ? (
                      dataCollectionInProgress
                    ) : (
                      <ResponsiveContainer width="100%" height={250}>
                        {issuesStatisticsData.total_issues === 0 ? (
                          emptyPieChartCount(
                            'issues',
                            <ListChecks className="h-8 w-8 text-muted-foreground" />,
                          )
                        ) : (
                          <PieChart>
                            <Pie
                              data={issuesData}
                              cx="50%"
                              cy="50%"
                              innerRadius={70}
                              outerRadius={90}
                              paddingAngle={5}
                              dataKey="value"
                            >
                              {issuesData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                              ))}
                              <Label
                                content={({ viewBox }) => {
                                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                                    return (
                                      <text
                                        x={viewBox.cx}
                                        y={viewBox.cy}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                      >
                                        <tspan
                                          x={viewBox.cx}
                                          y={viewBox.cy}
                                          className="fill-foreground text-3xl font-bold"
                                        >
                                          {issuesStatisticsData.total_issues}
                                        </tspan>
                                        <tspan
                                          x={viewBox.cx}
                                          y={(viewBox.cy || 0) + 20}
                                          className="fill-muted-foreground text-xs"
                                        >
                                          Total
                                        </tspan>
                                      </text>
                                    );
                                  }
                                }}
                              />
                            </Pie>
                            <RechartsTooltip
                              content={
                                <CustomTooltip total={issuesStatisticsData.totalWithoutInfo} />
                              }
                            />
                            <Legend
                              layout={isSmallScreen ? 'vertical' : 'horizontal'}
                              verticalAlign={isSmallScreen ? 'middle' : 'bottom'}
                              align={isSmallScreen ? 'right' : 'center'}
                              iconSize={10}
                              content={() => (
                                <div
                                  className={`flex ${isSmallScreen ? 'flex-col mr-6' : 'mt-2'} gap-6 justify-center w-full`}
                                >
                                  {issuesData.map((item) => (
                                    <div
                                      className="flex flex-row gap-1 items-center"
                                      key={item.name}
                                    >
                                      <div
                                        className={`w-3 h-3 rounded-full`}
                                        style={{ backgroundColor: item.color }}
                                      />
                                      <div className="flex flex-row gap-1 items-center">
                                        <a
                                          href={`/issues?severity=${item.name.toLowerCase()}`}
                                          className={cn('text-foreground hover:underline text-sm')}
                                        >
                                          {item.name}: {item.value}
                                        </a>
                                        <div
                                          className={cn(
                                            'flex flex-row items-center ml-1 text-sm font-medium',
                                            issuesStatistics.dashboard_period_delta[
                                              item.name.toLowerCase()
                                            ] > 0
                                              ? 'text-red-500'
                                              : issuesStatistics.dashboard_period_delta[
                                                    item.name.toLowerCase()
                                                  ] < 0
                                                ? 'text-green-500'
                                                : 'text-muted-foreground',
                                          )}
                                        >
                                          {issuesStatistics.dashboard_period_delta[
                                            item.name.toLowerCase()
                                          ] > 0 ? (
                                            <ArrowUp className="h-3 w-3 inline-block" />
                                          ) : issuesStatistics.dashboard_period_delta[
                                              item.name.toLowerCase()
                                            ] < 0 ? (
                                            <ArrowDown className="h-3 w-3 inline-block" />
                                          ) : null}
                                          <div>
                                            {issuesStatistics.dashboard_period_delta[
                                              item.name.toLowerCase()
                                            ] > 0
                                              ? '+'
                                              : ''}
                                            {issuesStatistics.dashboard_period_delta[
                                              item.name.toLowerCase()
                                            ] !== 0
                                              ? issuesStatistics.dashboard_period_delta[
                                                  item.name.toLowerCase()
                                                ]
                                              : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            />
                          </PieChart>
                        )}
                      </ResponsiveContainer>
                    )}
                  </CardContent>
                </Card>
              ) : (
                pieChartSkeleton
              )}

              {!issuesResolutionStatisticsLoading ? (
                <Card className={cn('shadow-sm', isPrinting && 'shadow-none')}>
                  <CardHeader className={cn('pb-2', isPrinting && 'p-3')}>
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="text-base font-semibold">
                          Resolution Efficiency
                        </CardTitle>
                        <CardDescription className="text-xs mt-1">
                          {timeframeText[timeframe]} comparison of security issues resolved
                        </CardDescription>
                      </div>
                      <LineChartIcon className="h-5 w-5 text-muted-foreground" />
                    </div>
                  </CardHeader>
                  <CardContent>
                    {issuesResolutionStatistics?.customer_created_at &&
                    new Date(issuesResolutionStatistics.customer_created_at) >
                      new Date(
                        Date.now() -
                          (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) *
                            24 *
                            60 *
                            60 *
                            1000,
                      ) ? (
                      dataCollectionInProgress
                    ) : (
                      <ResponsiveContainer width="100%" height={275}>
                        <BarChart
                          data={issueComparisonData}
                          margin={{ top: 20, right: 20, left: 10, bottom: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="severity"
                            style={{ fontSize: '14px', marginBottom: '10px' }}
                            tickMargin={10}
                          />
                          <YAxis style={{ fontSize: '14px' }} />
                          <RechartsTooltip content={<CustomTooltip />} />
                          <Legend
                            verticalAlign="bottom"
                            wrapperStyle={{
                              fontSize: '14px',
                              paddingTop: '10px',
                            }}
                          />
                          <Bar
                            name="Current Period"
                            dataKey="current"
                            fill="#3b82f6"
                            radius={[4, 4, 0, 0]}
                          />
                          <Bar
                            name="Previous Period"
                            dataKey="previous"
                            fill="#93c5fd"
                            radius={[4, 4, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </CardContent>
                </Card>
              ) : (
                barChartSkeleton
              )}
            </div>
          </div>

          {/* Vulnerabilities Section */}
          <div>
            <div className="flex items-center gap-2 mb-2">
              <ShieldAlert className="h-5 w-5 flex-shrink-0" />
              <h3 className="text-xl font-semibold cursor-help">Vulnerabilities Overview</h3>
            </div>

            <div className="flex flex-row items-center gap-2 text-muted-foreground text-sm mb-4">
              A Vulnerability is an identified risk (CVE or misconfiguration) that is not currently
              exploitable
              <InfoTooltip content="Specific security flaws in your systems that could be exploited by attackers. These include CVEs, zero-days, and other documented security weaknesses with defined risk profiles." />
            </div>

            <div className="grid gap-6 md:grid-cols-2">
              {!vulnerabilitiesTrendLoading ? (
                <Card className={cn('shadow-sm', isPrinting && 'shadow-none')}>
                  <CardHeader className={cn('pb-2', isPrinting && 'p-3')}>
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="text-base font-semibold">
                          Vulnerability Trends
                        </CardTitle>
                        <CardDescription className="text-xs mt-1">
                          {timeframeText[timeframe]} overview of security issues by severity
                        </CardDescription>
                      </div>
                      <LineChartIcon className="h-5 w-5 text-muted-foreground" />
                    </div>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={250}>
                      <LineChart
                        data={
                          showRealData
                            ? formattedVulnerabilitiesTrend
                            : formatMockData(mockVulnerabilitiesTrend)
                        }
                        margin={{ top: 20, right: 20, left: 10, bottom: 20 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" className="text-xs" />
                        <YAxis className="text-sm" />
                        <RechartsTooltip
                          content={
                            <CustomTooltip total={vulnerabilitiesStatisticsData.total ?? 0} />
                          }
                        />
                        <Legend iconType="plainline" />
                        <Line
                          type="monotone"
                          name="Critical"
                          dataKey="critical"
                          stroke="#ef4444"
                          strokeWidth={2}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          name="High"
                          dataKey="high"
                          stroke="#f97316"
                          strokeWidth={2}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          name="Medium"
                          dataKey="medium"
                          stroke="#eab308"
                          strokeWidth={2}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          name="Low"
                          dataKey="low"
                          stroke="#22c55e"
                          strokeWidth={2}
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              ) : (
                trendsSkeleton
              )}

              {!vulnerabilitiesStatisticsLoading ? (
                <Card className={cn('shadow-sm', isPrinting && 'shadow-none')}>
                  <CardHeader className={cn('pb-2', isPrinting && 'p-3')}>
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="text-base font-semibold">
                          Current Vulnerabilities
                        </CardTitle>
                        <CardDescription className="text-xs mt-1">
                          Distribution by severity
                        </CardDescription>
                      </div>
                      <ShieldAlert className="h-5 w-5 text-muted-foreground" />
                    </div>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={250}>
                      {vulnerabilitiesStatisticsData.total === 0 ? (
                        emptyPieChartCount(
                          'vulnerabilities',
                          <ShieldAlert className="h-8 w-8 text-muted-foreground" />,
                        )
                      ) : (
                        <PieChart>
                          <Pie
                            data={trendsData ?? []}
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            paddingAngle={5}
                            dataKey="value"
                          >
                            {trendsData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label
                              content={({ viewBox }) => {
                                if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                                  return (
                                    <text
                                      x={viewBox.cx}
                                      y={viewBox.cy}
                                      textAnchor="middle"
                                      dominantBaseline="middle"
                                    >
                                      <tspan
                                        x={viewBox.cx}
                                        y={viewBox.cy}
                                        className="fill-foreground text-3xl font-bold"
                                      >
                                        {vulnerabilitiesStatisticsData.total ?? 0}
                                      </tspan>
                                      <tspan
                                        x={viewBox.cx}
                                        y={(viewBox.cy || 0) + 20}
                                        className="fill-muted-foreground text-xs"
                                      >
                                        Total
                                      </tspan>
                                    </text>
                                  );
                                }
                              }}
                            />
                          </Pie>
                          <RechartsTooltip
                            content={
                              <CustomTooltip total={vulnerabilitiesStatisticsData.total ?? 0} />
                            }
                          />
                          <Legend
                            layout={isSmallScreen ? 'vertical' : 'horizontal'}
                            verticalAlign={isSmallScreen ? 'middle' : 'bottom'}
                            align={isSmallScreen ? 'right' : 'center'}
                            iconSize={10}
                            content={() => (
                              <div
                                className={`flex ${isSmallScreen ? 'flex-col mr-6' : 'mt-2'} gap-6 justify-center w-full`}
                              >
                                {trendsData.map((item) => (
                                  <div className="flex flex-row gap-1 items-center" key={item.name}>
                                    <div
                                      className={`w-3 h-3 rounded-full`}
                                      style={{ backgroundColor: item.color }}
                                    />
                                    <a
                                      href={`/investigation?cvss_severity_filter=${item.name.toLowerCase()}`}
                                      className={cn('text-foreground hover:underline text-sm')}
                                    >
                                      {item.name}: {item.value}
                                    </a>
                                    <span className="text-muted-foreground text-sm">
                                      (
                                      {safePercentage(
                                        item.value,
                                        vulnerabilitiesStatisticsData.total,
                                      )}
                                      %)
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              ) : (
                pieChartSkeleton
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              {!vulnerabilitiesStatisticsRemediationLoading ? (
                <Card className={cn('shadow-sm', isPrinting && 'shadow-none')}>
                  <CardHeader className={cn('pb-2', isPrinting && 'p-3')}>
                    <div className="flex justify-between items-center">
                      <div>
                        <CardTitle className="text-base font-semibold">
                          Remediation Performance
                        </CardTitle>
                        <CardDescription className="text-xs mt-1">
                          {timeframeText[timeframe]} overview of mean vs median time to fix by
                          severity (hours)
                        </CardDescription>
                      </div>
                      <Clock className="h-5 w-5 text-muted-foreground" />
                    </div>
                  </CardHeader>
                  <CardContent className={isPrinting ? 'p-3 pt-0' : ''}>
                    {issuesResolutionStatistics?.customer_created_at &&
                    new Date(issuesResolutionStatistics?.customer_created_at) >
                      new Date(
                        Date.now() -
                          (timeframe === '7d' ? 7 : timeframe === '30d' ? 30 : 90) *
                            24 *
                            60 *
                            60 *
                            1000,
                      ) ? (
                      dataCollectionInProgress
                    ) : (
                      <ResponsiveContainer width="100%" height={275}>
                        <BarChart
                          data={[
                            {
                              severity: 'Critical',
                              meanTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.critical?.mean || 0,
                              medianTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.critical?.median || 0,
                            },
                            {
                              severity: 'High',
                              meanTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.high?.mean || 0,
                              medianTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.high?.median || 0,
                            },
                            {
                              severity: 'Medium',
                              meanTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.medium?.mean || 0,
                              medianTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.medium?.median || 0,
                            },
                            {
                              severity: 'Low',
                              meanTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.low?.mean || 0,
                              medianTime:
                                vulnerabilitiesStatisticsRemediation?.statistics?.remediation_status
                                  ?.low?.median || 0,
                            },
                          ]}
                          margin={{ top: 20, right: 20, left: 10, bottom: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="severity"
                            style={{ fontSize: '14px', marginBottom: '10px' }}
                          />
                          <YAxis style={{ fontSize: '14px' }} />
                          <RechartsTooltip content={<CustomTooltip />} />
                          <Legend
                            verticalAlign="bottom"
                            wrapperStyle={{
                              fontSize: '14px',
                              paddingTop: '10px',
                            }}
                          />
                          <Bar
                            name="Mean Time (hours)"
                            dataKey="meanTime"
                            fill="#3b82f6"
                            radius={[4, 4, 0, 0]}
                          />
                          <Bar
                            name="Median Time (hours)"
                            dataKey="medianTime"
                            fill="#93c5fd"
                            radius={[4, 4, 0, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </CardContent>
                </Card>
              ) : (
                barChartSkeleton
              )}
            </div>
          </div>

          {/* Assets Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <Server className="h-5 w-5 flex-shrink-0" />
                <h3 className="text-xl font-semibold cursor-help">Assets Overview</h3>
              </div>
              <div className="flex flex-row items-center gap-2 text-muted-foreground text-sm mb-4">
                Inventory of digital services and resources being monitored by Specular
                <InfoTooltip
                  content="Comprehensive inventory of all digital resources under management including
                        servers, applications, domains, and endpoints."
                />
              </div>

              {!assetsStatisticsLoading ? (
                <Card className={cn('shadow-sm', isPrinting && 'shadow-none')}>
                  <CardHeader
                    className={cn(
                      'flex flex-row items-center justify-between pb-2 space-y-0',
                      isPrinting && 'p-3',
                    )}
                  >
                    <CardTitle className="text-base font-medium">Assets</CardTitle>
                    <Server className="w-5 h-5 text-muted-foreground rotate-180" />
                  </CardHeader>
                  <CardContent className={cn(isPrinting ? 'p-3 pt-0' : '', 'pt-6')}>
                    <div className="flex flex-col space-y-6">
                      <div className="text-center mb-8">
                        <div className="text-4xl font-bold text-black">
                          {assetsStatistics.http_assets.total}
                        </div>
                        <div className="text-sm text-muted-foreground mt-2">
                          Total monitored assets within{' '}
                          {timeframe === '7d' ? '7' : timeframe === '30d' ? '30' : '90'} day period
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="text-center flex-1">
                          <div className="text-lg font-semibold text-green-500">
                            {assetsStatistics.http_assets.new > 0 && `+`}
                            {assetsStatistics.http_assets.new}
                          </div>

                          {assetsStatistics.http_assets.new > 0 ? (
                            <div className="flex flex-col gap-1 text-sm text-muted-foreground">
                              <div>New assets</div>
                              <a
                                href={`/assets?created_at=${timeframe}&from=dashboard&created_at_filter_operator=greater_than_or_equals`}
                                className="text-sm text-blue-500 hover:underline"
                              >
                                View all
                              </a>
                            </div>
                          ) : (
                            <div className="text-sm text-muted-foreground">New assets</div>
                          )}
                        </div>

                        <div className="h-12 w-px bg-border" />

                        <div className="text-center flex-1">
                          <div className="text-lg font-semibold text-red-500">
                            {assetsStatistics.http_assets.rolled_off > 0 && `-`}
                            {assetsStatistics.http_assets.rolled_off}
                          </div>
                          {assetsStatistics.http_assets.rolled_off > 0 ? (
                            <div className="flex flex-col gap-1 text-sm text-muted-foreground">
                              <div>Assets no longer observed</div>
                              <a
                                href={`/assets?rolled_off=true&from=dashboard&last_seen=${timeframe}`}
                                className="text-sm text-blue-500 hover:underline"
                              >
                                View all
                              </a>
                            </div>
                          ) : (
                            <div className="text-sm text-muted-foreground">
                              Assets no longer observed
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ) : (
                assetsSkeleton
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
