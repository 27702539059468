import {
  createAlertFromNoteAPI,
  createAlertFromNoteParams,
  createNewNoteAPI,
  createNewNoteParams,
  deleteNoteAPI,
  getUserNotes,
  getUserNotesParams,
  updateNoteAPI,
} from '@/api/notes.api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ImageMap } from '@/hooks/useAttachedImages';
import { Note } from '@/types/api/response/notes';

export const getUserNotesQuery = ({ verbose, httpAsset, search, filter }: getUserNotesParams) => {
  return {
    queryKey: ['userNotes', verbose, httpAsset, search, filter],
    queryFn: () => getUserNotes({ verbose, httpAsset, search, filter }),
  };
};

export const useDeleteNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteNoteAPI,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userNotes'], exact: false });
    },
  });
};

export const useDeleteNoteCache = () => {
  const queryClient = useQueryClient();

  return (deletedNoteId: string | number) => {
    queryClient.setQueriesData(
      { queryKey: ['userNotes'], exact: false },
      (old: Note[] | undefined) => {
        if (!old) return old;

        const updatedEntries = old.filter((note) => note.id !== deletedNoteId);

        return updatedEntries;
      },
    );
  };
};

type UseCreateNewNoteParams = {
  onSuccess?: (id: string) => void;
};

export function useCreateNewNote({ onSuccess = () => {} }: UseCreateNewNoteParams) {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: ({ params, imageMap }: { params: createNewNoteParams; imageMap: ImageMap }) =>
      createNewNoteAPI(params, imageMap),
    onSuccess: (data: { id: string }) => {
      onSuccess?.(data?.id);
      queryClient.invalidateQueries({ queryKey: ['userNotes'] });
    },
    onError: (error) => {
      console.error('Error creating new note', error);
      return error;
    },
  });
  return { mutate, isPending, error };
}

type UseUpdateNoteParams = {
  onSuccess?: (id: string) => void;
};

export function useUpdateNote({ onSuccess = () => {} }: UseUpdateNoteParams) {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: ({
      noteId,
      text,
      imageMap,
    }: {
      noteId: string;
      text: string;
      imageMap: ImageMap;
    }) => updateNoteAPI(noteId, { text }, imageMap),
    onSuccess: (_, { noteId }) => {
      queryClient.invalidateQueries({ queryKey: ['userNotes'] });
      onSuccess?.(noteId);
    },
  });
  return { mutate, isPending, error };
}

export function useCreateAlertFromNote() {
  const { mutate, isPending, error } = useMutation({
    mutationFn: (params: createAlertFromNoteParams) => createAlertFromNoteAPI(params),
  });
  return { mutate, isPending, error };
}
