import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import SmartAI from '@/v2/icons/Global/SmartAI';
import React from 'react';
interface AIActionsMenuProps {
  children: React.ReactNode[];
  iconClassName?: string;
}

export const AIActionsMenu = ({ children, iconClassName }: AIActionsMenuProps) => {
  return (
    <DropdownMenu data-click-bypass="true">
      <DropdownMenuTrigger>
        <SmartAI
          className={cn('w-8 h-8 text-v2-orange hover:text-v2-orange-dark ml-1', iconClassName)}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {React.Children.map(children, (child, index) => (
          <DropdownMenuItem key={index}>{child}</DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
