import { Button } from '@/components/ui/button';
import { PlusIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

export const AddIssueButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNewIssuePage = location.pathname === '/issues/new';
  if (isNewIssuePage) return null;
  return (
    <Button
      onClick={() => {
        navigate('/issues/new?from=issues');
      }}
      className="bg-v2-orange text-white"
    >
      <PlusIcon className="w-4 h-4" /> New Issue
    </Button>
  );
};
