import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Custom hook for managing navigation with preservation of query parameters and navigation history.
 * This hook provides utility functions for navigating between pages while maintaining a breadcrumb
 * trail using the 'from' parameter to track navigation history.
 *
 * @returns An object containing utility functions for navigation with parameters
 */
export const useNavigateWithParams = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const from = searchParams.get('from');
  const [baseFrom] = from?.split('?') ?? ['', ''];

  /**
   * Navigates back to the previous page defined in the 'from' parameter.
   * Cleans all query parameters added in the current view and only preserves
   * parameters that were in the original 'from' URL.
   */
  const handleBack = () => {
    const fromParams = searchParams.getAll('from');
    const currentFrom = fromParams[0];
    searchParams.delete('from');

    if (location.pathname.startsWith('/assets')) {
      searchParams.delete('tab');
      searchParams.delete('cve');
      searchParams.delete('urlPage');
      searchParams.delete('urlTechnology');
      searchParams.delete('extension');
      searchParams.delete('statusCode');
    }

    if (location.pathname.startsWith('/investigation')) {
      searchParams.delete('type');
    }

    // Only keep any additional 'from' parameters
    if (fromParams.length > 1) {
      searchParams.append('from', fromParams[1]);
    }

    const [baseFrom, queryString] = currentFrom?.split('?') ?? ['', ''];
    const newQueryString = searchParams.toString();

    const fullQuery = queryString
      ? `${queryString}${newQueryString ? '&' + decodeURIComponent(newQueryString) : ''}`
      : newQueryString;

    navigate(`/${baseFrom}${fullQuery ? '?' + decodeURIComponent(fullQuery) : ''}`);
  };

  /**
   * Generates a URL with preserved navigation history using 'from' parameters.
   *
   * @param page - The destination page path (without leading slash)
   * @param id - The identifier for the resource (usually used in path like /page/id)
   * @param from - The current page path to be stored in the 'from' parameter for back navigation
   * @param queryParams - Optional object with additional query parameters to include in the URL
   * @returns A formatted URL string with all parameters correctly encoded
   *
   * @example
   * // Basic usage (returns "/users/123?from=dashboard")
   * navigateWithParams('users', '123', 'dashboard')
   *
   * @example
   * // With query parameters (returns "/users/123?from=dashboard&filter=active&sort=name")
   * navigateWithParams('users', '123', 'dashboard', { filter: 'active', sort: 'name' })
   */
  const navigateWithParams = (
    page: string,
    id: string,
    from: string,
    queryParams?: Record<string, string>,
  ) => {
    const params = new URLSearchParams(window.location.search);
    const existingFrom = params.get('from');
    params.delete('from');

    // Add custom query parameters if provided (these will override existing ones)
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value]) => {
        params.set(key, value);
      });
    }

    const fromParams =
      existingFrom && existingFrom !== decodeURIComponent(from)
        ? `from=${decodeURIComponent(from)}&from=${existingFrom}`
        : `from=${decodeURIComponent(from)}`;

    return `/${page}/${id}?${fromParams}${params.toString().trim() ? '&' + params.toString() : ''}`;
  };

  /**
   * Determines the text to display on the back button based on the origin page.
   *
   * @returns An appropriate back button text based on where the user navigated from
   */
  const fromText = () => {
    if (baseFrom.startsWith('investigation')) {
      return 'Back to Investigation';
    }
    if (baseFrom.includes('ipaddress')) {
      return 'Back to IP Address';
    }
    if (baseFrom.startsWith('assets/domains')) {
      return 'Back to Domains';
    }
    if (baseFrom.startsWith('assets/services')) {
      return 'Back to Services';
    }
    if (baseFrom.startsWith('assets')) {
      return 'Back to Assets';
    }
    if (baseFrom.startsWith('issues')) {
      return 'Back to Issues';
    }
    return 'Back';
  };

  return { handleBack, navigateWithParams, fromText };
};
