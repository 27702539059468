import { User } from '@/types/api/response/user';
import { ChevronDown, ChevronUp, LogOut } from 'lucide-react';
import { useMemo, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { switchOrganizationApi } from '@/api/auth.api';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

interface AuthDropdownProps {
  userData: User;
  userOrganization: string;
  handleLogoff: () => void;
  isStaff?: boolean;
  staffOrganizations?: Array<{ customername: string }>;
}

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const AuthDropdown = ({
  userData,
  handleLogoff,
  isStaff,
  staffOrganizations,
}: AuthDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const CurrentChevronIcon = useMemo(
    () => (isDropdownOpen ? ChevronUp : ChevronDown),
    [isDropdownOpen],
  );

  const handleOrganizationSwitch = async (org: string) => {
    try {
      setIsSwitching(true);
      await switchOrganizationApi(org);
      setIsDropdownOpen(false);
      window.location.reload();
    } catch (error) {
      console.error('Failed to switch organization:', error);
    } finally {
      setIsSwitching(false);
    }
  };

  return (
    <DropdownMenu onOpenChange={setIsDropdownOpen} modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex items-center gap-2 text-v2-gray hover:text-v2-gray-hover"
        >
          <span>
            {userData?.first_name && userData?.last_name
              ? `${capitalize(userData.first_name)} ${capitalize(userData.last_name)}`
              : 'User'}
          </span>
          <CurrentChevronIcon className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        {isStaff && staffOrganizations.length > 0 && (
          <div className="p-2">
            <DropdownMenuLabel>Current organization</DropdownMenuLabel>
            <Select
              defaultValue={userData.organization.customername}
              onValueChange={handleOrganizationSwitch}
              disabled={isSwitching}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Change organization" />
              </SelectTrigger>
              <SelectContent>
                {staffOrganizations.map((org, index) => (
                  <SelectItem key={index} value={org.customername}>
                    {org.customername}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {!isStaff && (
          <DropdownMenuItem className="text-gray-700">
            Organization: {userData.organization.customername}
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={handleLogoff}
          className="text-red-600 cursor-pointer flex items-center gap-2 p-3"
        >
          <LogOut className="w-4 h-4" />
          Log Off
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AuthDropdown;
