import { fetchWrapper } from '../lib/fetchWrapper';

interface MfaSetupResponse {
  secret: string;
  qr_code: string;
}

interface MfaVerifyResponse {
  backup_codes: string[];
}

interface MfaVerifyLoginResponse {
  message: string;
}

export const startMfaSetup = async (): Promise<MfaSetupResponse> => {
  const { data } = await fetchWrapper.post<MfaSetupResponse>('/mfa/', {});
  return data;
};

export const verifyMfaCode = async (code: string): Promise<MfaVerifyResponse> => {
  const { data } = await fetchWrapper.put<MfaVerifyResponse>('/mfa/', { code: code });
  return data;
};

export const verifyMfaLogin = async (code: string): Promise<MfaVerifyLoginResponse> => {
  const response = await fetchWrapper.post<MfaVerifyLoginResponse>('/mfa/verify/', {
    code,
  });
  return response.data;
};

export const disableMfa = async (code: string): Promise<void> => {
  await fetchWrapper.delete('/mfa/', { body: { code: code } });
};

export default {
  startMfaSetup,
  verifyMfaCode,
  verifyMfaLogin,
  disableMfa,
};
