import { Button } from '@/components/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/Helpers/AuthContext';
import NavSpecularBrandmark from '@/v2/icons/nav-specular-brandmark-transparent.svg?react';

interface ErrorPageProps {
  code?: number;
  message?: string;
  resetErrorBoundary?: any;
}

export default function ErrorPage({ code, message, resetErrorBoundary }: ErrorPageProps) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const oops = {
    title: 'Oops!',
    subtitle: 'An error has occurred.',
    description: 'We are very sorry this just happened.',
  };

  const getErrorDetails = () => {
    switch (code) {
      case 404:
        return {
          title: '404',
          subtitle: 'Page not found',
          description:
            message ||
            "We couldn't find the page you're looking for. Please check the URL or go back.",
        };
      default:
        return oops;
    }
  };

  const { title, subtitle, description } = getErrorDetails();

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-white">
      <div className="m-auto flex max-w-[500px] flex-col items-center justify-center text-center">
        <div className="flex-1 flex flex-col items-center justify-center text-center max-w-md mx-auto gap-8 text-v2-black">
          <h1 className="text-8xl font-bold text-v2-black">{title}</h1>
          <div>
            <h1 className="font-medium text-v2-black">{subtitle}</h1>
            <p className="mb-4 text-muted-foreground text-v2-black">{description}</p>
          </div>
        </div>
        {code ? (
          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={() => window.history.back()}
              className="min-w-[120px]"
            >
              Go Back
            </Button>

            <Button asChild className="min-w-[120px] bg-v2-orange hover:bg-v2-orange/80">
              <Link to={isAuthenticated ? '/issues' : '/login'}>
                {isAuthenticated ? 'Home' : 'Login'}
              </Link>
            </Button>
          </div>
        ) : (
          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={() => {
                resetErrorBoundary();
                navigate(isAuthenticated ? '/issues' : '/login');
              }}
              className="min-w-[120px] bg-v2-orange text-white hover:bg-v2-orange/80 hover:text-white"
            >
              Go Back
            </Button>
          </div>
        )}
      </div>
      <div className="pb-24">
        <NavSpecularBrandmark className="h-4 text-black" />
      </div>
    </div>
  );
}
