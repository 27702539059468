import { GetVulnerabilitiesParams } from '@/api/investigations.api';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { AssetDetailsWithDetails } from '@/types/api/response/assets';
import { ResourceVulnerabilityObjectEntry } from '@/types/api/response/investigations';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { ElementWithActions } from '@/v2/components/ElementWithActions/ElementWithActions';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { getSeverityDisplay } from '@/v2/components/SeverityBadge/SeverityBadge';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import {
  getVulnerabilitiesListQuery,
  getVulnerabilityASNOverviewQuery,
  useGetVulnerabilitiesProductsAndVendorsList,
} from '@/v2/queries/investigation';
import { formatShortDateTime } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Filter, Globe, Link as LinkIcon } from 'lucide-react';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function VulnerabilitiesView({ asset }: { asset: AssetDetailsWithDetails }) {
  const { navigateWithParams } = useNavigateWithParams();
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [asnFilter, setAsnFilter] = useQueryState(
    'asset_asn',
    parseAsArrayOf(parseAsString).withDefault([]),
  );

  const [domainInputValue, setDomainInputValue] = useQueryState(
    'asset_domain_input_value',
    parseAsString.withDefault(''),
  );
  const [domainInputType, setDomainInputType] = useQueryState(
    'asset_domain_input_type',
    parseAsString.withDefault('startswith'),
  );

  const [cveNameFilter, setCveNameFilter] = useQueryState(
    'asset_cve_name_filter',
    parseAsString.withDefault(''),
  );
  const [cveNameFilterOperator, setCveNameFilterOperator] = useQueryState(
    'asset_cve_name_filter_operator',
    parseAsString.withDefault('equals'),
  );

  const [vendorFilter, setVendorFilter] = useQueryState(
    'asset_vendor_filter',
    parseAsString.withDefault(''),
  );
  const [productFilter, setProductFilter] = useQueryState(
    'asset_product_filter',
    parseAsString.withDefault(''),
  );

  const [cvssSeverityFilter, setCvssSeverityFilter] = useQueryState(
    'asset_cvss_severity_filter',
    parseAsString.withDefault(''),
  );

  const [cvssSeverityFilterOperator, setCvssSeverityFilterOperator] = useQueryState(
    'asset_cvss_severity_filter_operator',
    parseAsString.withDefault('greater_than'),
  );

  const [firstObservedFilter, setFirstObservedFilter] = useQueryState(
    'asset_first_observed_filter',
    parseAsString.withDefault(''),
  );

  const [firstObservedFilterOperator, setFirstObservedFilterOperator] = useQueryState(
    'asset_first_observed_filter_operator',
    parseAsString.withDefault('newer_than'),
  );

  const [lastObservedFilter, setLastObservedFilter] = useQueryState(
    'asset_last_observed_filter',
    parseAsString.withDefault(''),
  );

  const [lastObservedFilterOperator, setLastObservedFilterOperator] = useQueryState(
    'asset_last_observed_filter_operator',
    parseAsString.withDefault('newer_than'),
  );

  const [sourceFilter, setSourceFilter] = useQueryState(
    'asset_source_filter',
    parseAsArrayOf(parseAsString).withDefault([]),
  );

  const getVulnerabilitiesParams: GetVulnerabilitiesParams = {
    page,
    asn: asnFilter.join(','),
    hostname: domainInputValue,
    domainname_filter_operator: domainInputType,
    vulnerability_name: cveNameFilter,
    cve_name_filter_operator: cveNameFilterOperator,
    vendor: vendorFilter,
    product: productFilter,
    product_filter_operator: 'contains',
    vendor_filter_operator: 'contains',
    severity: cvssSeverityFilter,
    severity_filter_operator: cvssSeverityFilterOperator,
    first_observed: firstObservedFilter,
    first_observed_filter_operator: firstObservedFilterOperator,
    last_observed: lastObservedFilter,
    last_observed_filter_operator: lastObservedFilterOperator,
    source: sourceFilter.join(','),
    resource_object_id: asset.id,
    resource_content_type: 'httpasset',
  };

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // Clean up all asset_ query params on component unmount
      setAsnFilter([]);
      setDomainInputValue('');
      setDomainInputType(null);
      setCveNameFilter('');
      setCveNameFilterOperator(null);
      setVendorFilter('');
      setProductFilter('');
      setCvssSeverityFilter('');
      setCvssSeverityFilterOperator(null);
      setFirstObservedFilter('');
      setFirstObservedFilterOperator(null);
      setLastObservedFilter('');
      setLastObservedFilterOperator(null);
      setSourceFilter([]);
    };
  }, []);

  const { data, isLoading, error } = useQuery(
    getVulnerabilitiesListQuery(getVulnerabilitiesParams),
  );

  const { data: asnOverview } = useQuery(getVulnerabilityASNOverviewQuery);

  const { data: vulnerabilitiesProductsAndVendorsList } =
    useGetVulnerabilitiesProductsAndVendorsList();

  const handleSelectDomain = (value: string) => {
    setDomainInputValue(value);
    setPage(1);
  };

  const handleSelectDomainType = (value: string) => {
    setDomainInputType(value);
    setPage(1);
  };

  const handleSelectCVE = (value: string) => {
    setCveNameFilter(value);
    setPage(1);
  };

  const handleSelectCVEOperator = (value: string) => {
    setCveNameFilterOperator(value);
    setPage(1);
  };

  const handleSelectVendor = (value: string) => {
    setVendorFilter(value);
    setPage(1);
  };

  const handleSelectProduct = (value: string) => {
    setProductFilter(value);
    setPage(1);
  };

  const handleSelectCVSSSeverity = (value: string) => {
    setCvssSeverityFilter(value);
    setPage(1);
  };

  const handleSelectFirstObserved = (value: string) => {
    setFirstObservedFilter(value);
    setPage(1);
  };

  const handleSelectFirstObservedOperator = (value: string) => {
    setFirstObservedFilterOperator(value);
    setPage(1);
  };

  const handleSelectLastObserved = (value: string) => {
    setLastObservedFilter(value);
    setPage(1);
  };

  const handleSelectLastObservedOperator = (value: string) => {
    setLastObservedFilterOperator(value);
    setPage(1);
  };

  const handleSelectAsn = (value: string[]) => {
    setAsnFilter(value);
    setPage(1);
  };

  const handleSelectSource = (value: string[]) => {
    setSourceFilter(value);
    setPage(1);
  };

  const columns: ColumnDef<ResourceVulnerabilityObjectEntry>[] = [
    {
      header: 'Hostname',
      accessorKey: 'http_asset.hostname',
      cell: ({ row }) => (
        <LinkWithAction
          to={navigateWithParams(
            'assets',
            row.original.resource_object_id.toString(),
            'investigation',
          )}
          dataClickBypass={true}
          title="View Asset details"
          actions={[
            {
              label: 'Set Domain as Filter',
              onClick: () => handleSelectDomain(row.original.hostname),
              icon: <Filter />,
            },
          ]}
          showAsButton={true}
          buttonIcon={<Globe className="h-4 w-4" />}
        >
          {row.original.hostname}
        </LinkWithAction>
      ),
    },
    {
      header: 'Port',
      accessorKey: 'port',
      cell: ({ row }) => <div className="text-center">{row.original.port}</div>,
    },
    {
      header: () => <div className="text-center">Name</div>,
      accessorKey: 'vulnerability_name',
      cell: ({ row }) =>
        row.original.vulnerability_type === 'nvdvulnerabilities' ? (
          <LinkWithAction
            to={navigateWithParams(
              'vulnerabilities',
              row.original.vulnerability_name,
              'investigation',
            )}
            dataClickBypass={true}
            title="View Vulnerability details"
            actions={[
              {
                label: 'Set Name as Filter',
                onClick: () => handleSelectCVE(row.original.vulnerability_name),
                icon: <Filter />,
              },
            ]}
            showAsButton={true}
            buttonIcon={<LinkIcon className="h-4 w-4" />}
          >
            {row.original.vulnerability_name}
          </LinkWithAction>
        ) : (
          <span>{row.original.vulnerability_name}</span>
        ),
    },
    {
      header: 'Vendor',
      accessorFn: (row) => row.vendor,
      cell: ({ row }) => (
        <ElementWithActions
          dataClickBypass={true}
          actions={[
            {
              label: 'Set Vendor Filter',
              onClick: () => handleSelectVendor(row.original.vendor),
              icon: <Filter className="h-4 w-4" />,
            },
          ]}
        >
          {row.original.vendor}
        </ElementWithActions>
      ),
    },
    {
      header: 'Product',
      accessorFn: (row) => row.product,
      cell: ({ row }) => (
        <ElementWithActions
          dataClickBypass={true}
          actions={[
            {
              label: 'Set Product Filter',
              onClick: () => handleSelectProduct(row.original.product),
              icon: <Filter className="h-4 w-4" />,
            },
          ]}
        >
          {row.original.product}
        </ElementWithActions>
      ),
    },
    {
      accessorKey: 'severity',
      header: () => <div className="text-center">Severity</div>,
      cell: ({ row }) =>
        getSeverityDisplay({
          severity: row.original.severity,
          showLabel: true,
          showIcon: false,
        }),
    },
    {
      id: 'asn',
      accessorFn: (row) => row.resource_asn,
      header: 'ASN',
      cell: ({ row }) => (
        <ElementWithActions
          dataClickBypass={true}
          actions={[
            {
              label: 'Set ASN Filter',
              onClick: () => handleSelectAsn([row.original.resource_asn]),
              icon: <Filter className="h-4 w-4" />,
            },
          ]}
        >
          {row.original.resource_asn ?? 'N/A'}
        </ElementWithActions>
      ),
    },
    {
      id: 'source',
      accessorFn: (row) => row.source,
      header: 'Source',
      cell: ({ row }) => (
        <ElementWithActions
          dataClickBypass={true}
          actions={[
            {
              label: 'Set Source Filter',
              onClick: () => handleSelectSource([row.original.source]),
              icon: <Filter className="h-4 w-4" />,
            },
          ]}
        >
          {row.original.source.charAt(0).toUpperCase() + row.original.source.slice(1)}
        </ElementWithActions>
      ),
    },
    {
      id: 'first_identified',
      accessorFn: (row) => row.created_at,
      header: () => <div className="text-center">First Identified</div>,
      cell: ({ row }) => (
        <div className="flex justify-center text-xs">
          {formatShortDateTime(row.original.created_at)}
        </div>
      ),
    },
    {
      id: 'last_seen',
      accessorFn: (row) => row.last_scanned_at,
      header: () => <div className="text-center">Service Last Seen</div>,
      cell: ({ row }) => (
        <div className="flex justify-center text-xs">
          {formatShortDateTime(row.original.last_scanned_at)}
        </div>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleNavigateToAsset = (vulnerability: ResourceVulnerabilityObjectEntry) => {
    const url =
      vulnerability.vulnerability_type === 'nvdvulnerabilities'
        ? navigateWithParams(`investigation`, vulnerability.id.toString(), 'investigation', {
            type: 'passive',
          })
        : navigateWithParams(`investigation`, vulnerability.id.toString(), 'investigation', {
            type: 'nuclei',
          });
    navigate(url);
  };

  const filters: AvailableFilter[] = [
    {
      type: 'select',
      key: 'vendor',
      label: 'Vendor',
      placeholder: 'Filter by Vendor',
      options: vulnerabilitiesProductsAndVendorsList?.vendors ?? [],
      setState: handleSelectVendor,
      state: vendorFilter,
      onClear: () => {},
    },
    {
      type: 'select',
      key: 'product',
      label: 'Product',
      placeholder: 'Filter by Product',
      options: vulnerabilitiesProductsAndVendorsList?.products ?? [],
      setState: handleSelectProduct,
      state: productFilter,
      onClear: () => {},
    },
    {
      type: 'text-with-operator',
      key: 'name',
      label: 'Name',
      placeholder: 'Filter by Name',
      setState: handleSelectCVE,
      state: cveNameFilter,
      setValueType: handleSelectCVEOperator,
      valueType: cveNameFilterOperator,
      options: [
        {
          label: 'equals',
          value: 'equals',
        },
        {
          label: 'contains',
          value: 'contains',
        },
      ],
      defaultValue: 'equals',
      onClear: () => {
        setCveNameFilter('');
        setCveNameFilterOperator(null);
      },
    },
    {
      type: 'multiSelect',
      key: 'asn',
      label: 'ASN',
      placeholder: 'Filter by ASN',
      options: asnOverview ?? [],
      setState: handleSelectAsn,
      state: asnFilter,
    },
    {
      type: 'text-with-operator',
      key: 'hostname',
      label: 'Hostname',
      placeholder: 'Filter by Hostname',
      setState: handleSelectDomain,
      state: domainInputValue,
      setValueType: handleSelectDomainType,
      valueType: domainInputType,
      onClear: () => {
        setDomainInputValue('');
        setDomainInputType(null);
      },
      options: [
        {
          label: 'starts with',
          value: 'startswith',
        },
        {
          label: 'contains',
          value: 'contains',
        },
      ],
      defaultValue: 'startswith',
    },
    {
      type: 'multiSelect',
      key: 'severity',
      label: 'Severity',
      placeholder: 'Severity',
      valueType: 'severity',
      options: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' },
        { label: 'Critical', value: 'critical' },
        { label: 'Informational', value: 'informational' },
      ],
      state: cvssSeverityFilter,
      setState: handleSelectCVSSSeverity,
      onClear: () => {
        setCvssSeverityFilter('');
        setCvssSeverityFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'first_observed',
      label: 'First Observed',
      placeholder: 'Example: 1d, or 1h',
      setState: handleSelectFirstObserved,
      state: firstObservedFilter,
      setValueType: handleSelectFirstObservedOperator,
      valueType: firstObservedFilterOperator,
      defaultValue: 'newer_than',
      options: [
        {
          label: 'newer than',
          value: 'newer_than',
        },
        {
          label: 'older than',
          value: 'older_than',
        },
      ],
      onClear: () => {
        setFirstObservedFilter('');
        setFirstObservedFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'last_observed',
      label: 'Last Observed',
      placeholder: 'Example: 1d, or 1h',
      setState: handleSelectLastObserved,
      state: lastObservedFilter,
      setValueType: handleSelectLastObservedOperator,
      valueType: lastObservedFilterOperator,
      options: [
        {
          label: 'newer than',
          value: 'newer_than',
        },
        {
          label: 'older than',
          value: 'older_than',
        },
      ],
      defaultValue: 'newer_than',
      onClear: () => {
        setLastObservedFilter('');
        setLastObservedFilterOperator(null);
      },
    },
    {
      type: 'multiSelect',
      key: 'source',
      label: 'Source',
      placeholder: 'Select source(s)',
      setState: handleSelectSource,
      state: sourceFilter,
      options: [
        {
          label: 'Passive',
          value: 'passive',
        },
        {
          label: 'Dynamic',
          value: 'dynamic',
        },
      ],
      onClear: () => {
        setSourceFilter([]);
      },
    },
  ];

  const clearAllFilters = () => {
    setPage(1);
    setAsnFilter([]);
    setDomainInputValue('');
    setDomainInputType(null);
    setCveNameFilter('');
    setCveNameFilterOperator(null);
    setVendorFilter('');
    setProductFilter('');
    setCvssSeverityFilter('');
    setCvssSeverityFilterOperator(null);
    setFirstObservedFilter('');
    setFirstObservedFilterOperator(null);
    setLastObservedFilter('');
    setLastObservedFilterOperator(null);
    setSourceFilter([]);
  };

  return (
    <div className="max-w-[2000px] flex flex-col gap-2">
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-row gap-6 w-full justify-start">
          <UniversalFilter filters={filters} clearAllFilters={clearAllFilters} />
        </div>
        <DataTable
          columns={columns}
          data={data?.entries ?? []}
          loading={isLoading}
          currentPage={page}
          totalPages={data?.total_pages ?? 1}
          totalEntries={data?.total_count ?? 0}
          onPageChange={handlePageChange}
          error={error}
          tableHeight="calc(100vh - 18.5rem)"
          maxWidth="w-full"
          onRowClick={handleNavigateToAsset}
        />
      </div>
    </div>
  );
}
