import { useAuth } from '@/Helpers/AuthContext';
import * as Analytics from '@/lib/analytics';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook to use analytics functions throughout the application
 */
export function useAnalytics() {
  const initialized = useRef(false);
  const { userData } = useAuth();

  // Initialize analytics only once
  useEffect(() => {
    if (!initialized.current) {
      Analytics.initializeAnalytics();
      initialized.current = true;
    }
  }, []);

  // Set user ID when user changes and is available
  useEffect(() => {
    if (userData?.id) {
      // Using a hashed or anonymized ID is recommended
      Analytics.setUserId(String(userData.id));
    }
  }, [userData]);

  // Function to track page views - should be called inside components that have router context
  const trackPageView = useCallback((path: string, title?: string) => {
    Analytics.trackPageView(path, title);
  }, []);

  // Memoized helper functions
  const trackEvent = useCallback(
    (category: string, action: string, label?: string, value?: number) => {
      Analytics.trackEvent(category, action, label, value);
    },
    [],
  );

  const trackTiming = useCallback(
    (category: string, variable: string, value: number, label?: string) => {
      Analytics.trackTiming(category, variable, value, label);
    },
    [],
  );

  const startTimeTracking = useCallback((category: string, variable: string, label?: string) => {
    return Analytics.startTimeTracking(category, variable, label);
  }, []);

  // Track feature usage by combining page and event tracking
  const trackFeatureUsage = useCallback((featureName: string, action: string) => {
    Analytics.trackEvent('Feature', action, featureName);
  }, []);

  // Track search queries
  const trackSearch = useCallback((searchTerm: string, category: string, results: number) => {
    Analytics.trackEvent('Search', 'query', `${category}: ${searchTerm}`, results);
  }, []);

  return {
    trackPageView,
    trackEvent,
    trackTiming,
    startTimeTracking,
    trackFeatureUsage,
    trackSearch,
  };
}
