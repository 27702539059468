import { trackVisitSource } from '@/lib/analytics';
import { useAnalyticsContext } from '@/providers/AnalyticsProvider';
import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

/**
 * Hook to automatically track page views with analytics
 * Must be used within a Router context
 */
export function useRouterAnalytics() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { trackPageView } = useAnalyticsContext();
  const initialRenderRef = useRef(true);

  // Track page views when location changes
  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location, trackPageView]);

  // Track visit source from URL parameters on initial render only
  useEffect(() => {
    if (initialRenderRef.current) {
      trackVisitSource(searchParams);
      initialRenderRef.current = false;
    }
  }, [searchParams]);

  return {
    // Add any router-specific analytics functions here
    currentPath: location.pathname + location.search,
    searchParams,
  };
}
