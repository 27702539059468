import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { ReferencesCard } from '../GenAITabs/AIReferences';

interface AIAnalysisProps {
  impact?: string;
  evidence?: string;
  aiThreatActorActivity?: string;
  aiThreatActorActivityReferences?: string[];
}

export function AIAnalysisSection({
  impact,
  evidence,
  aiThreatActorActivity,
  aiThreatActorActivityReferences,
}: AIAnalysisProps) {
  if (!impact && !evidence) return null;

  return (
    <>
      {impact && (
        <div>
          <h3 className="text-base font-medium mb-1">Impact</h3>
          <div>
            <MarkdownViewer markdown={impact} />
          </div>
        </div>
      )}
      {evidence && (
        <div>
          <h3 className="text-base font-medium mb-1">Evidence</h3>
          <div>
            <MarkdownViewer markdown={evidence} />
          </div>
        </div>
      )}
      {aiThreatActorActivity && (
        <div>
          <h3 className="text-base font-medium mb-1">Threat Actor Activity</h3>
          <div>
            <MarkdownViewer markdown={aiThreatActorActivity} />
            <ReferencesCard
              references={aiThreatActorActivityReferences}
              tab="analysis"
              defaultOpen={false}
            />
          </div>
        </div>
      )}
    </>
  );
}
