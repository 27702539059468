import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { AssigneeSelector } from '@/pages/Issues/AssigneeSelector';
import { StatusSelector } from '@/pages/Issues/StatusSelector';
import { Issue, ISSUE_STATUSES, ISSUE_STATUSES_NAMES } from '@/types/api/response/issues';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { getSeverityDisplay } from '@/v2/components/SeverityBadge/SeverityBadge';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { getIssuesQuery, useUpdateIssueMutation } from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { formatShortDateTime } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Filter, Globe, Network } from 'lucide-react';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Ellipsis } from '@/components/common/Ellipsis';

const Issues = () => {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [severity, setSeverity] = useQueryState(
    'severity',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [status, setStatus] = useQueryState(
    'status',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [assignedTo, setAssignedTo] = useQueryState('assigned_to', parseAsString.withDefault(''));
  const [createdAtFilterOperator, setCreatedAtFilterOperator] = useQueryState(
    'created_at_filter_operator',
    parseAsString.withDefault('greater_than'),
  );
  const [createdAtFilterValue, setCreatedAtFilterValue] = useQueryState(
    'created_at_filter_value',
    parseAsString.withDefault(''),
  );
  const [affectedResource, setAffectedResource] = useState<any>(null);
  const [affectedResourceOperator, setAffectedResourceOperator] = useState<any>(null);
  const navigate = useNavigate();
  const { navigateWithParams } = useNavigateWithParams();

  const { data, error, isPending } = useQuery(
    getIssuesQuery({
      page,
      severity: severity.join(','),
      status: status.join(','),
      assigned_to: assignedTo,
      affected_resource_id: affectedResource?.id,
      affected_resource_type: affectedResourceOperator,
      created_at_filter_operator: createdAtFilterOperator,
      created_at: createdAtFilterValue,
    }),
  );
  const { mutate: updateIssue } = useUpdateIssueMutation();
  const { data: users } = useQuery(getUsersQuery());

  type IssueStatus = keyof typeof ISSUE_STATUSES;

  const handleStatusChange = (issue: Issue, status: IssueStatus) => {
    updateIssue({
      issueId: issue.issue_number,
      updates: {
        ...issue,
        status,
      },
    });
  };

  const getStatusDisplay = (issue: Issue) => (
    <StatusSelector
      currentStatus={issue.status}
      onStatusChange={(status) => handleStatusChange(issue, status as IssueStatus)}
      triggerClassName="status-popover flex justify-start"
    />
  );

  const getAffectedResourceDisplay = (issue: Issue) => {
    if (!issue.resource_vulnerabilities[0]) return null;
    const affectedResource = issue.resource_vulnerabilities[0].http_asset;
    const resourceType = issue.resource_vulnerabilities[0].resource_type;

    const formatResourceType = (type: string) => {
      switch (type.toLowerCase()) {
        case 'ipaddressv4':
          return 'IPAddressV4';
        case 'httpasset':
          return 'HTTPAsset';
        case 'domains':
          return 'Domains';
        default:
          return type;
      }
    };

    switch (formatResourceType(resourceType)) {
      case 'IPAddressV4':
        return (
          <LinkWithAction
            showAsButton={true}
            buttonIcon={<Network className="h-4 w-4" />}
            to={navigateWithParams('ipaddress', affectedResource.id.toString(), 'issues')}
            dataClickBypass={true}
            title="View IP Address"
            target="_self"
            actions={[
              {
                label: 'Set as Affected Resource',
                onClick: () => {
                  setAffectedResource(affectedResource);
                  setAffectedResourceOperator(formatResourceType(resourceType));
                },
                icon: <Filter />,
              },
            ]}
          >
            {affectedResource?.ipaddress ?? '-'}
          </LinkWithAction>
        );
      case 'HTTPAsset':
        return (
          <LinkWithAction
            to={navigateWithParams('assets', affectedResource.id.toString(), 'issues')}
            buttonIcon={<Globe className="h-4 w-4" />}
            showAsButton={true}
            dataClickBypass={true}
            title="View Asset"
            target="_self"
            actions={[
              {
                label: 'Set as Affected Resource',
                onClick: () => {
                  setAffectedResource(affectedResource);
                  setAffectedResourceOperator(formatResourceType(resourceType));
                },
                icon: <Filter />,
              },
            ]}
          >
            {affectedResource?.hostname ?? '-'}
          </LinkWithAction>
        );
      case 'Domains':
        return (
          <LinkWithAction
            showAsButton={true}
            buttonIcon={<Globe className="h-4 w-4" />}
            to={`/assets/domains?domain_search_value=${affectedResource.domainname}`}
            dataClickBypass={true}
            title="View Domain"
            target="_self"
            actions={[
              {
                label: 'Set as Affected Resource',
                onClick: () => {
                  setAffectedResource(affectedResource);
                  setAffectedResourceOperator(formatResourceType(resourceType));
                },
                icon: <Filter />,
              },
            ]}
          >
            {affectedResource?.domainname ?? '-'}
          </LinkWithAction>
        );
      default:
        return '-';
    }
  };

  const handleUpdate = (issue: Issue, updates: Partial<Issue>) => {
    updateIssue({
      issueId: issue.issue_number,
      updates: {
        ...issue,
        ...updates,
      },
    });
  };

  const columnHelper = createColumnHelper<Issue>();

  const columns: ColumnDef<Issue>[] = [
    columnHelper.accessor('issue_number', {
      header: 'ID',
    }),
    columnHelper.accessor('severity', {
      header: 'Severity',
      cell: ({ row }) =>
        getSeverityDisplay({
          severity: row.original.severity,
          showLabel: true,
          showIcon: false,
        }),
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: ({ row }) => <Ellipsis text={row.original.title} maxLength={75} />,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ row }) => getStatusDisplay(row.original),
    }),
    columnHelper.accessor((row) => row, {
      header: 'Affected Resource',
      cell: ({ row }) => getAffectedResourceDisplay(row.original),
    }),
    columnHelper.accessor('assigned_to.user.first_name', {
      header: 'Assigned To',
      cell: ({ row }) => (
        <AssigneeSelector
          users={users?.entries ?? []}
          assigneeId={row.original.assigned_to?.user?.id}
          assigneeName={
            row.original.assigned_to?.user
              ? `${row.original.assigned_to.user.first_name} ${row.original.assigned_to.user.last_name}`
              : 'Unassigned'
          }
          onAssigneeChange={(userId) => handleUpdate(row.original, { assigned_to_id: userId })}
          data-click-bypass="true"
        />
      ),
    }),
    columnHelper.accessor('created_at', {
      header: 'Created At',
      cell: ({ row }) => (
        <span className="text-xs">{formatShortDateTime(row.original.created_at)}</span>
      ),
    }),
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRowClick = (row: Issue) => {
    navigate(navigateWithParams('issues', row.issue_number.toString(), 'issues'));
  };

  const filters: AvailableFilter[] = [
    {
      label: 'Severity',
      key: 'severity',
      type: 'multiSelect',
      placeholder: 'Severity',
      valueType: 'severity',
      options: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' },
        { label: 'Critical', value: 'critical' },
        { label: 'Informational', value: 'informational' },
      ],
      state: severity,
      setState: setSeverity as (value: string | string[] | DateRange | string | null) => void,
    },
    {
      label: 'Status',
      key: 'status',
      type: 'multiSelect',
      placeholder: 'Status',
      valueType: 'status',
      options: [
        ...Object.entries(ISSUE_STATUSES_NAMES).map(([key, value]) => ({
          label: value,
          value: key,
        })),
      ],
      state: status,
      setState: setStatus as (value: string | string[] | DateRange | string | null) => void,
    },
    {
      label: 'Affected Resource',
      key: 'affected_resource',
      type: 'issues-affected-resources',
      placeholder: 'Affected Resource',
      state: affectedResource,
      setState: (value: any | null) => setAffectedResource(value),
      valueType: affectedResourceOperator,
      setValueType: (value: string) => setAffectedResourceOperator(value),
      onClear: () => {
        setAffectedResource(null);
        setAffectedResourceOperator(null);
      },
    },
    {
      label: 'Assigned To',
      key: 'assigned_to',
      type: 'select',
      placeholder: 'Assigned To',
      valueType: 'assigned_to',
      options: [
        { label: 'All', value: null },
        { label: 'Unassigned', value: 'unassigned' },
        ...(users?.entries ?? []).map((user) => ({
          label: `${user.user.first_name} ${user.user.last_name}`,
          value: user.user.id.toString(),
        })),
      ],
      state: assignedTo ? assignedTo.toString() : null,
      setState: (value: string) => setAssignedTo(value ? value.toString() : null),
    },
    {
      label: 'Created At',
      key: 'created_at',
      type: 'text-with-operator',
      placeholder: '1d or 1h',
      state: createdAtFilterValue,
      setState: setCreatedAtFilterValue as (
        value: string | string[] | DateRange | string | null,
      ) => void,
      valueType: createdAtFilterOperator,
      setValueType: setCreatedAtFilterOperator as (value: string) => void,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
    },
  ];

  const clearAllFilters = () => {
    setSeverity(null);
    setStatus(null);
    setAffectedResource(null);
    setAffectedResourceOperator(null);
    setAssignedTo(null);
    setCreatedAtFilterValue(null);
    setCreatedAtFilterOperator(null);
  };

  return (
    <div className="max-w-[2000px] flex flex-col gap-4 mx-4 py-4">
      <Helmet>
        <title>Issues</title>
      </Helmet>
      <div className="flex flex-row gap-6 w-full justify-start">
        <UniversalFilter filters={filters} clearAllFilters={clearAllFilters} />
      </div>

      <DataTable
        columns={columns}
        data={data?.entries ?? []}
        loading={isPending}
        currentPage={page}
        totalPages={data?.total_pages ?? 1}
        totalEntries={data?.total_count ?? 0}
        onPageChange={handlePageChange}
        error={error}
        tableHeight="calc(100vh - 11rem)"
        maxWidth="w-full"
        onRowClick={handleRowClick}
        noResultsMessage={'No open issues, nice work!'}
      />
    </div>
  );
};

export default Issues;
