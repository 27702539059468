import { Tip } from '@/components/common/Tip';
import { Button } from '@/components/ui/button';
import { useAssetQueries } from '@/hooks/useAssetQueries';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { useAnalyticsContext } from '@/providers/AnalyticsProvider';
import { AssetWithDetails } from '@/types/api/response/assets';
import { AIActionsMenu } from '@/v2/components/AIActionsMenu/AIActionsMenu';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { ElementWithActions } from '@/v2/components/ElementWithActions/ElementWithActions';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { usePrefetchAssetDetails, usePrefetchAssetHistoryQuery } from '@/v2/queries/assets';
import { formatShortDateTime } from '@/v2/utils';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ArrowDown, ArrowUp, ArrowUpDown, Filter, Globe, Network } from 'lucide-react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

const Assets = () => {
  const [searchParams] = useSearchParams();
  const { trackEvent, trackFeatureUsage } = useAnalyticsContext();
  const {
    mappedAsns,
    mappedSelectedAsns,
    page,
    handlePageChange,
    assets,
    isPending,
    error,
    totalPages,
    totalCount,
    prefetchChangePage,
    setDomainname,
    setDomainnameFilterOperator,
    setAsn,
    setTechnology,
    setTechnologyFilterOperator,
    setCreatedAt,
    setCreatedAtFilterOperator,
    setDomainSource,
    setDomainSourceFilterOperator,
    setLastScanned,
    setLastScannedFilterOperator,
    setIpaddress,
    setIpaddressFilterOperator,
    setTechnologyChangeWindow,
    setTechnologyChangeWindowFilterOperator,
    domainname,
    domainnameFilterOperator,
    technology,
    technologyFilterOperator,
    createdAt,
    createdAtFilterOperator,
    lastScanned,
    lastScannedFilterOperator,
    domainSource,
    domainSourceFilterOperator,
    technologyChangeWindow,
    technologyChangeWindowFilterOperator,
    ipaddress,
    ipaddressFilterOperator,
    technologiesSuggestions,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  } = useAssetQueries();
  const prefetchAssetHistory = usePrefetchAssetHistoryQuery();
  const prefetchAssetDetails = usePrefetchAssetDetails();
  const { navigateWithParams } = useNavigateWithParams();

  const handlePrefetchHistory = (asset: AssetWithDetails) => {
    prefetchAssetHistory({ assetId: asset.id });
    prefetchAssetDetails({ assetId: asset.id });
  };

  const TechnologiesList = ({ technologies }: { technologies: string[] }) => {
    const filteredTech = technologies.map((tech) => {
      if (tech.startsWith('Font Awesome:')) {
        return 'Font Awesome';
      }
      return tech;
    });

    const maxLength = 20;
    let displayedTech = '';
    let totalLength = 0;
    const remainingTech = [];

    for (const tech of filteredTech) {
      if (totalLength + tech.length > maxLength) {
        remainingTech.push(tech);
      } else {
        displayedTech += (displayedTech ? ', ' : '') + tech;
        totalLength += tech.length + 2;
      }
    }

    const remainingCount = remainingTech.length;

    const technologiesTip = (
      <div>
        <span className="font-bold">All technologies</span>
        <span>
          {filteredTech.map((tech) => (
            <div key={tech}>
              {tech}
              <br />
            </div>
          ))}
        </span>
      </div>
    );

    return (
      <div className="flex items-center text-xs text-nowrap">
        {displayedTech || '-'}
        {remainingCount > 0 && (
          <Tip content={technologiesTip}>
            <Button
              variant="link"
              className="text-slate-500 px-0 py-0 hover:underline ml-1 text-xs"
            >
              +{remainingCount} more
            </Button>
          </Tip>
        )}
      </div>
    );
  };

  const handleAnalyzeXHR = (asset: AssetWithDetails) => {
    trackEvent('Asset', 'analyze_xhr', asset.hostname);
    const url = `/http_analyzer?url=http${asset.port === 443 ? 's' : ''}://${asset.hostname}`;
    window.open(url, '_blank');
  };

  const handleNavigateToAsset = (assetId: string) => {
    trackEvent('Asset', 'view_details', assetId);
    const params = new URLSearchParams(searchParams);
    params.delete('technology');
  };

  // Track filter changes
  useEffect(() => {
    if (domainname) {
      trackEvent('Asset Filter', 'apply', `domainname:${domainnameFilterOperator}:${domainname}`);
    }
    if (technology) {
      trackEvent('Asset Filter', 'apply', `technology:${technologyFilterOperator}:${technology}`);
    }
    if (createdAt) {
      trackEvent('Asset Filter', 'apply', `created_at:${createdAtFilterOperator}:${createdAt}`);
    }
    if (lastScanned) {
      trackEvent(
        'Asset Filter',
        'apply',
        `last_scanned:${lastScannedFilterOperator}:${lastScanned}`,
      );
    }
    if (domainSource) {
      trackEvent(
        'Asset Filter',
        'apply',
        `domain_source:${domainSourceFilterOperator}:${domainSource}`,
      );
    }
    if (ipaddress) {
      trackEvent('Asset Filter', 'apply', `ipaddress:${ipaddressFilterOperator}:${ipaddress}`);
    }
    if (technologyChangeWindow) {
      trackEvent(
        'Asset Filter',
        'apply',
        `tech_change:${technologyChangeWindowFilterOperator}:${technologyChangeWindow}`,
      );
    }
  }, [
    domainname,
    domainnameFilterOperator,
    technology,
    technologyFilterOperator,
    createdAt,
    createdAtFilterOperator,
    lastScanned,
    lastScannedFilterOperator,
    domainSource,
    domainSourceFilterOperator,
    ipaddress,
    ipaddressFilterOperator,
    technologyChangeWindow,
    technologyChangeWindowFilterOperator,
    trackEvent,
  ]);

  // Track page changes
  useEffect(() => {
    trackEvent('Asset List', 'page_change', undefined, page);
  }, [page, trackEvent]);

  // Track initial page load
  useEffect(() => {
    trackFeatureUsage('Asset List', 'view');
  }, [trackFeatureUsage]);

  const headerWithSorting = (header: string, key: string) => {
    const isSelected = sortField === key;
    return (
      <div className="flex items-center gap-2">
        <span className={isSelected ? 'font-bold' : ''}>{header}</span>
        {isSelected && sortDirection === 'asc' ? (
          <ArrowUp
            className="h-4 w-4 cursor-pointer text-primary"
            onClick={() => {
              setSortField(key);
              setSortDirection('desc');
            }}
            data-click-bypass="true"
          />
        ) : isSelected && sortDirection === 'desc' ? (
          <ArrowDown
            className="h-4 w-4 cursor-pointer text-primary"
            onClick={() => {
              setSortField('');
              setSortDirection('');
            }}
            data-click-bypass="true"
          />
        ) : (
          <ArrowUpDown
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setSortField(key);
              setSortDirection('asc');
            }}
            data-click-bypass="true"
          />
        )}
      </div>
    );
  };

  const columnHelper = createColumnHelper<AssetWithDetails>();

  const columns: ColumnDef<AssetWithDetails>[] = [
    {
      id: 'actions',
      header: () => <div className="w-12">Actions</div>, // Fixed width using Tailwind
      cell: ({ row }) => (
        <AIActionsMenu>
          {[
            <span
              key="analyze-xhr"
              className="flex justify-center items-center cursor-pointer"
              data-click-bypass="true"
              onClick={() => handleAnalyzeXHR(row.original)}
              title="Analyze XHR"
            >
              Analyze XHR
            </span>,
          ]}
        </AIActionsMenu>
      ),
    },
    columnHelper.accessor('hostname', {
      header: () => headerWithSorting('Hostname', 'hostname'),
      cell: ({ row }) => (
        <LinkWithAction
          onClick={() => handleNavigateToAsset(row.original.id.toString())}
          showAsButton={true}
          buttonIcon={<Globe className="h-4 w-4" />}
          to={navigateWithParams('assets', row.original.id.toString(), 'assets')}
          title="View asset details"
          dataClickBypass={true}
          actions={[
            {
              label: 'Set Domain Name Filter',
              onClick: () => setDomainname(row.original.hostname),
              icon: <Filter />,
            },
          ]}
        >
          {row.original.hostname}
        </LinkWithAction>
      ),
    }),
    columnHelper.accessor('port', {
      header: 'Port',
      cell: ({ row }) => row.original.port,
    }),
    columnHelper.accessor('ipAddress', {
      header: 'IP Address',
      cell: ({ row }) => {
        const ipAddresses = row.original.dns_record.ipaddress;
        if (ipAddresses.length === 1) {
          return (
            <LinkWithAction
              showAsButton={true}
              buttonIcon={<Network className="h-4 w-4" />}
              to={navigateWithParams('ipaddress', ipAddresses[0].ipaddress, 'assets')}
              title="View IP Address details"
              dataClickBypass={true}
              actions={[
                {
                  label: 'Set IP Address Filter',
                  onClick: () => setIpaddress(ipAddresses[0].ipaddress),
                  icon: <Filter />,
                },
              ]}
            >
              {ipAddresses[0].ipaddress}
            </LinkWithAction>
          );
        }
        return (
          <div className="flex flex-col gap-2">
            {ipAddresses.map((ip) => (
              <LinkWithAction
                showAsButton={true}
                buttonIcon={<Network className="h-4 w-4" />}
                key={ip.ipaddress}
                to={navigateWithParams('ipaddress', ip.ipaddress, 'assets')}
                dataClickBypass={true}
                title="View IP Address details"
                actions={[
                  {
                    label: 'Set IP Address Filter',
                    onClick: () => setIpaddress(ip.ipaddress),
                    icon: <Filter />,
                  },
                ]}
              >
                {ip.ipaddress}
              </LinkWithAction>
            ))}
          </div>
        );
      },
    }),
    {
      header: 'Owner',
      accessorFn: (row) => (row.asnDetails ? row.asnDetails.owner : 'N/A'),
      cell: ({ row }) => (
        <ElementWithActions
          dataClickBypass={true}
          actions={[
            {
              label: 'Set ASN Filter',
              onClick: () =>
                setAsn([
                  row.original.asnDetails.asn !== 'N/A' ? row.original.asnDetails.asn : 'Unknown',
                ]),
              icon: <Filter />,
            },
          ]}
        >
          <div className="w-fit text-xs">
            {row.original.asnDetails ? row.original.asnDetails.owner : 'N/A'}
          </div>
        </ElementWithActions>
      ),
    },
    {
      header: 'Technology',
      cell: ({ row }) => <TechnologiesList technologies={row.original.technologies} />,
    },
    columnHelper.accessor('total_urls', {
      header: 'URLs',
      cell: ({ row }) => row.original.total_urls,
    }),
    columnHelper.accessor('vulnerabilities_count', {
      header: 'Vulnerabilities',
      cell: ({ row }) => row.original.vulnerabilities_count,
    }),
    columnHelper.accessor('last_scanned', {
      header: () => headerWithSorting('Last Scanned', 'last_scanned'),
      cell: ({ row }) => (
        <span className="text-xs">{formatShortDateTime(row.original.last_scanned)}</span>
      ),
    }),
    columnHelper.accessor('created_at', {
      header: () => headerWithSorting('Created', 'created_at'),
      cell: ({ row }) => (
        <span className="text-xs">{formatShortDateTime(row.original.created_at)}</span>
      ),
    }),
  ];

  const availableFilters: AvailableFilter[] = [
    {
      type: 'multiSelect',
      key: 'asn',
      label: 'ASN',
      placeholder: 'Select ASNs',
      options: mappedAsns,
      state: mappedSelectedAsns,
      setState: setAsn,
    },
    {
      type: 'domainSearch',
      key: 'domainname',
      label: 'Hostname',
      placeholder: 'Enter Hostname',
      state: domainname,
      setState: setDomainname,
      valueType: domainnameFilterOperator,
      setValueType: setDomainnameFilterOperator,
      options: [
        {
          label: 'Starts with',
          value: 'startswith',
        },
        {
          label: 'Contains',
          value: 'contains',
        },
      ],
    },
    {
      type: 'text-with-operator',
      key: 'ipaddress',
      label: 'IP Address',
      placeholder: 'Enter IP Address',
      state: ipaddress,
      setState: setIpaddress,
      valueType: ipaddressFilterOperator,
      setValueType: setIpaddressFilterOperator,
      options: [
        {
          label: 'Include',
          value: 'include',
        },
        {
          label: 'Exclude',
          value: 'exclude',
        },
      ],
      onClear: () => {
        setIpaddress('');
        setIpaddressFilterOperator(null);
      },
    },
    {
      type: 'select-with-operator',
      key: 'technology',
      label: 'Technology',
      placeholder: 'Select Technology',
      state: technology,
      setState: setTechnology,
      valueType: technologyFilterOperator,
      setValueType: setTechnologyFilterOperator,
      options: technologiesSuggestions.map((tech) => ({ label: tech, value: tech })),
      operatorOptions: [
        {
          label: 'Include',
          value: 'include',
        },
        {
          label: 'Exclude',
          value: 'exclude',
        },
      ],
      onClear: () => {
        setTechnology('');
        setTechnologyFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'technologyChangeWindow',
      label: 'Technology Change Window',
      placeholder: '1d or 1h',
      state: technologyChangeWindow,
      setState: setTechnologyChangeWindow,
      valueType: technologyChangeWindowFilterOperator,
      setValueType: setTechnologyChangeWindowFilterOperator,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      onClear: () => {
        setTechnologyChangeWindow('');
        setTechnologyChangeWindowFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'createdAt',
      label: 'Created At',
      placeholder: '1d or 1h',
      state: createdAt,
      setState: setCreatedAt,
      valueType: createdAtFilterOperator,
      setValueType: setCreatedAtFilterOperator,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
        {
          label: 'Newer Than or Equal To',
          value: 'greater_than_or_equals',
        },
      ],
      operatorOptions: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      onClear: () => {
        setCreatedAt('');
        setCreatedAtFilterOperator(null);
      },
    },
    {
      type: 'select-with-operator',
      key: 'domainSource',
      label: 'Domain Source',
      placeholder: 'Select Domain Source',
      state: domainSource,
      setState: setDomainSource,
      valueType: domainSourceFilterOperator,
      setValueType: setDomainSourceFilterOperator,
      options: technologiesSuggestions.map((tech) => ({ label: tech, value: tech })),
      operatorOptions: [
        {
          label: 'Include',
          value: 'include',
        },
        {
          label: 'Exclude',
          value: 'exclude',
        },
      ],
      onClear: () => {
        setDomainSource('');
        setDomainSourceFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'lastScanned',
      label: 'Last Scanned',
      placeholder: '1d or 1h',
      state: lastScanned,
      setState: setLastScanned,
      valueType: lastScannedFilterOperator,
      setValueType: setLastScannedFilterOperator,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      onClear: () => {
        setLastScanned('');
        setLastScannedFilterOperator(null);
      },
    },
  ];

  return (
    <div className="mx-4 mt-4 max-w-[2000px]">
      <Helmet>
        <title> Assets </title>
      </Helmet>
      <UniversalFilter filters={availableFilters} clearAllFilters={() => {}} className="mb-4" />
      <DataTable
        columns={columns}
        data={assets}
        loading={isPending}
        currentPage={page}
        totalPages={totalPages}
        totalEntries={totalCount}
        onPageChange={handlePageChange}
        error={error}
        tableHeight="calc(100vh - 11rem)"
        onRowHover={(row) => handlePrefetchHistory(row)}
        prefetchChangePage={prefetchChangePage}
      />
    </div>
  );
};

export default Assets;
