import { createLogger } from '@/common/lib/logger';
import { useFeatureFlags } from '@/context/FeatureFlagContext';
import { useAuth } from '@/Helpers/AuthContext';
import { QueryClient } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  ErrorResponse,
  Navigate,
  Outlet,
  useNavigate,
  useRouteError,
} from 'react-router-dom';
import IssueDetails from '../../pages/Issues/IssueDetails';
import LayoutHeader from '../components/LayoutHeader/LayoutHeader';
import { getAssetsListQuery } from '../queries/assets';
import AnonymousLayout from './AnonymousLayout';
import { AddAssetDialog } from './Assets/AddAssetDialog';
import AssetDetails from './Assets/AssetDetails';
import Assets from './Assets/Assets';
import Domains from './Assets/Domains';
import Services from './Assets/Services';
import ChangePassword from './Auth/ChangePassword';
import EmailVerification from './Auth/EmailVerification';
import ForgotPassword from './Auth/ForgotPassword';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import Register from './Auth/Register';
import ResetPassword from './Auth/ResetPassword';
import ErrorPage from './Exceptions/ErrorPage';
import ExecDashboard from './ExecDashboard/ExecDashboard';
import HTTPAnalizer from './HTTPAnalizer/HTTPAnalizer';
import Passive from './Investigation/Pasive';
import PassiveDetails from './Investigation/PasiveDetails';
import { IPAdressDetailsView } from './IPAdress/IPAdressDetailsView';
import { AddIssueButton } from './Issues/AddIssueButton';
import Issues from './Issues/Issues';
import NewIssuePage from './Issues/NewIssuePage';
import LoggedInLayout from './LoggedInLayout';
import Notes from './Notes/Notes';
import Settings from './User/Settings';
import VulnerabilityDetails from './Vulnerabilities/VulnerabilitiesDetails';
const log = createLogger('router');

const ErrorDisplayComponent = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: any;
}) => {
  log('App error', error);
  return <ErrorPage resetErrorBoundary={resetErrorBoundary} />;
};

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useAuth();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate(`/login?redirect=${window.location.pathname}`);
    }
  }, [loading, isAuthenticated, navigate]);

  if (loading) {
    return <div />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return <ErrorBoundary FallbackComponent={ErrorDisplayComponent}>{children}</ErrorBoundary>;
};

const RouterErrorBoundary = () => {
  const error = useRouteError() as ErrorResponse;

  if (error.status === 404) {
    return <ErrorPage code={404} />;
  }

  if (error.status >= 500) {
    return <ErrorPage code={500} />;
  }

  return (
    <ErrorPage
      code={500}
      message={error?.data ?? 'Something went wrong. Please try again later.'}
    />
  );
};

const queryClient = new QueryClient();

const prefetchAssetsList = () => {
  queryClient.prefetchQuery(getAssetsListQuery());
};

const HomeRedirect = () => {
  const { hasFeatureFlag, isLoading, featureFlags } = useFeatureFlags();

  if (isLoading || featureFlags['EXEC_DASHBOARD'] === undefined) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8" />
      </div>
    );
  }

  const showExecDashboard = hasFeatureFlag('EXEC_DASHBOARD');
  return <Navigate to={showExecDashboard ? '/dashboard' : '/issues'} replace />;
};

const DashboardHeader = () => {
  const { hasFeatureFlag, isLoading, featureFlags } = useFeatureFlags();

  if (isLoading || featureFlags['EXEC_DASHBOARD'] === undefined) {
    return <LayoutHeader links={[{ label: 'Loading...', to: '#' }]} />;
  }

  const showExecDashboard = hasFeatureFlag('EXEC_DASHBOARD');
  return (
    <LayoutHeader
      links={[
        {
          label: showExecDashboard ? 'Dashboard' : 'Home',
          to: '/dashboard',
        },
      ]}
    />
  );
};

const IssuesHeader = () => {
  const { hasFeatureFlag, isLoading, featureFlags } = useFeatureFlags();

  if (isLoading || featureFlags['EXEC_DASHBOARD'] === undefined) {
    return <LayoutHeader links={[{ label: 'Loading...', to: '#' }]} />;
  }

  const showExecDashboard = hasFeatureFlag('EXEC_DASHBOARD');
  return (
    <LayoutHeader
      links={[
        {
          label: showExecDashboard ? 'Issues' : 'Home',
          to: '/issues',
        },
      ]}
      actions={[
        {
          component: <AddIssueButton />,
        },
      ]}
    />
  );
};

const DashboardRoute = () => {
  const { hasFeatureFlag, isLoading, featureFlags } = useFeatureFlags();

  if (isLoading || featureFlags['EXEC_DASHBOARD'] === undefined) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-muted-foreground" />
      </div>
    );
  }

  const showExecDashboard = hasFeatureFlag('EXEC_DASHBOARD');

  if (!showExecDashboard) {
    return <Navigate to="/issues" replace />;
  }

  return (
    <>
      <DashboardHeader />
      <Outlet />
    </>
  );
};

export const router = createBrowserRouter([
  {
    element: <AnonymousLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        path: '/register',
        Component: Register,
      },
      {
        path: '/login',
        Component: Login,
      },
      {
        path: '/forgot-password',
        Component: ForgotPassword,
      },
      {
        path: '/logout',
        Component: Logout,
      },
      {
        path: '/password-reset',
        Component: ResetPassword,
      },
      {
        path: '/change-password',
        Component: ChangePassword,
      },
      {
        path: '/email-verification',
        Component: EmailVerification,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <LoggedInLayout />
      </ProtectedRoute>
    ),
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        path: '/',
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <HomeRedirect />,
          },
        ],
      },
      {
        path: '/issues',
        element: (
          <>
            <IssuesHeader />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/issues',
            Component: Issues,
          },
          {
            path: '/issues/new',
            Component: NewIssuePage,
          },
          {
            path: '/issues/:id',
            Component: IssueDetails,
          },
        ],
      },
      {
        path: '/assets',
        element: (
          <>
            <LayoutHeader
              links={[
                { label: 'HTTP Services', to: '/assets', prefetch: prefetchAssetsList },
                { label: 'All Services', to: '/assets/services' },
                { label: 'Domains', to: '/assets/domains' },
              ]}
              actions={[{ component: <AddAssetDialog /> }]}
            />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/assets',
            Component: Assets,
            index: true,
          },
          {
            path: 'services',
            Component: Services,
          },
          {
            path: ':id',
            Component: AssetDetails,
          },
          {
            path: 'domains',
            Component: Domains,
          },
        ],
      },
      {
        path: '/notes',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Notes', to: '/notes' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/notes',
            Component: Notes,
          },
        ],
      },
      {
        path: '/http_analyzer',
        Component: HTTPAnalizer,
      },
      {
        path: '/investigation',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Investigation', to: '/investigation' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '',
            Component: Passive,
            index: true,
          },
          {
            path: ':id',
            Component: PassiveDetails,
          },
        ],
      },
      {
        element: (
          <>
            <LayoutHeader links={[]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/ipaddress/:id',
            Component: IPAdressDetailsView,
          },
        ],
      },
      {
        path: '/settings',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Settings', to: '/settings' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/settings',
            Component: Settings,
          },
        ],
      },
      {
        element: (
          <>
            <LayoutHeader links={[]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/vulnerabilities/:id',
            Component: VulnerabilityDetails,
          },
        ],
      },
      {
        path: '/dashboard',
        element: <DashboardRoute />,
        children: [
          {
            path: '/dashboard',
            Component: ExecDashboard,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage code={404} />,
  },
]);
