import { useState } from 'react';
import { Badge } from '@/components/ui/badge';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Issue, ISSUE_STATUSES } from '@/types/api/response/issues';
import { Pencil } from 'lucide-react';
import cn from '@/Helpers/cn';

interface StatusSelectorProps {
  currentStatus: Issue['status'];
  onStatusChange: (status: Issue['status']) => void;
  triggerClassName?: string;
}

export const StatusSelector = ({
  currentStatus,
  onStatusChange,
  triggerClassName = 'text-left',
}: StatusSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const statusBadge = (status: Issue['status']) => (
    <Badge
      className={cn(
        'shadow-none rounded-full',
        ISSUE_STATUSES[status as keyof typeof ISSUE_STATUSES],
      )}
    >
      <span className="tracking-wide">{status?.replace('_', ' ').toUpperCase() ?? ''}</span>
    </Badge>
  );

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger className={triggerClassName} onClick={() => setIsOpen(!isOpen)}>
        <div className="group flex items-center gap-2">
          {statusBadge(currentStatus)}
          <Pencil className="h-4 w-4 text-muted-foreground group-hover:opacity-100 opacity-0 transition-opacity duration-200" />
        </div>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-40 p-0 bg-gray-50 border border-gray-300">
        <div className="flex flex-col max-h-[200px] overflow-y-auto">
          {Object.keys(ISSUE_STATUSES).map((status) => (
            <button
              key={status}
              className={`p-2 text-left hover:bg-gray-200 ${
                status === currentStatus ? 'bg-gray-100' : ''
              }`}
              onClick={() => {
                onStatusChange(status as Issue['status']);
                setIsOpen(false);
              }}
            >
              {statusBadge(status as Issue['status'])}
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
