import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { ResourceVulnerabilityObject } from '@/types/api/response/investigations';
import { Issue, ISSUE_STATUSES, ISSUE_STATUSES_NAMES } from '@/types/api/response/issues';
import { NVDCVEWithRelations } from '@/types/api/response/nvd';
import { formatShortDateTime, getCVSSScoreColor } from '@/v2/utils';
import { Bug, Globe, Server } from 'lucide-react';
import { DateTime } from 'luxon';
import { Link, useNavigate } from 'react-router-dom';
import { ReferencesCard } from '../GenAITabs/AIReferences';
import { getSeverityDisplay } from '../SeverityBadge/SeverityBadge';
import { AIAnalysisSection } from './AIAnalysisSection';
import { AIRemediationSection } from './AIRemediationSection';
import { AIValidationSection } from './AIValidationSection';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { LinkWithAction } from '../LinkWithAction/LinkWithAction';
import { Ellipsis } from '@/components/common/Ellipsis';

export const VulnerabilityTabsSkeleton = () => (
  <div className="p-4 space-y-4">
    <Skeleton className="h-8 w-64" />
    <Skeleton className="h-48 w-full" />
    <div className="grid grid-cols-2 gap-4">
      <Skeleton className="h-48 w-full" />
      <Skeleton className="h-48 w-full" />
    </div>
    <Skeleton className="h-48 w-full" />
  </div>
);

interface BaseVulnerabilityOverviewProps {
  description?: string;
  severity?: string;
  aiKEV?: boolean;
  hostname?: string;
  port?: string | number;
  scannedAt?: string;
  dateAdded?: string;
  resourceObjectId?: string | number;
  references?: any[];
  children?: React.ReactNode;
  className?: string;
}

const BaseVulnerabilityOverview = ({
  description,
  severity,
  aiKEV,
  hostname,
  port,
  scannedAt,
  dateAdded,
  resourceObjectId,
  children,
  className,
  references,
}: BaseVulnerabilityOverviewProps) => {
  const { navigateWithParams } = useNavigateWithParams();

  return (
    <Card className={className}>
      <CardHeader>
        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-muted-foreground font-medium mb-1">Description</h3>
            <MarkdownViewer
              markdown={description || 'No description available'}
              className="-mt-4"
            />
          </div>
          {children}
          <div className="grid grid-cols-2 2xl:grid-cols-4 gap-y-4">
            {severity && (
              <div>
                <h3 className="text-muted-foreground font-medium mb-2">Severity</h3>
                <div className="flex items-center gap-2">
                  {getSeverityDisplay({
                    severity: severity,
                    showLabel: true,
                    showIcon: true,
                  })}
                </div>
              </div>
            )}
            <div>
              <h3 className="text-muted-foreground font-medium mb-1">
                Known Exploited Vulnerability{' '}
              </h3>
              {aiKEV ? (
                <Badge variant="outline" className="bg-green-50">
                  Yes
                </Badge>
              ) : (
                <Badge variant="outline" className="bg-gray-50">
                  No
                </Badge>
              )}
            </div>
            {resourceObjectId && hostname && (
              <div>
                <h3 className="font-medium text-muted-foreground mb-1">Affected Resource</h3>
                <LinkWithAction
                  to={navigateWithParams(
                    'assets',
                    resourceObjectId?.toString() || '',
                    `investigation/${resourceObjectId.toString()}`,
                  )}
                  dataClickBypass={true}
                  title="View Asset details"
                  showAsButton={true}
                  buttonIcon={<Globe className="h-4 w-4" />}
                  actions={[]}
                >
                  <Ellipsis text={hostname} maxLength={30} />
                </LinkWithAction>
              </div>
            )}
            {port && (
              <div>
                <h3 className="font-medium text-muted-foreground mb-1">Port</h3>
                <div className="flex items-center gap-2">{port}</div>
              </div>
            )}
            {scannedAt && (
              <div>
                <h3 className="font-medium text-muted-foreground mb-1">Last Seen</h3>
                <div className="flex items-center gap-2">{formatShortDateTime(scannedAt)}</div>
              </div>
            )}
            {dateAdded && (
              <div>
                <h3 className="font-medium text-muted-foreground mb-1">Discovered</h3>
                <div className="flex items-center gap-2">{formatShortDateTime(dateAdded)}</div>
              </div>
            )}
          </div>
          {references && references.length > 0 && (
            <ReferencesCard references={references} tab="overview" />
          )}
        </div>
      </CardHeader>
    </Card>
  );
};

export const CVEOverview = ({
  data,
  filteredAssets,
  assetFilter,
  setAssetFilter,
}: {
  data: NVDCVEWithRelations;
  filteredAssets: NVDCVEWithRelations['http_assets'];
  assetFilter: 'all' | 'solved' | 'unsolved';
  setAssetFilter: (filter: 'all' | 'solved' | 'unsolved') => void;
}) => {
  const { navigateWithParams } = useNavigateWithParams();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4 p-3">
      <BaseVulnerabilityOverview
        description={data?.ai_analysis.description}
        aiKEV={data?.ai_KEV}
        references={[]}
      >
        <div className="grid grid-cols-3 md:grid-cols-4 gap-4 mb-4">
          <div>
            <h3 className="font-medium mb-1 text-muted-foreground">Vendor</h3>
            <span className="text-gray-700">{data?.cpe_uris.vendor}</span>
          </div>
          <div>
            <h3 className="font-medium mb-1 text-muted-foreground">Product</h3>
            <span className="text-gray-700">{data?.cpe_uris.product}</span>
          </div>
          <div>
            <h3 className="font-medium text-muted-foreground">Risk Score</h3>
            <span
              style={{ color: getCVSSScoreColor(data?.cvss3_base_score ?? '-') }}
              className="text-xl font-bold"
            >
              {data?.cvss3_base_score ?? 'N/A'}
            </span>
          </div>
          <div>
            <h3 className="font-medium text-muted-foreground">CVSS3 Exploitability Score</h3>
            <span
              style={{ color: getCVSSScoreColor(data?.cvss3_exploitability_score ?? '-') }}
              className="text-xl font-bold"
            >
              {data?.cvss3_exploitability_score ?? 'N/A'}
            </span>
          </div>
        </div>
      </BaseVulnerabilityOverview>
      <div className="grid grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>
              <div className="flex items-center gap-2">
                <Server className="h-5 w-5 text-red-500" />
                Affected Assets
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <button
                  onClick={() => setAssetFilter('all')}
                  className={`px-3 py-1 rounded-full text-xs font-medium transition-colors ${
                    assetFilter === 'all'
                      ? 'bg-primary text-primary-foreground'
                      : 'bg-secondary hover:bg-secondary/80'
                  }`}
                >
                  All
                </button>
                <button
                  onClick={() => setAssetFilter('solved')}
                  className={`px-3 py-1 rounded-full text-xs font-medium transition-colors ${
                    assetFilter === 'solved'
                      ? 'bg-primary text-primary-foreground'
                      : 'bg-secondary hover:bg-secondary/80'
                  }`}
                >
                  Solved
                </button>
                <button
                  onClick={() => setAssetFilter('unsolved')}
                  className={`px-3 py-1 rounded-full text-xs font-medium transition-colors ${
                    assetFilter === 'unsolved'
                      ? 'bg-primary text-primary-foreground'
                      : 'bg-secondary hover:bg-secondary/80'
                  }`}
                >
                  Unsolved
                </button>
              </div>
              <ScrollArea className="max-h-[200px] overflow-y-auto">
                <div className="flex flex-col gap-2 p-1">
                  {filteredAssets?.map((asset) => (
                    <div
                      key={asset.id}
                      className="flex items-center justify-between p-2.5 bg-white border border-gray-200 hover:border-gray-300 hover:bg-accent/5 rounded-md transition-colors"
                    >
                      <LinkWithAction
                        to={`/assets/${asset.id}?from=vulnerabilities/${data.cve_id}`}
                        dataClickBypass={true}
                        title="View Asset details"
                        showAsButton={true}
                        buttonIcon={<Globe className="h-4 w-4" />}
                        actions={[]}
                      >
                        <Ellipsis text={asset.hostname} maxLength={40} />
                      </LinkWithAction>
                      {!asset.vulnerability_status.detected_last_scan && (
                        <Badge
                          variant="outline"
                          className="bg-green-50 border-green-300 text-green-700"
                        >
                          Patched
                        </Badge>
                      )}
                    </div>
                  ))}
                  {filteredAssets?.length === 0 && (
                    <div className="text-center text-gray-500 py-4">
                      No {assetFilter === 'all' ? '' : assetFilter} assets found
                    </div>
                  )}
                </div>
              </ScrollArea>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>
              <div className="flex items-center gap-2">
                <Bug className="h-5 w-5 text-red-500" />
                Issues
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4 overflow-y-auto max-h-[300px]">
              {data?.issues?.length === 0 ? (
                <div className="text-center text-gray-500 py-4">No issues found</div>
              ) : (
                data?.issues?.map((issue, index) => (
                  <div
                    key={index}
                    className="p-4 bg-card border rounded-lg hover:border-border/80 transition-colors cursor-pointer hover:bg-gray-100"
                  >
                    <div
                      className="flex flex-col sm:flex-row sm:items-start justify-between gap-4"
                      onClick={() => {
                        navigate(
                          navigateWithParams(
                            `issues`,
                            issue.issue_number.toString(),
                            `vulnerabilities/${data.cve_id}`,
                          ),
                        );
                      }}
                    >
                      <div className="flex-1 min-w-0">
                        <div className="flex flex-wrap items-start gap-2 mb-2">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Link
                                  to={`/issues/${issue.issue_number}`}
                                  target="_self"
                                  className="font-semibold hover:underline cursor-pointer line-clamp-2"
                                >
                                  {issue.title}
                                </Link>
                              </TooltipTrigger>
                              <TooltipContent side="top" className="max-w-[400px]">
                                <p className="break-words">{issue.title}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                        <div className="shrink-0">
                          {getSeverityDisplay({
                            severity: issue.severity as Issue['severity'],
                            showLabel: true,
                            showIcon: true,
                          })}
                        </div>
                      </div>
                      <div className="flex flex-row sm:flex-col items-center sm:items-end gap-2 shrink-0">
                        <div className="flex flex-wrap items-center gap-2">
                          <Badge
                            className={ISSUE_STATUSES[issue.status as keyof typeof ISSUE_STATUSES]}
                          >
                            {
                              ISSUE_STATUSES_NAMES[
                                issue.status as keyof typeof ISSUE_STATUSES_NAMES
                              ]
                            }
                          </Badge>
                          {issue.is_resolved && (
                            <Badge variant="outline" className="bg-green-50">
                              Resolved
                            </Badge>
                          )}
                        </div>
                        <time className="text-sm text-muted-foreground whitespace-nowrap">
                          {DateTime.fromISO(issue.created_at).toRelative()}
                        </time>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      {data.references && data.references.length > 0 && (
        <ReferencesCard references={data.references} tab="overview" />
      )}
    </div>
  );
};

export const CVEAnalysis = ({ data }: { data: NVDCVEWithRelations }) => {
  return (
    <AIValidationSection
      brief={data.ai_analysis?.brief}
      steps={data.ai_validation?.steps?.steps}
      validationReferences={data.ai_validation_references}
      code={data.ai_validation?.code}
      aiThreatActorActivity={data.ai_threat_actor_activity}
      aiThreatActorActivityReferences={data.ai_threat_actor_activity_references}
      aiKEV={data.ai_KEV}
    />
  );
};

export const CVERemediation = ({ data }: { data: NVDCVEWithRelations }) => {
  return (
    <div className="flex flex-col gap-4">
      <AIRemediationSection
        brief={data.ai_remediation?.brief}
        steps={data.ai_remediation?.steps}
        remediationReferences={data.ai_remediation_references}
      />
      {data.ai_mitigation && (
        <AIRemediationSection
          brief={data.ai_mitigation?.brief}
          steps={data.ai_mitigation?.steps}
          remediationReferences={data.ai_mitigation_references}
          mitigation={true}
        />
      )}
    </div>
  );
};

export const DynamicOverview = (vulnerability: ResourceVulnerabilityObject) => {
  return (
    <>
      <BaseVulnerabilityOverview
        description={vulnerability?.ai_analysis?.description}
        severity={vulnerability.severity}
        aiKEV={vulnerability.ai_KEV}
        hostname={vulnerability.hostname}
        port={vulnerability.port}
        scannedAt={vulnerability.scanned_at}
        dateAdded={vulnerability.date_added}
        resourceObjectId={vulnerability.resource_object_id}
        references={vulnerability.references}
        className="h-full w-full overflow-y-auto"
      >
        <AIAnalysisSection
          impact={vulnerability?.ai_analysis?.impact}
          evidence={vulnerability?.ai_analysis?.evidence}
        />
      </BaseVulnerabilityOverview>
    </>
  );
};

export const DynamicAnalysis = (vulnerability: ResourceVulnerabilityObject) => (
  <AIValidationSection
    brief={vulnerability.ai_analysis?.brief}
    steps={vulnerability.ai_validation?.steps}
    validationReferences={vulnerability.ai_validation_references}
    curlCommand={vulnerability?.result?.[0]?.curl_command}
    response={vulnerability?.result?.[0]?.response}
    code={vulnerability?.ai_validation?.code}
    aiThreatActorActivity={vulnerability?.ai_threat_actor_activity}
    aiThreatActorActivityReferences={vulnerability?.ai_threat_actor_activity_references}
    aiKEV={vulnerability?.ai_KEV}
  />
);

export const DynamicRemediation = (vulnerability: ResourceVulnerabilityObject) => (
  <div className="flex flex-col gap-4">
    <AIRemediationSection
      brief={vulnerability.ai_remediation?.brief}
      steps={vulnerability.ai_remediation?.steps}
      remediationReferences={vulnerability.ai_remediation_references}
    />
    {vulnerability.ai_mitigation && (
      <AIRemediationSection
        brief={vulnerability.ai_mitigation?.brief}
        steps={vulnerability.ai_mitigation?.steps}
        remediationReferences={vulnerability.ai_mitigation_references}
        mitigation={true}
      />
    )}
  </div>
);

export const PassiveOverview = (vulnerability: ResourceVulnerabilityObject) => {
  return (
    <>
      <BaseVulnerabilityOverview
        description={vulnerability?.ai_analysis?.description}
        severity={vulnerability.severity}
        aiKEV={vulnerability.ai_KEV}
        hostname={vulnerability.hostname}
        port={vulnerability.port}
        scannedAt={vulnerability.scanned_at}
        dateAdded={vulnerability.date_added}
        resourceObjectId={vulnerability.resource_object_id}
        references={vulnerability.references}
      />
    </>
  );
};

export const PassiveAnalysis = (vulnerability: ResourceVulnerabilityObject) => (
  <AIValidationSection
    brief={vulnerability.ai_analysis?.brief}
    description={vulnerability.ai_analysis?.description}
    steps={vulnerability.ai_validation?.steps?.steps}
    validationReferences={vulnerability.ai_validation_references}
    code={vulnerability?.ai_validation?.code}
    aiThreatActorActivity={vulnerability?.ai_threat_actor_activity}
    aiThreatActorActivityReferences={vulnerability?.ai_threat_actor_activity_references}
    aiKEV={vulnerability?.ai_KEV}
  />
);

export const PassiveRemediation = (vulnerability: ResourceVulnerabilityObject) => (
  <div className="flex flex-col gap-4">
    <AIRemediationSection
      brief={vulnerability.ai_remediation?.brief}
      steps={vulnerability.ai_remediation?.steps}
      remediationReferences={vulnerability.ai_remediation_references}
    />
    {vulnerability.ai_mitigation && (
      <AIRemediationSection
        brief={vulnerability.ai_mitigation?.brief}
        steps={vulnerability.ai_mitigation?.steps}
        remediationReferences={vulnerability.ai_mitigation_references}
        mitigation={true}
      />
    )}
  </div>
);
