import { useAuth } from '@/Helpers/AuthContext';
import { JiraIcon } from '@/components/icons/JiraIcon';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardTitle, CardHeader } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/hooks/use-toast';
import type { UserSettings as BaseUserSettings } from '@/types/api/response/user';
import { useUpdateUserSettings } from '@/v2/queries/users';
import { getTimezones } from '@/v2/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Bell, ChevronDown, Globe, KeyRound, Link, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

// Extend the UserSettings type to include integrations
interface UserSettings extends BaseUserSettings {
  integrations?: {
    jira: {
      is_enabled: boolean;
      api_token?: string;
      organization_name?: string;
      project_key?: string;
      default_issue_type?: string;
    };
  };
}

const eventPreferenceSchema = z.object({
  channels: z.array(z.string()),
  daily_report: z.boolean(),
  weekly_report: z.boolean(),
});

// Add schema for integrations
const jiraIntegrationSchema = z.object({
  is_enabled: z.boolean().default(false),
  api_token: z.string().optional(),
  organization_name: z.string().optional(),
  project_key: z.string().optional(),
  default_issue_type: z.string().optional(),
});

const userSettingsSchema = z.object({
  timezone: z.string(),
  notifications: z.object({
    channels: z.array(z.string()),
    email: z.string(),
    max_notifications_per_day: z.number(),
    event_preferences: z.object({
      new_issue: eventPreferenceSchema,
      new_http_asset: eventPreferenceSchema,
      new_vulnerability: eventPreferenceSchema,
    }),
  }),
  jira_integration: jiraIntegrationSchema,
});

type UserSettingsFormData = z.infer<typeof userSettingsSchema>;

const DEFAULT_EVENT_PREFERENCES = {
  channels: [],
  daily_report: false,
  weekly_report: false,
};

const DEFAULT_INTEGRATIONS = {
  is_enabled: false,
  api_token: '',
  organization_name: '',
  project_key: '',
  default_issue_type: 'Bug',
};

export default function UserSettings() {
  const { userData } = useAuth();
  const { mutate: updateUserSettings, isPending: isUpdating } = useUpdateUserSettings();

  const form = useForm<UserSettingsFormData>({
    resolver: zodResolver(userSettingsSchema),
    defaultValues: {
      timezone: userData?.settings?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      notifications: {
        channels: userData?.settings?.notifications?.channels || [],
        email: userData?.settings?.notifications?.email || userData?.email,
        max_notifications_per_day:
          userData?.settings?.notifications?.max_notifications_per_day || 100,
        event_preferences: {
          new_issue:
            userData?.settings?.notifications?.event_preferences?.new_issue ||
            DEFAULT_EVENT_PREFERENCES,
          new_http_asset:
            userData?.settings?.notifications?.event_preferences?.new_http_asset ||
            DEFAULT_EVENT_PREFERENCES,
          new_vulnerability:
            userData?.settings?.notifications?.event_preferences?.new_vulnerability ||
            DEFAULT_EVENT_PREFERENCES,
        },
      },
      jira_integration:
        (userData?.settings as UserSettings)?.jira_integration || DEFAULT_INTEGRATIONS,
    },
  });

  const handleSubmit = async (data: UserSettingsFormData) => {
    try {
      updateUserSettings(data as unknown as BaseUserSettings);
      toast({
        title: 'Settings updated',
        description: 'Your settings have been successfully saved.',
        className: 'bg-green-50 border-green-200 text-green-700',
        duration: 3000,
      });
    } catch (error) {
      console.error('Failed to save settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to save settings. Please try again.',
        className: 'bg-red-50 border-red-200 text-red-700',
        duration: 3000,
      });
    }
  };

  const testJiraConnection = async () => {
    const jiraSettings = form.getValues('jira_integration');

    if (!jiraSettings.api_token || !jiraSettings.organization_name || !jiraSettings.project_key) {
      toast({
        title: 'Missing information',
        description: 'Please fill in all required Jira fields before testing the connection.',
        variant: 'destructive',
        duration: 3000,
      });
      return;
    }

    // This would be replaced with an actual API call in production
    toast({
      title: 'Testing connection...',
      description: 'Checking connection to Jira...',
      duration: 2000,
    });

    // Mock API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    toast({
      title: 'Connection successful',
      description: 'Successfully connected to Jira.',
      className: 'bg-green-50 border-green-200 text-green-700',
      duration: 3000,
    });
  };

  const notificationsEnabled = form.watch('notifications.channels').length > 0;
  const jiraEnabled = form.watch('jira_integration.is_enabled');

  return (
    <div className="container max-w-4xl px-6">
      <h1 className="text-2xl font-bold mb-4">User Settings</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Accordion type="single" defaultValue="item-2" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>
                <div className="flex items-center gap-2">
                  <Globe className="h-5 w-5 text-primary" />
                  Regional Preferences
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <FormField
                  control={form.control}
                  name="timezone"
                  render={({ field }) => (
                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <span>Regional Preferences</span>
                        </CardTitle>
                        <CardDescription>
                          Configure your timezone and regional preferences
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <FormItem>
                          <FormLabel>Timezone</FormLabel>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                              form.handleSubmit(handleSubmit)();
                            }}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select your timezone" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {getTimezones().map((tz) => (
                                <SelectItem key={tz.value} value={tz.value}>
                                  {tz.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      </CardContent>
                    </Card>
                  )}
                />
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>
                <div className="flex items-center gap-2">
                  <Bell className="h-5 w-5 text-primary" />
                  Notifications Preferences
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <Card className="col-span-2">
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <div className="flex items-center gap-2">Notifications Preferences</div>
                    </CardTitle>
                    <CardDescription>Configure how you receive notifications</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <FormField
                      control={form.control}
                      name="notifications.channels"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">Notifications</FormLabel>
                            <FormDescription>Receive notifications</FormDescription>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value?.length > 0}
                              onCheckedChange={(checked) => {
                                const newValue = checked ? ['email'] : [];
                                field.onChange(newValue);
                                if (!checked) {
                                  // Reset all event preferences when notifications are disabled
                                  form.setValue(
                                    'notifications.event_preferences.new_issue',
                                    DEFAULT_EVENT_PREFERENCES,
                                  );
                                  form.setValue(
                                    'notifications.event_preferences.new_http_asset',
                                    DEFAULT_EVENT_PREFERENCES,
                                  );
                                  form.setValue(
                                    'notifications.event_preferences.new_vulnerability',
                                    DEFAULT_EVENT_PREFERENCES,
                                  );
                                }
                                form.handleSubmit(handleSubmit)();
                              }}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <div className="grid grid-cols-2 gap-4 mt-4 w-full">
                      {notificationsEnabled && (
                        <>
                          <NotificationSection
                            form={form}
                            title="New Issues"
                            description="Notifications for new issues"
                            basePath="notifications.event_preferences.new_issue"
                            onSubmit={handleSubmit}
                          />

                          <NotificationSection
                            form={form}
                            title="New HTTP Assets"
                            description="Notifications for new HTTP assets"
                            basePath="notifications.event_preferences.new_http_asset"
                            onSubmit={handleSubmit}
                          />

                          <NotificationSection
                            form={form}
                            title="New Vulnerabilities"
                            description="Notifications for new vulnerabilities"
                            basePath="notifications.event_preferences.new_vulnerability"
                            onSubmit={handleSubmit}
                          />
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </AccordionContent>
            </AccordionItem>
            {/* TODO: Add Jira integration back in */}
            {false && (
              <AccordionItem value="item-3">
                <AccordionTrigger>
                  <div className="flex items-center gap-2">
                    <JiraIcon className="h-4 w-4" />
                    Jira Integration
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <Card className="col-span-2">
                    <CardHeader>
                      <CardTitle className="flex items-center gap-2">Jira Integration</CardTitle>
                      <CardDescription>
                        Connect with Jira to send issues directly to your Jira projects
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-6">
                      <FormField
                        control={form.control}
                        name="jira_integration.is_enabled"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                            <div className="space-y-0.5">
                              <FormLabel className="text-base">Enable Jira Integration</FormLabel>
                              <FormDescription>
                                Allow sending issues from Specular to Jira
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch checked={field.value} onCheckedChange={field.onChange} />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {jiraEnabled && (
                        <>
                          <div className="grid grid-cols-2 gap-4 pl-2">
                            <FormField
                              control={form.control}
                              name="jira_integration.api_token"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>
                                    Jira API Token <span className="text-red-500">*</span>
                                  </FormLabel>
                                  <FormControl>
                                    <div className="flex gap-2 items-center">
                                      <KeyRound className="h-4 w-4 text-muted-foreground" />
                                      <Input
                                        type="password"
                                        placeholder="Enter your Jira API token"
                                        {...field}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormDescription>
                                    Generate an API token from your Jira account settings
                                  </FormDescription>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />

                            <FormField
                              control={form.control}
                              name="jira_integration.organization_name"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>
                                    Organization <span className="text-red-500">*</span>
                                  </FormLabel>
                                  <FormControl>
                                    <div className="flex gap-2 items-center">
                                      <Link className="h-4 w-4 text-muted-foreground" />
                                      <Input placeholder="your-organization" {...field} />
                                    </div>
                                  </FormControl>
                                  <FormDescription>
                                    Your Jira organization name (e.g., for
                                    https://acme.atlassian.net, enter &quot;acme&quot;)
                                  </FormDescription>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />

                            <FormField
                              control={form.control}
                              name="jira_integration.project_key"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>
                                    Project Key <span className="text-red-500">*</span>
                                  </FormLabel>
                                  <FormControl>
                                    <Input placeholder="SEC" {...field} />
                                  </FormControl>
                                  <FormDescription>
                                    The key of the Jira project to create issues in (e.g.,
                                    &quot;SEC&quot; for Security)
                                  </FormDescription>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />

                            <FormField
                              control={form.control}
                              name="jira_integration.default_issue_type"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Default Issue Type</FormLabel>
                                  <Select
                                    onValueChange={field.onChange}
                                    value={field.value}
                                    defaultValue="Bug"
                                  >
                                    <FormControl>
                                      <SelectTrigger>
                                        <SelectValue placeholder="Select issue type" />
                                      </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                      <SelectItem value="Bug">Bug</SelectItem>
                                      <SelectItem value="Task">Task</SelectItem>
                                      <SelectItem value="Story">Story</SelectItem>
                                      <SelectItem value="Epic">Epic</SelectItem>
                                    </SelectContent>
                                  </Select>
                                  <FormDescription>
                                    The default issue type to use when creating Jira issues
                                  </FormDescription>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex justify-end gap-2 pt-4">
                            <Button
                              type="button"
                              variant="outline"
                              onClick={testJiraConnection}
                              className="flex items-center gap-2"
                            >
                              Test Connection
                            </Button>
                            <Button type="submit" disabled={isUpdating}>
                              {isUpdating ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  Saving...
                                </>
                              ) : (
                                'Save Settings'
                              )}
                            </Button>
                          </div>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </AccordionContent>
              </AccordionItem>
            )}
          </Accordion>
        </form>
      </Form>
    </div>
  );
}

type NotificationSectionProps = {
  form: UseFormReturn<UserSettingsFormData>;
  title: string;
  description: string;
  basePath:
    | 'notifications.event_preferences.new_issue'
    | 'notifications.event_preferences.new_http_asset'
    | 'notifications.event_preferences.new_vulnerability';
  onSubmit: (data: UserSettingsFormData) => Promise<void>;
};

const NotificationSection = ({
  form,
  title,
  description,
  basePath,
  onSubmit,
}: NotificationSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const channels = form.watch(`${basePath}.channels` as const);
  const dailyReport = form.watch(`${basePath}.daily_report` as const);
  const weeklyReport = form.watch(`${basePath}.weekly_report` as const);

  const activeChannels = [
    ...(channels || []),
    ...(dailyReport ? ['daily digest'] : []),
    ...(weeklyReport ? ['weekly digest'] : []),
  ];

  const handleSave = () => {
    setIsOpen(false);
    form.handleSubmit(onSubmit)();
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription className="text-xs">{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" type="button" className="p-4 text-xs">
              Notify:{' '}
              {activeChannels.length ? (
                activeChannels.map((channel, index) => (
                  <Badge key={index}>{channel.charAt(0).toUpperCase() + channel.slice(1)}</Badge>
                ))
              ) : (
                <Badge variant="secondary">None</Badge>
              )}
              <ChevronDown className="h-4 w-4 text-muted-foreground" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-56 p-4" align="start">
            <div className="space-y-4">
              <FormField
                control={form.control}
                name={`${basePath}.channels` as const}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        id="email"
                        checked={field.value?.includes('email')}
                        onCheckedChange={(checked) => {
                          const newChannels = checked
                            ? [...(field.value || []), 'email']
                            : (field.value || []).filter((c) => c !== 'email');
                          field.onChange(newChannels);

                          // If email is unchecked, reset daily and weekly reports
                          if (!checked) {
                            form.setValue(`${basePath}.daily_report` as const, false);
                            form.setValue(`${basePath}.weekly_report` as const, false);
                          }
                        }}
                      />
                    </FormControl>
                    <FormLabel
                      htmlFor="email"
                      className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 !m-0"
                    >
                      Email
                    </FormLabel>
                  </FormItem>
                )}
              />

              <div className="border-t my-2" />

              <FormField
                control={form.control}
                name={`${basePath}.daily_report` as const}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                        }}
                        disabled={!channels.includes('email')}
                      />
                    </FormControl>
                    <FormLabel className="text-sm font-normal !m-0">Daily digest</FormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`${basePath}.weekly_report` as const}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center gap-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                        }}
                        disabled={!channels.includes('email')}
                      />
                    </FormControl>
                    <FormLabel className="text-sm font-normal !m-0">Weekly digest</FormLabel>
                  </FormItem>
                )}
              />

              <div className="pt-2 flex justify-end space-x-2">
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" size="sm" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </CardContent>
    </Card>
  );
};
