import {
  getPortScanChangesApi,
  GetPortScanChangesParams,
  getResourceVulnerabilityObjectApi,
  getVulnerabilitiesApi,
  getVulnerabilitiesCountApi,
  getVulnerabilitiesNucleiASNOverviewApi,
  GetVulnerabilitiesParams,
  getVulnerabilitiesProductsAndVendorsListApi,
  getVulnerabilitiesScanResultsApi,
  GetVulnerabilitiesScanResultsParams,
  getVulnerabilityASNOverviewApi,
  getVulnerabilitySearchApi,
} from '@/api/investigations.api';
import { ASNOverview } from '@/types/api/response/common';
import { queryOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

interface StaleWhileRevalidateOptions<T>
  extends Omit<UseQueryOptions<T, Error>, 'queryKey' | 'queryFn'> {
  queryKey: unknown[];
  queryFn: () => Promise<T>;
  mergeData?: (existingData: T | undefined, newData: T) => T;
}

function useStaleWhileRevalidate<T>({
  queryKey,
  queryFn,
  mergeData,
  ...options
}: StaleWhileRevalidateOptions<T>) {
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData<T>(queryKey);

  return useQuery({
    queryKey,
    queryFn,
    staleTime: 30 * 1000,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    initialData: cachedData,
    select: (newData: T) => {
      if (!mergeData) return newData;

      const existingData = queryClient.getQueryData<T>(queryKey);
      if (!existingData) return newData;

      return mergeData(existingData, newData);
    },
    ...options,
  });
}

export const useVulnerabilitiesQuery = (params: GetVulnerabilitiesParams) => {
  return useStaleWhileRevalidate({
    queryKey: ['vulnerabilities', params],
    queryFn: () => getVulnerabilitiesApi(params),
    mergeData: (existingData, newData) => ({
      ...newData,
      entries: [
        ...new Map(
          [...(existingData.entries || []), ...(newData.entries || [])].map((item) => [
            item.id,
            item,
          ]),
        ).values(),
      ],
    }),
  });
};

export const getVulnerabilitiesListQuery = (getVulnerabilitiesParams: GetVulnerabilitiesParams) =>
  queryOptions({
    queryKey: ['vulnerabilities', getVulnerabilitiesParams],
    queryFn: () => getVulnerabilitiesApi(getVulnerabilitiesParams),
  });

export const getVulnerabilitiesCountQuery = queryOptions({
  queryKey: ['vulnerabilities-count'],
  queryFn: () => getVulnerabilitiesCountApi(),
});

export const getVulnerabilityASNOverviewQuery = queryOptions({
  queryKey: ['vulnerabilities-asn-overview'],
  queryFn: () => getVulnerabilityASNOverviewApi(),
  select: (data: ASNOverview[]) =>
    data.map((item) => ({
      label: `${item.owner ? item.owner : 'Unknown'} (ASN: ${item.asn ? item.asn.toString() : ''}) - ${item.count} entries`,
      value: item.asn?.toString() || item.owner || 'Unknown',
    })),
});

export const getVulnerabilitiesScanResultsQuery = ({
  page,
  asn,
  http_asset,
  domainname,
  filter_type,
  severity,
}: GetVulnerabilitiesScanResultsParams) =>
  queryOptions({
    queryKey: [
      'vulnerabilities-scan-results',
      page,
      asn,
      http_asset,
      domainname,
      filter_type,
      severity,
    ],
    queryFn: () =>
      getVulnerabilitiesScanResultsApi({
        page,
        asn,
        http_asset,
        domainname,
        filter_type,
        severity,
      }),
  });

export const getVulnerabilitiesNucleiASNOverviewQuery = () =>
  queryOptions({
    queryKey: ['vulnerabilities-nuclei-asn-overview'],
    queryFn: () => getVulnerabilitiesNucleiASNOverviewApi(),
  });

export const getPortScanChangesQuery = ({
  page,
  state,
  from_date,
  to_date,
  ipaddress,
  ports,
  services,
  versions,
}: GetPortScanChangesParams) =>
  queryOptions({
    queryKey: [
      'port-scan-changes',
      page,
      state,
      from_date,
      to_date,
      ipaddress,
      ports,
      services,
      versions,
    ],
    queryFn: () =>
      getPortScanChangesApi({
        page,
        state,
        from_date,
        to_date,
        ipaddress,
        ports,
        services,
        versions,
      }),
  });

export const useGetVulnerabilitiesProductsAndVendorsList = () =>
  useQuery({
    queryKey: ['vulnerabilities-products-and-vendors-list'],
    queryFn: () => getVulnerabilitiesProductsAndVendorsListApi(),
    select: (data) => {
      return {
        products: data.products.map((product) => ({ label: product, value: product })),
        vendors: data.vendors.map((vendor) => ({ label: vendor, value: vendor })),
      };
    },
  });

export const getVulnerabilityScanResultDetailsQuery = (id: string) =>
  queryOptions({
    queryKey: ['vulnerability-scan-result-details', id],
    queryFn: () => getResourceVulnerabilityObjectApi(id),
  });
export const usePrefetchVulnerabilityScanResultDetailsQuery = () => {
  const queryClient = useQueryClient();

  return async (id: string) => {
    await queryClient.prefetchQuery(getVulnerabilityScanResultDetailsQuery(id));
  };
};

export const getPassiveScanResultDetailsQuery = (id: string) =>
  queryOptions({
    queryKey: ['passive-scan-result-details', id],
    queryFn: () => getResourceVulnerabilityObjectApi(id),
  });

export const getVulnerabilitySearchQuery = (query: string) =>
  queryOptions({
    queryKey: ['vulnerability-search', query],
    queryFn: () => getVulnerabilitySearchApi(query),
  });

export const useVulnerabilitiesScanResultsQuery = (params: GetVulnerabilitiesScanResultsParams) => {
  const { page, asn, http_asset, domainname, filter_type, severity } = params;

  return useStaleWhileRevalidate({
    queryKey: [
      'vulnerabilities-scan-results',
      page,
      asn,
      http_asset,
      domainname,
      filter_type,
      severity,
    ],
    queryFn: () => getVulnerabilitiesScanResultsApi(params),
    mergeData: (existingData, newData) => ({
      ...newData,
      entries: [
        ...new Map(
          [...(existingData.entries || []), ...(newData.entries || [])].map((item) => [
            item.id,
            item,
          ]),
        ).values(),
      ],
    }),
  });
};
