import { createIssue, NewIssuePayload } from '@/api/issues.api';
import { BackButton } from '@/components/common/BackButton';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useToast } from '@/hooks/use-toast';
import { ImageMap, useAttachedImages } from '@/hooks/useAttachedImages';
import { useBestStorage } from '@/hooks/useBestStorage';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { ResourceSelector } from '@/pages/Issues/ResourceSelector';
import { ShortVulnerability, VulnerabilitySelector } from '@/pages/Issues/VulnerabilitySelector';
import { severityConfig } from '@/v2/components/SeverityBadge/SeverityBadge';
import { useUpdateIssuesCache } from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { UserX } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useBeforeUnload, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const newIssueSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  severity: z.enum(['informational', 'low', 'medium', 'high', 'critical']),
  affected_resource_model_type: z.string().nullable(),
  affected_resource_object_id: z.number().nullable(),
  vuln_model_type: z.string().nullable(),
  vuln_object_id: z.number().nullable(),
  assignedToId: z.string().optional(),
  status: z.enum([
    'draft',
    'new',
    'acknowledged',
    'in_progress',
    'mitigated',
    'resolved',
    'ignored',
  ]),
  _uploaded_screenshots: z.record(
    z.string(),
    z
      .object({
        filename: z.string().min(1),
        blob: z.instanceof(Blob),
      })
      .strict(),
  ) as z.ZodType<ImageMap>,
});

export default function NewIssuePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const { data: users } = useQuery(getUsersQuery());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedResource, setSelectedResource] = useState<any>(null);
  const [selectedVulnerability, setSelectedVulnerability] = useState<ShortVulnerability | null>(
    null,
  );
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const { handleBack } = useNavigateWithParams();
  const [wipIssue, , removeWipIssue] = useBestStorage(
    `resourcevulnerability:${searchParams.get('id')}:createissue`,
    null,
  );

  const form = useForm<NewIssuePayload>({
    resolver: zodResolver(newIssueSchema),
    defaultValues: {
      title: wipIssue?.vulnerability_name || '',
      description: wipIssue?.description || '',
      severity: wipIssue?.severity || 'informational',
      affected_resource_model_type: wipIssue?.resourceType || 'HTTPAsset',
      affected_resource_object_id: wipIssue?.resourceId || null,
      vuln_model_type: wipIssue?.vulnType || '',
      vuln_object_id: wipIssue?.vulnId || null,
      assignedToId: wipIssue?.assignedToId || '',
      status: wipIssue?.status || 'new',
      _uploaded_screenshots: {} as ImageMap,
    },
  });

  const [selectedResourceType, setSelectedResourceType] = useState<string | null>(
    form.getValues('affected_resource_model_type'),
  );

  useEffect(() => {
    if (wipIssue && form && form.reset && form.getValues) {
      // Add checks for form methods
      try {
        // Pre-populate resource if provided
        if (wipIssue.resourceType && wipIssue.resourceId && wipIssue.resourceData) {
          const resource = wipIssue.resourceData;
          handleResourceSelect(wipIssue.resourceType, resource);
        }

        // Pre-populate vulnerability if provided
        if (wipIssue.vulnId && wipIssue.vulnData) {
          const vulnerability = wipIssue.vulnData;
          const vulnerabilityData: ShortVulnerability = {
            id: vulnerability.vuln_object_id,
            name: vulnerability.vulnerability_name,
            type: vulnerability.vulnerability_type,
            references: vulnerability.vuln_object_references || [],
          };
          handleVulnerabilitySelect(vulnerabilityData);
        }

        // Use reset method to update all form values at once for better reliability
        form.reset({
          ...form.getValues(),
          title: wipIssue.vulnerability_name || '',
          description: wipIssue.description,
          severity: wipIssue.severity || 'informational',
          affected_resource_model_type: wipIssue.resourceType,
          vuln_model_type: wipIssue.vulnType,
          vuln_object_id: Number(wipIssue.vulnId) || null,
          resource_vulnerability_object_id:
            Number(wipIssue.resource_vulnerability_object_id) || null,
        });
      } catch (error) {
        console.error('Error setting form values from localStorage:', error);
      }
    }
  }, [wipIssue, form]); // Added missing dependencies

  const handleResourceSelect = (resourceType: string | null, resource: any) => {
    if (resourceType && !resource) {
      setSelectedResourceType(resourceType);
      form.setValue('affected_resource_model_type', resourceType);
      return;
    }

    setSelectedResourceType(resourceType);
    setSelectedResource(resource);
    form.setValue('affected_resource_model_type', resourceType);
    form.setValue('affected_resource_object_id', resource?.id);
  };

  const handleVulnerabilitySelect = (vulnerability: ShortVulnerability | null) => {
    setSelectedVulnerability(vulnerability);

    if (vulnerability) {
      // If we have a vulnerability, set all the necessary form values
      form.setValue('vuln_object_id', vulnerability.id);
      form.setValue('vuln_model_type', vulnerability.type || '');
    } else {
      // If clearing, reset form values to empty string instead of null
      form.setValue('vuln_object_id', null);
      form.setValue('vuln_model_type', '');
    }
  };

  const handleResourceClear = () => {
    setSelectedResource(null);
    setSelectedResourceType(null);
    form.setValue('affected_resource_model_type', null);
    form.setValue('affected_resource_object_id', null);
  };

  const { addImage } = useAttachedImages((imageMap) => {
    form.setValue('_uploaded_screenshots', imageMap);
  });

  const handleImageUpload = async (file: File) => {
    const url = await addImage(file);
    return url;
  };

  // Add beforeunload event listener
  useBeforeUnload(
    useCallback(
      (event) => {
        if (form.formState.isDirty) {
          event.preventDefault();
          return '';
        }
      },
      [form.formState.isDirty],
    ),
  );

  // Handle navigation attempts
  useEffect(() => {
    const handleBeforeNavigate = (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault();
        setShowExitPrompt(true);
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeNavigate);
    return () => window.removeEventListener('beforeunload', handleBeforeNavigate);
  }, [form.formState.isDirty]);

  const handleNavigationAttempt = (path: string) => {
    if (form.formState.isDirty) {
      setShowExitPrompt(true);
    } else {
      removeWipIssue();
      navigate(path);
    }
  };

  const updateIssuesCache = useUpdateIssuesCache();

  const handleSubmit = async (data: NewIssuePayload, isDraft: boolean = false) => {
    // Ensure vuln_model_type is never null
    if (data.vuln_model_type === null) {
      data.vuln_model_type = '';
    }

    try {
      setIsSubmitting(true);

      const payload = {
        ...data,
        is_draft: isDraft,
      };

      const createdIssue = await createIssue({
        ...payload,
        status: isDraft ? 'draft' : 'new',
        resource_vulnerability_object_id: data.resource_vulnerability_object_id,
      });

      if (!createdIssue.errors) {
        // Update cache with new issue
        updateIssuesCache(createdIssue);

        removeWipIssue();

        toast({
          title: isDraft ? 'Draft saved' : 'Issue created',
          description: (
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <span className="text-sm text-muted-foreground">{createdIssue.issue_number}</span>
                <span>—</span>
                <span className="text-sm text-muted-foreground">{createdIssue.title}</span>
              </div>
              <Button
                variant="link"
                className="p-0 h-auto font-normal text-left text-blue-500 hover:text-blue-600 hover:no-underline"
                onClick={() => navigate(`/issues/${createdIssue.issue_number}`)}
              >
                View issue
              </Button>
            </div>
          ),
          duration: 5000,
        });
        navigate('/issues');
      } else {
        console.log(createdIssue.errors);
        toast({
          title: 'Failed to create Issue',
          description: createdIssue.errors.join(', '),
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Failed to create issue:', error);
      toast({
        title: 'Error',
        description: 'Failed to create issue. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormInvalid = !form.getValues('title') || !form.getValues('description');

  const isDisabled = isSubmitting || isFormInvalid;

  return (
    <div className="px-6 py-2 max-w-[1600px] mx-auto">
      <Helmet>
        <title>{`Issues > New Issue`}</title>
      </Helmet>
      <div className="flex flex-col mb-4 justify-start items-start">
        <BackButton customHandler={() => handleNavigationAttempt('/issues')} />
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold">New Issue</h1>
        </div>
      </div>

      <div className="bg-background rounded-lg border p-6">
        <Form {...form}>
          <form className="space-y-8">
            <div className="flex flex-row items-center gap-4">
              <div className="w-[600px]">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="block text-sm font-medium">
                        Title<span className="text-red-500 ml-1">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter a clear, concise title"
                          className="text-foreground"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-[250px]">
                <FormField
                  control={form.control}
                  name="severity"
                  render={({ field }) => (
                    <FormItem className="mr-4">
                      <FormLabel className="block text-sm font-medium">
                        Severity<span className="text-red-500 ml-1">*</span>
                      </FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger
                            className={`w-full justify-between font-bold ${severityConfig[field.value].bgColor} ${severityConfig[field.value].textColor} ${severityConfig[field.value].borderColor}`}
                          >
                            <SelectValue
                              placeholder="Select severity"
                              className="w-full justify-between bg-blue-50 border-blue-100 text-blue-500 font-normal"
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="informational">INFO</SelectItem>
                          <SelectItem value="low">LOW</SelectItem>
                          <SelectItem value="medium">MEDIUM</SelectItem>
                          <SelectItem value="high">HIGH</SelectItem>
                          <SelectItem value="critical">CRITICAL</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="flex flex-row items-start gap-4 flex-wrap">
              <FormField
                control={form.control}
                name="affected_resource_model_type"
                render={() => (
                  <FormItem>
                    <FormLabel className="block text-sm font-medium">Affected Resource</FormLabel>
                    <ResourceSelector
                      placeholderText="Select type"
                      selectedResourceType={selectedResourceType}
                      selectedResource={selectedResource}
                      onResourceSelect={handleResourceSelect}
                      onClear={handleResourceClear}
                      isEditable={!searchParams.get('vulnData')}
                    />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="vuln_model_type"
                render={() => (
                  <FormItem>
                    <FormLabel className="block text-sm font-medium">Vulnerability</FormLabel>
                    <VulnerabilitySelector
                      placeholderText="Select vulnerability"
                      selectedVulnerability={selectedVulnerability}
                      onVulnerabilitySelect={handleVulnerabilitySelect}
                      target="_blank"
                      resourceVulnerability={true}
                      from="issues"
                      onClear={() => {
                        setSelectedVulnerability(null);
                        form.setValue('vuln_model_type', '');
                        form.setValue('vuln_object_id', null);
                      }}
                    />
                  </FormItem>
                )}
              />
              <div className="w-[250px]">
                <FormField
                  control={form.control}
                  name="assignedToId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="block text-sm font-medium">Assigned to</FormLabel>
                      <Select
                        onValueChange={(value) => {
                          if (value === 'clear') {
                            field.onChange(null);
                          } else {
                            field.onChange(value);
                          }
                        }}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger className="border rounded-md bg-background">
                            <SelectValue placeholder="Select user (optional)" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {field.value !== '' && (
                            <SelectItem value="clear" className="text-foreground cursor-pointer">
                              <div className="flex items-center gap-2 text-red-500 font-medium cursor-pointer">
                                <UserX className="h-4 w-4" />
                                <span>Unassign</span>
                              </div>
                            </SelectItem>
                          )}
                          {(users?.entries ?? []).map((user) => (
                            <SelectItem
                              key={user.id}
                              value={user.id.toString()}
                              className="text-foreground cursor-pointer"
                            >
                              {user.user.first_name} {user.user.last_name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-foreground flex items-center group">
                    Description <span className="text-destructive ml-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <MarkdownEditor
                      markdown={field.value}
                      placeholder="Provide detailed information about the issue"
                      onChange={field.onChange}
                      imageUploadHandler={handleImageUpload}
                      embedded
                      className="new-issue border rounded-lg"
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                disabled={isDisabled}
                onClick={() => form.handleSubmit((data) => handleSubmit(data, true))()}
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-gray-600" />
                    Saving...
                  </div>
                ) : (
                  'Create as Draft'
                )}
              </Button>
              <Button
                type="submit"
                disabled={isDisabled}
                onClick={() => form.handleSubmit((data) => handleSubmit(data, false))()}
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary-foreground border-t-primary-foreground" />
                    Creating...
                  </div>
                ) : (
                  'Create Issue'
                )}
              </Button>
            </div>
          </form>
        </Form>
      </div>

      <AlertDialog open={showExitPrompt} onOpenChange={setShowExitPrompt}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
            <AlertDialogDescription>
              You have unsaved changes. Would you like to save them as a draft before leaving?
            </AlertDialogDescription>
            {isFormInvalid && (
              <AlertDialogDescription>
                <span className="text-destructive">
                  Please complete required fields before saving.
                </span>
              </AlertDialogDescription>
            )}
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setShowExitPrompt(false);
              }}
              className="mr-auto"
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogCancel
              onClick={() => {
                setShowExitPrompt(false);
                if (searchParams.get('vulnData')) {
                  handleBack();
                } else {
                  navigate('/issues');
                }
              }}
              className="bg-destructive hover:bg-destructive/50 text-white hover:text-white"
            >
              Discard
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => form.handleSubmit((data) => handleSubmit(data, true))()}
              disabled={isDisabled}
              className="bg-v2-black text-white"
            >
              {isSubmitting ? (
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary-foreground border-t-primary-foreground" />
                  Saving...
                </div>
              ) : (
                'Save as Draft'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
