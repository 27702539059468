import { AssetDetailsWithDetails, AssetHistory } from '@/types/api/response/assets';
import { ImageWithModal } from '@/v2/components/ImageWithModal/ImageWithModal';
import { formatShortDateTime } from '@/v2/utils';
import { useEffect, useState } from 'react';

interface HistoryViewProps {
  rowData: AssetDetailsWithDetails;
  historyData: AssetHistory[];
  isFetching: boolean;
}

const HistoryView = ({ rowData, historyData, isFetching }: HistoryViewProps) => {
  const [displayedEntries, setDisplayedEntries] = useState<AssetHistory[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOlderEntry, setSelectedOlderEntry] = useState<string>('');
  const hostname = rowData.domain?.domainname || rowData.ipaddressv4;

  useEffect(() => {
    const initialEntries = historyData.slice(0, 3);
    setDisplayedEntries(initialEntries);
    setSelectedTab(0);
  }, [historyData]);

  const remainingOlderEntries = historyData
    .slice(3)
    .filter((entry) => !displayedEntries.some((d) => d.last_scanned === entry.last_scanned));

  const handleOlderSnapshotSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = parseInt(event.target.value, 10);
    const selectedEntry = remainingOlderEntries[selectedIndex];

    if (selectedEntry) {
      setSelectedOlderEntry(event.target.value);
      setSelectedTab(3); // A special index to show older entry
    }
  };

  if (isFetching) {
    return <div className="w-full animate-pulse">{/* Skeleton remains same */}</div>;
  }

  if (!historyData?.length) {
    return <div className="no-history">No History</div>;
  }

  return (
    <div className="w-full pb-8">
      <div className="flex border-b border-gray-200">
        {displayedEntries.map((entry, index) => (
          <button
            key={entry.last_scanned}
            className={`px-2 py-1 min-w-[120px] text-xs ${
              selectedTab === index
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
            onClick={() => {
              setSelectedTab(index);
              setSelectedOlderEntry('');
            }}
          >
            {formatShortDateTime(entry.last_scanned)}
          </button>
        ))}

        {remainingOlderEntries.length > 0 && (
          <div className="relative">
            <select
              className={`px-2 py-1 min-w-[120px] text-xs border-none focus:ring-0 hover:bg-gray-100 ${
                selectedOlderEntry !== ''
                  ? 'text-blue-500 border-b-2 border-blue-500'
                  : 'text-gray-600'
              }`}
              onChange={handleOlderSnapshotSelect}
              value={selectedOlderEntry}
              onClick={(e) => e.stopPropagation()}
            >
              <option value="" disabled>
                Older Snapshots
              </option>
              {remainingOlderEntries.map((entry, index) => (
                <option key={entry.last_scanned} value={index}>
                  {formatShortDateTime(entry.last_scanned)}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div className="mt-4">
        {displayedEntries.map((entry, index) => (
          <div key={entry.last_scanned} className={`${selectedTab === index ? 'block' : 'hidden'}`}>
            <div className="flex justify-between">
              <div className="space-y-4">
                <div className="asset-overview-text">
                  <div className="text-left">
                    <div>
                      <strong>Hostname:</strong> {hostname}
                    </div>
                    <div>
                      <strong>Port:</strong> {rowData.port}
                    </div>
                    <strong>Scanned At:</strong> {formatShortDateTime(entry.last_scanned)}
                  </div>

                  {/* DNS Information */}
                  <div className="mt-4">
                    <strong className="block mb-2">DNS Information</strong>
                    {entry.dns_record && entry.dns_record.cname && (
                      <div className="flex gap-2">
                        <div className="font-medium">CNAME:</div>
                        <div>{entry.dns_record.cname || 'Not Available'}</div>
                      </div>
                    )}
                    {entry.dns_record &&
                      entry.dns_record.ipaddress &&
                      entry.dns_record.ipaddress[0] && (
                        <div className="flex gap-2">
                          <div className="font-medium">IP Address:</div>
                          <div>{entry.dns_record.ipaddress[0].ipaddress}</div>
                        </div>
                      )}
                    {entry.dns_record &&
                      entry.dns_record.ipaddress &&
                      entry.dns_record.ipaddress[0]?.asn && (
                        <>
                          <div className="flex gap-2">
                            <div className="font-medium">ASN #:</div>
                            <div>{entry.dns_record.ipaddress[0].asn.asn}</div>
                          </div>
                          <div className="flex gap-2">
                            <div className="font-medium">IP Range:</div>
                            <div>{entry.dns_record.ipaddress[0].asn.iprange}</div>
                          </div>
                          <div className="flex gap-2">
                            <div className="font-medium">Owner:</div>
                            <div>{entry.dns_record.ipaddress[0].asn.owner}</div>
                          </div>
                          <br />
                        </>
                      )}
                  </div>

                  {/* HTTP Information */}
                  <div className="mt-4">
                    <strong className="block mb-2">HTTP Information</strong>
                    {!entry.homepage_status_code && (
                      <div className="text-red-600">
                        Unable to connect to {hostname} on {rowData.port}
                      </div>
                    )}
                    {entry.homepage_server_header && (
                      <div className="flex gap-2">
                        <div className="font-medium">Server Header:</div>
                        <div>{entry.homepage_server_header || 'None'}</div>
                      </div>
                    )}
                    {entry.homepage_title && (
                      <div className="flex gap-2">
                        <div className="font-medium">HTML Title:</div>
                        <div>{entry.homepage_title || 'None'}</div>
                      </div>
                    )}
                    {entry.homepage_redirect_url && (
                      <div className="flex gap-2">
                        <div className="font-medium">Redirect URL:</div>
                        <div>{entry.homepage_redirect_url || 'None'}</div>
                      </div>
                    )}
                    {entry.homepage_status_code && (
                      <div className="flex gap-2">
                        <div className="font-medium">HTTP Status Code:</div>
                        <div>{entry.homepage_status_code || 'None'}</div>
                      </div>
                    )}
                  </div>

                  {/* Technologies */}
                  <div className="mt-4">
                    <strong className="block mb-2">Technologies:</strong>
                    {!entry.technologies?.length ? (
                      <div className="text-gray-600">No technologies identified</div>
                    ) : (
                      <div className="space-y-1">
                        {entry.technologies.sort().map((tech, index) => (
                          <div key={index}>{tech}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Screenshot */}
              <div>
                <ImageWithModal
                  imageUrl={entry.homepage_image}
                  hostname={hostname}
                  className="max-h-[500px] max-w-full cursor-pointer"
                />
              </div>
            </div>
          </div>
        ))}

        {selectedTab === 3 && selectedOlderEntry !== '' && (
          <div className="flex justify-between">
            <div className="space-y-4">
              <div className="asset-overview-text">
                <div className="text-left">
                  <div>
                    <strong>Hostname:</strong> {hostname}
                  </div>
                  <div>
                    <strong>Port:</strong> {rowData.port}
                  </div>
                  <strong>Scanned At:</strong>{' '}
                  {formatShortDateTime(
                    remainingOlderEntries[parseInt(selectedOlderEntry, 10)].last_scanned,
                  )}
                </div>

                {/* DNS Information */}
                <div className="mt-4">
                  <strong className="block mb-2">DNS Information</strong>
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record?.cname && (
                    <div className="flex gap-2">
                      <div className="font-medium">CNAME:</div>
                      <div>
                        {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record.cname ||
                          'Not Available'}
                      </div>
                    </div>
                  )}
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record
                    ?.ipaddress?.[0] && (
                    <div className="flex gap-2">
                      <div className="font-medium">IP Address:</div>
                      <div>
                        {
                          remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record
                            .ipaddress[0].ipaddress
                        }
                      </div>
                    </div>
                  )}
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record
                    ?.ipaddress?.[0]?.asn && (
                    <>
                      <div className="flex gap-2">
                        <div className="font-medium">ASN #:</div>
                        <div>
                          {
                            remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record
                              .ipaddress[0].asn.asn
                          }
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="font-medium">IP Range:</div>
                        <div>
                          {
                            remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record
                              .ipaddress[0].asn.iprange
                          }
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="font-medium">Owner:</div>
                        <div>
                          {
                            remainingOlderEntries[parseInt(selectedOlderEntry, 10)].dns_record
                              .ipaddress[0].asn.owner
                          }
                        </div>
                      </div>
                      <br />
                    </>
                  )}
                </div>

                {/* HTTP Information */}
                <div className="mt-4">
                  <strong className="block mb-2">HTTP Information</strong>
                  {!remainingOlderEntries[parseInt(selectedOlderEntry, 10)]
                    .homepage_status_code && (
                    <div className="text-red-600">
                      Unable to connect to {hostname} on {rowData.port}
                    </div>
                  )}
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)]
                    .homepage_server_header && (
                    <div className="flex gap-2">
                      <div className="font-medium">Server Header:</div>
                      <div>
                        {remainingOlderEntries[parseInt(selectedOlderEntry, 10)]
                          .homepage_server_header || 'None'}
                      </div>
                    </div>
                  )}
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].homepage_title && (
                    <div className="flex gap-2">
                      <div className="font-medium">HTML Title:</div>
                      <div>
                        {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].homepage_title ||
                          'None'}
                      </div>
                    </div>
                  )}
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)]
                    .homepage_redirect_url && (
                    <div className="flex gap-2">
                      <div className="font-medium">Redirect URL:</div>
                      <div>
                        {remainingOlderEntries[parseInt(selectedOlderEntry, 10)]
                          .homepage_redirect_url || 'None'}
                      </div>
                    </div>
                  )}
                  {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].homepage_status_code && (
                    <div className="flex gap-2">
                      <div className="font-medium">HTTP Status Code:</div>
                      <div>
                        {remainingOlderEntries[parseInt(selectedOlderEntry, 10)]
                          .homepage_status_code || 'None'}
                      </div>
                    </div>
                  )}
                </div>

                {/* Technologies */}
                <div className="mt-4">
                  <strong className="block mb-2">Technologies:</strong>
                  {!remainingOlderEntries[parseInt(selectedOlderEntry, 10)].technologies?.length ? (
                    <div className="text-gray-600">No technologies identified</div>
                  ) : (
                    <div className="space-y-1">
                      {remainingOlderEntries[parseInt(selectedOlderEntry, 10)].technologies
                        .sort()
                        .map((tech, index) => (
                          <div key={index}>{tech}</div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Screenshot */}
            <div>
              <ImageWithModal
                imageUrl={remainingOlderEntries[parseInt(selectedOlderEntry, 10)].homepage_image}
                hostname={hostname}
                className="max-h-[600px] max-w-full cursor-pointer"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryView;
