import { useAnalytics } from '@/hooks/useAnalytics';
import { ReactNode, createContext, useContext } from 'react';

// Context type for analytics
type AnalyticsContextType = ReturnType<typeof useAnalytics>;

// Create context with a sensible default
const AnalyticsContext = createContext<AnalyticsContextType>({
  trackPageView: () => {},
  trackEvent: () => {},
  trackTiming: () => {},
  startTimeTracking: () => () => 0,
  trackFeatureUsage: () => {},
  trackSearch: () => {},
});

interface AnalyticsProviderProps {
  children: ReactNode;
}

/**
 * Provider component that makes analytics functions available to any
 * child component via the useAnalyticsContext hook.
 */
export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const analytics = useAnalytics();

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}

/**
 * Hook to easily access analytics functions anywhere in your component tree
 */
export const useAnalyticsContext = () => useContext(AnalyticsContext);
