import { getVulnerabilitySearchApi } from '@/api/investigations.api';
import { Ellipsis } from '@/components/common/Ellipsis';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { cn } from '@/lib/utils';
import { VulnerabilityTypeMap } from '@/types/api/response/common';
import { VulnerabilitySearchResponse } from '@/types/api/response/investigations';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { Check, ChevronDown, Globe, Pencil, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

export interface ShortVulnerability {
  name: string;
  id: number;
  type: string;
  references: string[];
}

interface VulnerabilitySelectorProps {
  placeholderText?: string;
  selectedVulnerability: ShortVulnerability | null;
  onVulnerabilitySelect: (vulnerability: ShortVulnerability | null) => void;
  onClear: () => void;
  loading?: boolean;
  target?: string;
  resourceVulnerability: boolean;
  from?: string;
  details?: boolean;
}

const getVulnerabilityDisplayValue = (
  vulnerability: ShortVulnerability | null,
  target: string = '_self',
  maxLength?: number,
) => {
  if (!vulnerability) return null;

  const displayText = vulnerability.name || 'Unknown Vulnerability';

  const display = maxLength ? <Ellipsis text={displayText} maxLength={maxLength} /> : displayText;

  if (vulnerability.references && vulnerability.references.length > 0 && target === '_blank') {
    return (
      <a
        href={vulnerability.references[0]}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline"
      >
        {display}
      </a>
    );
  }

  return display;
};

const fetchVulnerabilitySuggestions = async (query: string) => {
  const response = await getVulnerabilitySearchApi(query);
  return response;
};

export const VulnerabilitySelector = ({
  placeholderText,
  selectedVulnerability,
  onVulnerabilitySelect,
  onClear,
  loading = false,
  target = '_self',
  resourceVulnerability = false,
  from = 'issues',
  details = false,
}: VulnerabilitySelectorProps) => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<VulnerabilitySearchResponse>([]);
  const [isFetching, setIsFetching] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const vulnerabilityType = selectedVulnerability?.type;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        if (isEditing) {
          setIsEditing(false);
          setInputValue('');
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, setIsEditing]);

  const handleInputChange = async (value: string) => {
    setInputValue(value);

    if (value.trim().length > 2) {
      setIsFetching(true);
      try {
        const results = await fetchVulnerabilitySuggestions(value);
        console.log(results);
        setSuggestions(results);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      } finally {
        setIsFetching(false);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion: VulnerabilitySearchResponse[0]) => {
    onVulnerabilitySelect({
      name: suggestion.vulnerability_name,
      id: suggestion.id,
      type: suggestion.vulnerability_type,
      references: [],
    });
    setOpen(false);
    setIsEditing(false);
    setInputValue('');
  };

  const handleEdit = () => {
    setIsEditing(true);
    setOpen(true);
  };

  const handleRemove = () => {
    onClear();
    setInputValue('');
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setOpen(false);
  };

  const url = resourceVulnerability
    ? `/investigation/${selectedVulnerability?.id}?from=${from}&type=${vulnerabilityType === 'nucleitemplates' ? 'nuclei' : 'passive'}`
    : `/vulnerabilities/${selectedVulnerability?.name}?from=${from}`;

  if (selectedVulnerability && !isEditing) {
    return (
      <div className="flex items-center justify-between">
        <div className="flex-grow truncate">
          <LinkWithAction
            to={url}
            dataClickBypass={true}
            title="View Vulnerability Details"
            actions={[]}
            showAsButton={true}
            buttonIcon={<Globe className="h-4 w-4" />}
            target={'_self'}
          >
            {getVulnerabilityDisplayValue(selectedVulnerability, target, 50)}
          </LinkWithAction>
        </div>
        {!details && (
          <div className="flex items-center ml-2 shrink-0">
            <Button variant="ghost" size="icon" onClick={handleEdit} className="h-8 w-8 p-0">
              <Pencil className="h-4 w-4" />
            </Button>
            <Button variant="ghost" size="icon" onClick={handleRemove} className="h-8 w-8 p-0">
              <X className="h-4 w-4" />
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div ref={popoverRef} className="relative w-[300px]">
      <div>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between bg-black hover:bg-black text-white"
          disabled={loading}
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          {loading ? (
            <span className="animate-pulse">Loading...</span>
          ) : (
            <>
              <span className="text-white font-medium">
                {placeholderText || 'Select Vulnerability'}
              </span>
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 text-white hover:text-gray-800" />
            </>
          )}
        </Button>
      </div>

      {open && (
        <div className="absolute top-full left-0 w-full mt-1 z-50">
          <Command className="rounded-lg border shadow-md" shouldFilter={false}>
            <CommandInput
              placeholder="Search..."
              value={inputValue}
              onValueChange={handleInputChange}
              className="h-9"
            />
            <CommandList className="max-h-60 overflow-auto">
              <CommandEmpty>{isFetching ? 'Loading...' : 'No results found'}</CommandEmpty>
              <CommandGroup>
                {suggestions.map((suggestion, index) => (
                  <CommandItem
                    key={`${suggestion.id}-${index}`}
                    value={suggestion.vulnerability_name || `suggestion-${index}`}
                    onSelect={() => handleSuggestionSelect(suggestion)}
                    className="cursor-pointer"
                  >
                    <div className="flex flex-col gap-1">
                      {suggestion.vulnerability_name.trim()}
                      <span className="text-xs text-gray-400">
                        {suggestion.vulnerability_type.toLowerCase() === VulnerabilityTypeMap.NVD
                          ? 'Passive'
                          : 'Dynamic'}
                      </span>
                    </div>

                    <Check
                      className={cn(
                        'ml-auto h-4 w-4',
                        selectedVulnerability?.id === suggestion.id ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
          {isEditing && (
            <div className="flex justify-end p-2 border-t">
              <Button variant="outline" size="sm" onClick={handleCancelEdit}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
