import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useAuth } from '@/Helpers/AuthContext';
import { toast } from '@/hooks/use-toast';
import NoteCreator from '@/Notes/NoteCreator';
import { Suggestion } from '@/types/api/response/assets';
import { Note } from '@/types/api/response/notes';
import AssetsFinderAutocomplete from '@/v2/components/AssetsFinderAutocomplete/AssetsFinderAutocomplete';
import { getAssetSuggestionsQuery } from '@/v2/queries/assets';
import { getUserNotesQuery, useDeleteNoteCache, useDeleteNoteMutation } from '@/v2/queries/notes';
import { useQuery } from '@tanstack/react-query';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  Calendar,
  Clock,
  Download,
  Edit,
  Globe,
  Loader2,
  MoreHorizontal,
  StickyNote,
  Trash,
} from 'lucide-react';
import { useState } from 'react';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Helmet } from 'react-helmet';
import { formatShortDateTime } from '@/v2/utils';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { Separator } from '@/components/ui/separator';
import { Tip } from '@/components/common/Tip';

const Notes = () => {
  const { userData } = useAuth();
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState<string>('startswith');
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);
  const [freeSearchValue, setFreeSearchValue] = useState<string>('');
  const [notesFilter, setNotesFilter] = useState<string[]>([]);
  const [showNoteCreator, setShowNoteCreator] = useState<boolean>(false);
  const [editNote, setEditNote] = useState<Note | null>(null);
  const { navigateWithParams } = useNavigateWithParams();
  const { mutate: deleteNote } = useDeleteNoteMutation();
  const deleteNoteCache = useDeleteNoteCache();

  const onAfterSave = async () => {
    setShowNoteCreator(false);
    setEditNote(null);
    await getUserNotesQuery({
      verbose: true,
      httpAsset: selectedSuggestion?.id,
      search: freeSearchValue,
      filter: notesFilter,
    });
  };

  const { data, isPending: isLoading } = useQuery(
    getUserNotesQuery({
      verbose: true,
      httpAsset: selectedSuggestion?.id,
      search: freeSearchValue,
      filter: notesFilter,
    }),
  );

  const { data: suggestions } = useQuery(
    getAssetSuggestionsQuery({
      assetFilter: searchFilter,
      assetFilterValue: search,
      onlyVulnerabilities: false,
      enabled: search.length > 1,
    }),
  );

  const handleSelectedSuggestion = (suggestion: Suggestion) => {
    setSelectedSuggestion(suggestion);
    setSearch(suggestion.domain.domainname);
  };

  const handleClearSuggestion = () => {
    setSelectedSuggestion(null);
    setSearch('');
  };

  const handleFreeSearchChange = (value: string) => {
    setFreeSearchValue(value);
  };

  const handleClearFreeSearch = () => {
    setFreeSearchValue('');
  };

  const handleNotesFilterChange = (value: string, checked: boolean | string) => {
    if (checked) {
      setNotesFilter([...notesFilter, value]);
    } else {
      setNotesFilter(notesFilter.filter((v) => v !== value));
    }
  };

  const handleOpenNoteCreator = (note: Note) => {
    setEditNote(note);
    setShowNoteCreator(true);
  };

  const handleCloseNoteCreator = () => {
    setEditNote(null);
    setShowNoteCreator(false);
  };

  const handleDeleteNote = (noteId: string) => {
    deleteNote(noteId);
    deleteNoteCache(noteId);
    toast({
      title: 'Note deleted',
      description: (
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">Note deleted successfully</span>
          </div>
        </div>
      ),
      duration: 5000,
    });
  };

  const preloadImages = (element: HTMLElement) => {
    const images: HTMLImageElement[] = Array.from(element.getElementsByTagName('img'));
    const loadPromises = images.map(
      (image) =>
        new Promise((resolve, reject) => {
          if (image.complete && image.naturalHeight !== 0) {
            resolve(void 0);
          } else {
            image.onload = resolve;
            image.onerror = reject;
          }
        }),
    );
    return Promise.all(loadPromises);
  };

  const handleDownloadNote = async (note: Note) => {
    const markdownPreviewElement: HTMLElement = document.querySelector(
      `#markdown-preview-${note.id}`,
    ) as HTMLElement;

    if (markdownPreviewElement) {
      await preloadImages(markdownPreviewElement);

      const canvas = await html2canvas(markdownPreviewElement, { useCORS: true });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`note-${note.id}.pdf`);
    }
  };

  return (
    <div className="p-4">
      <Helmet>
        <title>Notes</title>
      </Helmet>
      <div className="flex justify-between mb-6">
        <div className="flex gap-4">
          <div className="flex gap-2">
            <Input
              placeholder="Search notes"
              onChange={(e) => handleFreeSearchChange(e.target.value)}
              value={freeSearchValue}
              onClear={() => handleClearFreeSearch()}
              showClearButton={freeSearchValue.length > 0}
            />
            <AssetsFinderAutocomplete
              assetFilter={searchFilter}
              handleAssetFilterChange={setSearchFilter}
              assetFilterValue={search}
              handleAssetFilterValueChange={setSearch}
              suggestions={suggestions}
              showSuggestions={!selectedSuggestion}
              handleSuggestionClick={handleSelectedSuggestion}
              onClear={handleClearSuggestion}
              searchingBy="Asset"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={notesFilter.includes('myNotes')}
              onCheckedChange={(checked) => handleNotesFilterChange('myNotes', checked)}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              My Notes Only
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={notesFilter.includes('orgNotes')}
              onCheckedChange={(checked) => handleNotesFilterChange('orgNotes', checked)}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Test Customer Notes
            </label>
          </div>
        </div>
        <Button
          className="bg-v2-orange text-white flex items-center gap-2"
          onClick={() => handleOpenNoteCreator(null)}
        >
          <StickyNote className="h-4 w-4  " />
          Create Note
        </Button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-row items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span className="text-sm text-muted-foreground">Loading...</span>
          </div>
        </div>
      ) : data?.length > 0 ? (
        <ScrollArea className="h-[calc(100vh-150px)]">
          <div className="flex flex-col gap-4 border-gray-200 border p-4 rounded-md border-gray-200">
            {data?.map((note) => (
              <div key={note.id} className="bg-white rounded-md shadow-sm border border-gray-200">
                <div className="p-4">
                  <div className="flex justify-between items-start mb-4 text-sm">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Created by {note.user}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Hostname:</span>
                        {note.http_asset && note.http_asset.hostname && note.http_asset.id ? (
                          <LinkWithAction
                            to={navigateWithParams(
                              'assets',
                              note.http_asset?.id.toString(),
                              'notes',
                            )}
                            buttonIcon={<Globe className="h-2 w-2" />}
                            showAsButton={true}
                            dataClickBypass={true}
                            title="View Asset"
                            target="_self"
                            actions={[]}
                          >
                            <span className="text-sm">{note.http_asset?.hostname ?? '-'}</span>
                          </LinkWithAction>
                        ) : (
                          <span className="text-sm">N/A</span>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Port:</span>
                        <span>{note.http_asset?.port ?? 'N/A'}</span>
                      </div>
                    </div>

                    <div className="flex items-center gap-4">
                      <Tip content={'Created at'}>
                        <div className="flex items-center gap-1 text-muted-foreground">
                          <Calendar className="h-4 w-4" />
                          <span className="text-sm">{formatShortDateTime(note.created)}</span>
                        </div>
                      </Tip>
                      <Tip content={'Last modified'}>
                        <div className="flex items-center gap-1 text-muted-foreground">
                          <Clock className="h-4 w-4" />
                          <span className="text-sm">{formatShortDateTime(note.last_modified)}</span>
                        </div>
                      </Tip>
                      {userData.email === note.user && (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="icon" className="h-8 w-8">
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">More options</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              className="cursor-pointer"
                              onClick={() => handleOpenNoteCreator(note)}
                            >
                              <Edit className="mr-2 h-4 w-4" />
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="cursor-pointer"
                              onClick={() => handleDownloadNote(note)}
                            >
                              <Download className="mr-2 h-4 w-4" />
                              Download
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              className="cursor-pointer text-red-500"
                              onClick={() => handleDeleteNote(note.id)}
                            >
                              <Trash className="mr-2 h-4 w-4" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                    </div>
                  </div>
                  <Separator />
                  <MarkdownViewer markdown={note.text} />
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      ) : (
        <div className="flex flex-col gap-4 border-gray-200 border p-4 rounded-md border-gray-200">
          <span className="text-center text-gray-500">No notes found</span>
        </div>
      )}
      {showNoteCreator && (
        <NoteCreator
          headerContent={
            editNote ? `Note created on ${formatShortDateTime(editNote.created)}` : 'New Note'
          }
          initialContent={editNote ? editNote.text : ''}
          noteId={editNote ? editNote.id : null}
          assetId={editNote?.http_asset?.id}
          onAfterSave={onAfterSave}
          onCloseAction={handleCloseNoteCreator}
        />
      )}
    </div>
  );
};

export default Notes;
