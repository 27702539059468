import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/Helpers/AuthContext';
import { toast } from '@/hooks/use-toast';
import { changePassword } from '@/pages/User/UserApi';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Helmet } from 'react-helmet';

const formSchema = z
  .object({
    currentPassword: z.string().min(8, { message: 'Password must be at least 8 characters' }),
    newPassword: z.string().min(8, { message: 'Password must be at least 8 characters' }),
    confirmPassword: z.string().min(8, { message: 'Password must be at least 8 characters' }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

const ChangePassword = () => {
  const [error, setError] = useState('');
  const { logout } = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { currentPassword, newPassword, confirmPassword } = data;
    if (newPassword !== confirmPassword) {
      toast({
        title: 'Passwords do not match',
        description: 'Please ensure your new password and confirmation match.',
        duration: 5000,
      });
      return;
    }

    try {
      const resp = await changePassword(currentPassword, newPassword);
      if (resp.ok) {
        toast({
          title: 'Password changed successfully.',
          description: 'You will be logged out to apply changes.',
          duration: 5000,
        });
        setTimeout(() => {
          logout();
        }, 3000); // 3 seconds timeout
      } else {
        const resp_data = await resp.json(); // Assume error is in JSON
        const errorMessages = resp_data.new_password
          ? resp_data.new_password.join(' ')
          : resp_data.error;
        setError(errorMessages);
        toast({
          title: 'Failed password change.',
          description: errorMessages,
          duration: 5000,
        });
        throw new Error(errorMessages);
      }
    } catch (error) {
      toast({
        title: 'Failed to change password.',
        description: error.message,
        duration: 5000,
      });
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px]">
            <h1 className="text-v2-white text-2xl mb-8 uppercase tracking-wider text-center font-lato font-bold">
              Change Password
            </h1>

            {error && (
              <div className="mb-4 p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500">
                {error}
              </div>
            )}

            <div className="mb-6">
              <FormField
                control={form.control}
                name="currentPassword"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Current Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your current password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="newPassword"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="New Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your new password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Confirm New Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Confirm your new password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 px-3 bg-v2-orange text-v2-white text-base rounded-md
                   cursor-pointer transition-colors hover:bg-v2-orange/90 font-poppins font-semibold"
            >
              Change Password
            </button>
          </form>
        </Form>
      </div>
      <AuthBackground />
    </div>
  );
};

export default ChangePassword;
