import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Switch } from '@/components/ui/switch';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { cn } from '@/lib/utils';
import NotesTabDisplay from '@/Notes/NotesTabDisplay';
import { IPAddressV4Detail } from '@/types/api/response/ipaddress';
import { Issue, ISSUE_STATUSES, ISSUE_STATUSES_NAMES } from '@/types/api/response/issues';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { getSeverityDisplay } from '@/v2/components/SeverityBadge/SeverityBadge';
import { formatShortDateTime } from '@/v2/utils';
import { AlertTriangle, Calendar, Clock, Globe, Link2, Lock, Server, Shield } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export function IPAddressDashboard({ data }: { data: IPAddressV4Detail | undefined }) {
  const navigate = useNavigate();
  const { navigateWithParams } = useNavigateWithParams();

  // Calculate statistics
  const stats = {
    totalDomains: data.domains?.length ?? 0,
    totalPorts: data.ports?.length ?? 0,
    totalAssets: data.http_assets?.length ?? 0,
    openIssues: data.issues?.filter((issue) => !issue.is_resolved).length ?? 0,
    criticalIssues: data.issues?.filter((issue) => issue.severity === 'critical').length ?? 0,
  };

  const [showChanges, setShowChanges] = useState(false);

  return (
    <div className="flex flex-col gap-6 pb-6">
      {/* Header Section */}
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
        <div>
          <h1 className="text-2xl font-bold tracking-tight">IP Address Details</h1>
          <p className="text-muted-foreground">Comprehensive analysis for {data.ipaddress}</p>
        </div>
        <div className="flex items-center gap-4 text-sm text-muted-foreground">
          <div className="flex items-center gap-1">
            <Clock className="h-4 w-4" />
            Last Scanned: {formatShortDateTime(data.last_modified)}
          </div>
          <div className="flex items-center gap-1">
            <Calendar className="h-4 w-4" />
            Created: {formatShortDateTime(data.created_at)}
          </div>
        </div>
      </div>

      {/* Stats Overview */}
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Critical Issues</CardTitle>
            <Shield className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-red-600">{stats.criticalIssues}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Open Issues</CardTitle>
            <AlertTriangle className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.openIssues}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Open Ports</CardTitle>
            <Server className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.totalPorts}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">HTTP Assets</CardTitle>
            <Link2 className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.totalAssets}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Domains</CardTitle>
            <Globe className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.totalDomains}</div>
          </CardContent>
        </Card>
      </div>

      <Tabs defaultValue="overview" className="space-y-4">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="issues">Issues</TabsTrigger>
          <TabsTrigger value="assets">Assets</TabsTrigger>
          <TabsTrigger value="notes">Notes</TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="space-y-4">
          <div className="grid gap-4 md:grid-cols-2">
            <Card className="col-span-1">
              <CardHeader>
                <CardTitle>Domains</CardTitle>
                <CardDescription>Associated domain names and virtual hosts</CardDescription>
              </CardHeader>
              <CardContent>
                <ScrollArea className="max-h-[200px] pr-4 overflow-y-auto">
                  {data.domains?.length === 0 ? (
                    <div className="text-muted-foreground text-sm">No domains found.</div>
                  ) : (
                    <div className="space-y-4">
                      {data.domains?.map((domain, index) => (
                        <LinkWithAction
                          showAsButton={true}
                          buttonIcon={<Globe className="h-4 w-4" />}
                          to={navigateWithParams(
                            `assets/domains?domain_search_value=${domain.domainname}`,
                            '',
                            `ipaddress/${data.ipaddress}`,
                          )}
                          dataClickBypass={true}
                          title="View Domain"
                          target="_self"
                          actions={[]}
                          key={index}
                        >
                          {domain.domainname}
                        </LinkWithAction>
                      ))}
                    </div>
                  )}
                </ScrollArea>
              </CardContent>
            </Card>

            <Card className="col-span-1">
              <CardHeader className="flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <CardTitle>Open Ports</CardTitle>
                  <CardDescription>Currently open ports and services</CardDescription>
                </div>
                <div className="flex justify-end">
                  {data.ports?.length > 0 && (
                    <div className="flex items-center space-x-2">
                      <Switch
                        id="show-changes"
                        checked={showChanges}
                        onCheckedChange={setShowChanges}
                      />
                      <Label htmlFor="show-changes">Show Changes</Label>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardContent>
                <ScrollArea className=" pr-4">
                  {data.ports?.length === 0 ? (
                    <div className="text-muted-foreground text-sm">No open ports found.</div>
                  ) : (
                    <>
                      {showChanges &&
                        !data.ports?.some(
                          (port) =>
                            (port.previous_state &&
                              port.previous_state !== port.state &&
                              port.previous_state !== 'unknown') ||
                            (port.previous_service &&
                              port.previous_service !== port.service &&
                              port.previous_service !== 'unknown'),
                        ) && (
                          <div className="mb-4 text-sm text-muted-foreground">
                            No changes to show.
                          </div>
                        )}
                      {data.ports?.map((port, index) => {
                        const hasChanges =
                          port.previous_state &&
                          port.previous_state !== port.state &&
                          port.previous_state !== 'unknown';
                        const hasServiceChanges =
                          port.previous_service &&
                          port.previous_service !== port.service &&
                          port.previous_service !== 'unknown';
                        return (
                          <div
                            key={index}
                            className={cn(
                              'mb-2 flex items-center justify-between rounded-lg border p-2',
                              showChanges &&
                                (hasChanges || hasServiceChanges) &&
                                'border-yellow-500/50 bg-yellow-50/50',
                            )}
                          >
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center gap-2">
                                <Lock className="h-4 w-4 text-muted-foreground" />
                                <div>
                                  <div className="font-medium">
                                    Port {port.port || 'Unknown'} (
                                    {port.service.toUpperCase() || 'Unknown'}
                                    {showChanges && hasServiceChanges && (
                                      <>
                                        {' '}
                                        <span className="ml-2 ">
                                          {'-->'} {port.previous_service.toUpperCase() || 'Unknown'}
                                        </span>
                                      </>
                                    )}
                                    )
                                  </div>
                                  <div className="text-sm text-muted-foreground">
                                    {port.service_version ? ` ${port.service_version}` : ''}
                                  </div>
                                  {showChanges && hasChanges && (
                                    <div className="text-xs text-muted-foreground flex items-center gap-1 mt-1">
                                      <span>State changed from</span>
                                      <Badge variant="secondary" className="text-xs">
                                        {port.previous_state}
                                      </Badge>
                                      <span>to</span>
                                      <Badge variant="secondary" className="text-xs">
                                        {port.state}
                                      </Badge>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                {showChanges && (hasChanges || hasServiceChanges) && (
                                  <Badge variant="outline" className="bg-yellow-50">
                                    Changed
                                  </Badge>
                                )}
                                <Badge variant={port.state === 'open' ? 'default' : 'secondary'}>
                                  {port.state.toUpperCase()}
                                </Badge>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </ScrollArea>
              </CardContent>
            </Card>
          </div>

          <SSLCertCard certs={data.ssl_certs} />
          <Card>
            <CardHeader>
              <CardTitle>Recent Activity</CardTitle>
              <CardDescription>Latest changes and updates</CardDescription>
            </CardHeader>
            <CardContent>
              {data.issues?.length === 0 ? (
                <p className="text-muted-foreground text-sm">No recent activity.</p>
              ) : (
                <div className="space-y-4">
                  {data.issues?.slice(0, 5).map((issue, index) => (
                    <div key={index} className="flex items-start gap-4 rounded-lg border p-3">
                      {getSeverityDisplay({
                        severity: issue.severity as Issue['severity'],
                        showLabel: false,
                        showIcon: true,
                      })}
                      <div className="flex-1">
                        <Link
                          to={`/issues/${issue.id}`}
                          target="_self"
                          className="font-medium hover:underline cursor-pointer"
                        >
                          {issue.title}
                        </Link>
                        <p className="text-sm text-muted-foreground">{issue.description}</p>
                        <div className="mt-2 flex gap-2">
                          <Badge
                            className={ISSUE_STATUSES[issue.status as keyof typeof ISSUE_STATUSES]}
                          >
                            {
                              ISSUE_STATUSES_NAMES[
                                issue.status as keyof typeof ISSUE_STATUSES_NAMES
                              ]
                            }
                          </Badge>
                          {issue.is_resolved && (
                            <Badge variant="outline" className="bg-green-50">
                              Resolved
                            </Badge>
                          )}
                        </div>
                      </div>
                      <time className="text-sm text-muted-foreground">
                        {DateTime.fromISO(issue.created_at).toRelative()}
                      </time>
                    </div>
                  )) ?? <div>No recent activity.</div>}
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="issues" className="space-y-4">
          <Alert>
            <Shield className="h-4 w-4" />
            <AlertTitle>Security Overview</AlertTitle>
            <AlertDescription>
              Monitoring {stats.totalPorts} open ports, {stats.totalAssets} HTTP Assets, and{' '}
              {stats.totalDomains} domains with {stats.openIssues} open security issues.
            </AlertDescription>
          </Alert>
          <div className="grid gap-4 md:grid-cols-2">
            {data.issues?.length === 0 ? (
              <div className="text-muted-foreground text-sm">No security issues found.</div>
            ) : (
              data.issues?.map((issue, index) => (
                <Card key={index} className="h-full">
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <Link
                        to={`/issues/${issue.id}?from=ipaddress/${data.ipaddress}`}
                        target="_self"
                        className="font-semibold hover:underline cursor-pointer"
                      >
                        {issue.title}
                      </Link>
                      {getSeverityDisplay({
                        severity: issue.severity as Issue['severity'],
                        showLabel: true,
                        showIcon: true,
                      })}
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p className="text-sm text-muted-foreground">{issue.description}</p>
                    <div className="mt-4 flex items-center justify-between text-sm">
                      <div className="flex items-center gap-2">
                        <Badge
                          className={ISSUE_STATUSES[issue.status as keyof typeof ISSUE_STATUSES]}
                        >
                          {ISSUE_STATUSES_NAMES[issue.status as keyof typeof ISSUE_STATUSES_NAMES]}
                        </Badge>
                        {issue.is_resolved && (
                          <Badge variant="outline" className="bg-green-50">
                            Resolved
                          </Badge>
                        )}
                      </div>
                      <time className="text-muted-foreground">
                        {DateTime.fromISO(issue.created_at).toRelative()}
                      </time>
                    </div>
                  </CardContent>
                </Card>
              ))
            )}
          </div>
        </TabsContent>

        <TabsContent value="assets" className="space-y-4">
          <div className="grid gap-4 md:grid-cols-2">
            {data.http_assets?.length === 0 ? (
              <div className="text-muted-foreground text-sm">No HTTP assets found.</div>
            ) : (
              data.http_assets.map((asset, index) => (
                <Card
                  key={index}
                  className="cursor-pointer hover:bg-muted-foreground/10"
                  onClick={() => {
                    navigate(
                      navigateWithParams(
                        'assets',
                        asset.id.toString(),
                        `ipaddress/${data.ipaddress}`,
                      ),
                    );
                  }}
                  title="View asset details"
                >
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <CardTitle className="text-base">
                        {asset.hostname || asset.domain.domainname}
                      </CardTitle>
                      <Badge variant="outline">Port {asset.port}</Badge>
                    </div>
                    <CardDescription>
                      {asset.domain ? asset.domain.domainname : asset.hostname}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-muted-foreground">Status Code</span>
                        <Badge>{asset.homepage_status_code}</Badge>
                      </div>
                      {asset.homepage_title && (
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-muted-foreground">Title</span>
                          <span className="text-sm">{asset.homepage_title}</span>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              ))
            )}
          </div>
        </TabsContent>

        <TabsContent value="notes" className="space-y-4">
          <div className="flex flex-col gap-4 mx-2">
            {data.http_assets.length > 0 ? (
              <NotesTabDisplay
                assetId={data.http_assets[0].id}
                hostname={data.http_assets[0].hostname}
                showCreateNoteButton={false}
              />
            ) : (
              <div className="text-muted-foreground text-sm">
                Cant show notes cause there are no assets for this IP address
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}

interface SSLCert {
  subject_common_name: string;
  subject_alternative_name: string[];
  certValidOn: string;
  certExpiresOn: string;
  issuerOrganization: string;
  discovered_from: string;
}

interface SSLCertCardProps {
  certs?: SSLCert[];
}

function SSLCertCard({ certs = [] }: SSLCertCardProps) {
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Lock className="h-5 w-5" />
          SSL Certificates
        </CardTitle>
        <CardDescription>SSL certificates discovered on this IP address</CardDescription>
      </CardHeader>
      <CardContent>
        {certs.length === 0 ? (
          <p className="text-muted-foreground text-sm">No SSL certificates found.</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Domain</TableHead>
                <TableHead>Issuer</TableHead>
                <TableHead>Valid From</TableHead>
                <TableHead>Expires On</TableHead>
                <TableHead>Discovered From</TableHead>
                <TableHead>Subject Alternative Names</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {certs?.map((cert, index) => {
                const discoveredFrom = JSON.parse(cert.discovered_from);
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex flex-col">
                        <Badge variant="secondary" className="mt-1 w-fit">
                          <Globe className="mr-1 h-3 w-3" />
                          {cert.subject_common_name}
                        </Badge>
                      </div>
                    </TableCell>
                    <TableCell>{cert.issuerOrganization}</TableCell>
                    <TableCell>
                      <div className="flex items-center text-muted-foreground">
                        <Calendar className="mr-2 h-4 w-4" />
                        {formatShortDateTime(cert.certValidOn)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-muted-foreground">
                        <Calendar className="mr-2 h-4 w-4" />
                        {formatShortDateTime(cert.certExpiresOn)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Badge variant="outline">
                        {discoveredFrom.ip_address}:{discoveredFrom.port}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {cert.subject_alternative_name.length <= 3 ? (
                        cert.subject_alternative_name.map((altName, index) => (
                          <Badge variant="outline" key={index} className="mr-1">
                            {altName}
                          </Badge>
                        ))
                      ) : (
                        <div className="flex items-center gap-2">
                          {cert.subject_alternative_name.slice(0, 2).map((altName, index) => (
                            <Badge variant="outline" key={index}>
                              {altName}
                            </Badge>
                          ))}
                          <HoverCard>
                            <HoverCardTrigger>
                              <Badge variant="outline">
                                +{cert.subject_alternative_name.length - 2} more
                              </Badge>
                            </HoverCardTrigger>
                            <HoverCardContent className="w-80">
                              <ScrollArea className="">
                                <div className="flex flex-wrap gap-1">
                                  {cert.subject_alternative_name.slice(2).map((altName, index) => (
                                    <Badge variant="outline" key={index}>
                                      {altName}
                                    </Badge>
                                  ))}
                                </div>
                              </ScrollArea>
                            </HoverCardContent>
                          </HoverCard>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}
