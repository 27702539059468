import ReactGA from 'react-ga4';

// Google Analytics tracking ID
const GA_TRACKING_ID = 'G-D13SKLYCE1';

/**
 * Check if current environment is production
 */
const isProd = () => process.env.NODE_ENV === 'production';

/**
 * Initialize Google Analytics
 * Only initializes in production environment
 */
export function initializeAnalytics() {
  if (isProd()) {
    ReactGA.initialize(GA_TRACKING_ID, {
      gaOptions: {
        debug: false,
      },
    });
  }
}

/**
 * Track a page view
 * @param path - The current path being viewed
 * @param title - Optional page title
 */
export function trackPageView(path: string, title?: string) {
  if (!isProd()) return;

  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title: title,
  });
}

/**
 * Track a user event
 * @param category - Event category
 * @param action - Event action
 * @param label - Optional label
 * @param value - Optional value
 */
export function trackEvent(category: string, action: string, label?: string, value?: number) {
  if (!isProd()) return;

  ReactGA.event({
    category,
    action,
    label,
    value,
  });
}

interface UTMParameters {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

/**
 * Track UTM parameters and campaign data
 * @param searchParams - URLSearchParams object containing URL parameters
 */
export function trackVisitSource(searchParams: URLSearchParams) {
  if (!isProd()) return;

  const utmParams: UTMParameters = {
    utm_source:
      searchParams.get('utm_source') || searchParams.get('source') || searchParams.get('ref'),
    utm_medium: searchParams.get('utm_medium'),
    utm_campaign: searchParams.get('utm_campaign'),
    utm_term: searchParams.get('utm_term'),
    utm_content: searchParams.get('utm_content'),
  };

  // Remove undefined values
  const cleanParams = Object.fromEntries(
    Object.entries(utmParams).filter(([, value]) => value !== null),
  );

  // If we have any UTM parameters, track them
  if (Object.keys(cleanParams).length > 0) {
    // Track as a campaign event
    trackEvent('Campaign', 'Visit', utmParams.utm_campaign);

    // Track source separately
    if (utmParams.utm_source) {
      trackEvent('Visit', 'Source', utmParams.utm_source);
    }

    // Set campaign data in GA
    ReactGA.set({
      ...cleanParams,
      // Add any additional campaign-related dimensions here
    });
  }
  // Clean UTM parameters from URL to prevent duplicate tracking
  const newParams = new URLSearchParams(searchParams);
  [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'source',
    'ref',
    'utm_id',
  ].forEach((param) => newParams.delete(param));

  // Only update URL if we removed parameters
  if (newParams.toString() !== searchParams.toString()) {
    const newUrl = window.location.pathname + (newParams.toString() ? `?${newParams}` : '');
    window.history.replaceState({}, '', newUrl);
  }
}

/**
 * Track user timing
 * @param category - Timing category
 * @param variable - Timing variable
 * @param value - Time in milliseconds
 * @param label - Optional label
 */
export function trackTiming(category: string, variable: string, value: number, label?: string) {
  if (!isProd()) return;

  ReactGA.send({
    hitType: 'timing',
    timingCategory: category,
    timingVar: variable,
    timingValue: value,
    timingLabel: label,
  });
}

/**
 * Set user ID for cross-device tracking
 * @param userId - Unique, non-personally identifiable user ID
 */
export function setUserId(userId: string) {
  if (!isProd()) return;

  ReactGA.set({ userId });
}

/**
 * Helper to measure time spent on page/feature
 * Returns a function that when called will track the elapsed time
 * @param category - Timing category
 * @param variable - Timing variable
 * @param label - Optional label
 */
export function startTimeTracking(category: string, variable: string, label?: string) {
  const startTime = Date.now();

  return () => {
    const timeSpent = Date.now() - startTime;
    trackTiming(category, variable, timeSpent, label);
    return timeSpent;
  };
}
