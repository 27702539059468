import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { UserWithOrganization } from '@/types/api/response/user';
import { Loader2, Pencil, UserX } from 'lucide-react';
import { useState } from 'react';

interface AssigneeSelectorProps {
  users: UserWithOrganization[];
  assigneeId?: number;
  assigneeName?: string;
  onAssigneeChange: (userId: number | null) => void;
  triggerClassName?: string;
  loading?: boolean;
}

export const AssigneeSelector = ({
  users,
  assigneeId,
  assigneeName = 'Unassigned',
  onAssigneeChange,
  triggerClassName = 'cursor-pointer text-left',
  loading = false,
}: AssigneeSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const handleAssigneeSelect = (userId: number | null) => {
    if (userId === null) {
      onAssigneeChange(null);
      setIsOpen(false);
      return;
    }
    const selectedUser = users.find((user) => user.id === userId);
    if (selectedUser) {
      onAssigneeChange(userId);
      setIsOpen(false);
    }
  };

  const isUnassigned = assigneeName === 'Unassigned';

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger
        className={triggerClassName}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <div className="flex flex-row items-center gap-2 group">
          <span
            className={`w-full font-medium text-sm text-center rounded-full ${isUnassigned ? 'px-2 py-1 text-gray-500 border border-gray-200 bg-gray-50 hover:bg-gray-100 hover:text-gray-600' : ''}`}
          >
            {assigneeName}
          </span>
          <Pencil className="h-5 w-5 text-muted-foreground group-hover:opacity-100 opacity-0 transition-opacity duration-200" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-fit min-w-[200px] p-0 text-sm border border-gray-300"
        data-click-bypass="true"
      >
        <div className="flex flex-col max-h-[200px] overflow-y-auto">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          ) : (
            <>
              {!isUnassigned && (
                <button
                  className="p-2 text-left hover:bg-gray-100 text-gray-900 border-b border-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAssigneeSelect(null);
                  }}
                >
                  <div className="flex items-center gap-2 text-red-500">
                    <UserX className="h-4 w-4" />
                    <span>Unassign</span>
                  </div>
                </button>
              )}
              {users
                .filter((user) => user.user.first_name && user.user.last_name)
                .map((user) => (
                  <button
                    key={user.id}
                    className={`p-2 text-left hover:bg-gray-100 text-gray-900 ${
                      user.id === assigneeId ? 'bg-gray-50' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAssigneeSelect(user.id);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <span>
                        {user.user.first_name &&
                          user.user.last_name &&
                          `${user.user.first_name} ${user.user.last_name}`}
                      </span>
                    </div>
                  </button>
                ))}
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
