import { SuggestionsSelector } from '@/components/common/SuggestionsSelector';
import { getAssetSuggestions, getDomainSuggestions } from '../../GridComponents/GridAPI';
import { getIPSuggestions } from '../../api/ipaddress.api';
import { Globe, Network } from 'lucide-react';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';

interface ResourceSelectorProps {
  placeholderText?: string;
  selectedResourceType: string | null;
  selectedResource: any;
  onResourceSelect: (resourceType: string | null, resource: any) => void;
  onClear: () => void;
  loading?: boolean;
  isEditable?: boolean;
}

export const getResourceDisplayValue = (resource: any, resourceType: string) => {
  if (!resource) return null;
  if (resourceType === 'Domains') {
    return (
      <LinkWithAction
        to={`/assets/domains?domain_search_value=${resource.domainname}`}
        dataClickBypass={true}
        title="View Domain Details"
        actions={[]}
        showAsButton={true}
        buttonIcon={<Globe className="h-4 w-4" />}
        target={'_self'}
      >
        {resource.domainname}
      </LinkWithAction>
    );
  } else if (resourceType === 'HTTPAsset') {
    const hostname = resource.domain?.domainname || resource.hostname || 'Unknown Host';
    const port = resource.port || '80';
    return (
      <LinkWithAction
        to={`/assets/${resource.id}?from=/issues`}
        dataClickBypass={true}
        title="View Asset Details"
        actions={[]}
        showAsButton={true}
        buttonIcon={<Globe className="h-4 w-4" />}
        target={'_self'}
      >
        {`${hostname}:${port}`}
      </LinkWithAction>
    );
  } else if (resourceType === 'IPAddressV4') {
    if (!resource.ipaddress) return;
    return (
      <LinkWithAction
        to={`/ipaddress/${resource.ipaddress}?from=/issues`}
        dataClickBypass={true}
        title="View IP Address Details"
        actions={[]}
        showAsButton={true}
        buttonIcon={<Network className="h-4 w-4" />}
        target={'_self'}
      >
        {resource.ipaddress}
      </LinkWithAction>
    );
  }
};

const getResourceDisplaySuggestion = (resource: any, resourceType: string) => {
  if (resourceType === 'HTTPAsset') {
    const hostname = resource.domain?.domainname || resource.hostname || 'Unknown Host';
    const port = resource.port || '80';
    return `${hostname}:${port}`;
  } else if (resourceType === 'Domains') {
    return resource.domainname || 'No valid entry';
  } else if (resourceType === 'IPAddressV4') {
    return resource.ipaddress || 'No valid IP';
  }
};

const fetchResourceSuggestions = async (resourceType: string, query: string) => {
  if (resourceType === 'HTTPAsset') {
    return await getAssetSuggestions(query, 'startswith');
  } else if (resourceType === 'Domains') {
    return await getDomainSuggestions(query, 'startswith');
  } else if (resourceType === 'IPAddressV4') {
    return await getIPSuggestions(query);
  }
  return [];
};

export const ResourceSelector = ({
  selectedResourceType,
  selectedResource,
  onResourceSelect,
  onClear,
  loading,
  placeholderText,
  isEditable,
}: ResourceSelectorProps) => {
  const resourceTypes = ['HTTPAsset', 'Domains', 'IPAddressV4'];

  return (
    <SuggestionsSelector
      placeholderText={placeholderText || 'Affected Resource'}
      modelTypes={resourceTypes}
      selectedModelType={selectedResourceType}
      selectedItem={selectedResource}
      onSelect={onResourceSelect}
      onClear={onClear}
      fetchSuggestions={fetchResourceSuggestions}
      getDisplayValue={getResourceDisplayValue}
      getDisplaySuggestion={getResourceDisplaySuggestion}
      loading={loading}
      isEditable={isEditable}
    />
  );
};
