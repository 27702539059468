import { useIPAddressV4Detail } from '@/v2/queries/ipAddress';
import { useParams } from 'react-router-dom';
import { IPAddressDashboard } from './IPDashboard';
import { IPAddressDashboardSkeleton } from './IPDashboardSkeleton';
import { BackButton } from '@/components/common/BackButton';
import { Helmet } from 'react-helmet';

export const IPAdressDetailsView = () => {
  const { id } = useParams();
  const { data, isLoading } = useIPAddressV4Detail(id);

  if (isLoading) return <IPAddressDashboardSkeleton />;

  return (
    <div className="flex flex-col gap-2 px-6">
      <Helmet>
        <title>{`IP Address > ${data.ipaddress ?? ''}`}</title>
      </Helmet>
      <BackButton />
      <IPAddressDashboard data={data} />
    </div>
  );
};
