interface RemediationAIProps {
  className?: string;
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const RemediationAI = ({
  className,
  width = 128,
  height = 128,
  fill = 'currentColor',
}: RemediationAIProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width={width}
      height={height}
      className={className}
      fill={fill}
    >
      <path d="M60.26 93H31.16C28.9958 93 26.9203 92.1403 25.39 90.61C23.8597 89.0797 23 87.0042 23 84.84V33.16C23 30.9959 23.8597 28.9203 25.39 27.39C26.9203 25.8597 28.9958 25 31.16 25H82.84C85.0042 25 87.0797 25.8597 88.61 27.39C90.1403 28.9203 91 30.9959 91 33.16V59C91.0183 59.5456 91.2513 60.0618 91.6484 60.4364C92.0454 60.8109 92.5743 61.0135 93.12 61C94.8228 60.9949 96.5036 61.3851 98.03 62.14C98.3468 62.3026 98.7004 62.3802 99.0562 62.3654C99.412 62.3505 99.7578 62.2437 100.06 62.0554C100.362 61.867 100.61 61.6035 100.78 61.2906C100.95 60.9777 101.036 60.6261 101.03 60.27V33.16C101.03 30.7727 100.559 28.4088 99.6448 26.2036C98.7303 23.9983 97.3899 21.9951 95.7005 20.3083C94.011 18.6216 92.0055 17.2846 89.7987 16.3738C87.592 15.4629 85.2273 14.9961 82.84 15H31.16C26.3437 15 21.7246 16.9133 18.3189 20.319C14.9133 23.7246 13 28.3437 13 33.16V84.84C13 89.6564 14.9133 94.2754 18.3189 97.6811C21.7246 101.087 26.3437 103 31.16 103H64.46C64.8692 103.001 65.2693 102.88 65.6099 102.653C65.9505 102.426 66.2162 102.104 66.3735 101.726C66.5308 101.348 66.5726 100.932 66.4937 100.531C66.4148 100.129 66.2186 99.7601 65.93 99.47C64.374 98.0425 63.1135 96.3233 62.22 94.41C62.0768 94.0027 61.8121 93.6491 61.4616 93.397C61.1111 93.1448 60.6917 93.0063 60.26 93Z" />
      <path d="M104.73 96.8L113.79 93.23C113.977 93.1563 114.137 93.0282 114.249 92.8624C114.362 92.6965 114.422 92.5006 114.422 92.3C114.422 92.0995 114.362 91.9035 114.249 91.7377C114.137 91.5718 113.977 91.4437 113.79 91.37L104.73 87.8C103.126 87.1659 101.67 86.2095 100.45 84.99C99.2305 83.7705 98.2741 82.3138 97.64 80.71L94.07 71.71C93.9959 71.524 93.8676 71.3644 93.7018 71.2521C93.536 71.1398 93.3403 71.0798 93.14 71.08C92.9397 71.0798 92.744 71.1398 92.5782 71.2521C92.4124 71.3644 92.2841 71.524 92.21 71.71L88.64 80.71C88.0074 82.3147 87.0516 83.7722 85.8319 84.9919C84.6122 86.2116 83.1547 87.1674 81.55 87.8L72.55 91.37C72.3635 91.4437 72.2034 91.5718 72.0907 91.7377C71.9779 91.9035 71.9176 92.0995 71.9176 92.3C71.9176 92.5006 71.9779 92.6965 72.0907 92.8624C72.2034 93.0282 72.3635 93.1563 72.55 93.23L81.55 96.8C83.1547 97.4326 84.6122 98.3884 85.8319 99.6081C87.0516 100.828 88.0074 102.285 88.64 103.89L92.21 112.89C92.2825 113.078 92.4101 113.24 92.576 113.354C92.742 113.468 92.9386 113.529 93.14 113.53C93.3414 113.529 93.538 113.468 93.7039 113.354C93.8699 113.24 93.9975 113.078 94.07 112.89L97.64 103.89C98.2741 102.286 99.2305 100.83 100.45 99.61C101.67 98.3905 103.126 97.4342 104.73 96.8Z" />
      <path d="M62.5535 45.8695C62.0907 46.3416 61.8315 46.9764 61.8315 47.6375C61.8315 48.2986 62.0907 48.9333 62.5535 49.4054L66.5946 53.4465C67.0667 53.9093 67.7014 54.1685 68.3625 54.1685C69.0236 54.1685 69.6584 53.9093 70.1305 53.4465L79.6522 43.9248C80.9222 46.7312 81.3067 49.8581 80.7545 52.8887C80.2024 55.9192 78.7397 58.7096 76.5615 60.8878C74.3833 63.066 71.593 64.5286 68.5624 65.0808C65.5318 65.633 62.405 65.2485 59.5985 63.9785L42.1462 81.4308C41.1414 82.4355 39.7787 83 38.3577 83C36.9368 83 35.574 82.4355 34.5692 81.4308C33.5645 80.426 33 79.0632 33 77.6423C33 76.2213 33.5645 74.8586 34.5692 73.8538L52.0215 56.4015C50.7515 53.595 50.367 50.4681 50.9192 47.4376C51.4714 44.407 52.934 41.6167 55.1122 39.4385C57.2904 37.2603 60.0807 35.7976 63.1113 35.2455C66.1419 34.6933 69.2687 35.0778 72.0752 36.3478L62.5788 45.8443L62.5535 45.8695Z" />
    </svg>
  );
};

export default RemediationAI;
