import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Building, Shield, User } from 'lucide-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import OrganizationSettings from './OrganizationSettings';
import SecuritySettings from './SecuritySettings';
import UserSettings from './UserSettings';
import { BackButton } from '@/components/common/BackButton';

export default function Settings() {
  const [activeTab, setActiveTab] = useState<string>('user');

  return (
    <div className="min-h-screen mb-8">
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <div className="mx-6">
        <BackButton />
      </div>

      <Tabs defaultValue="user" value={activeTab} onValueChange={setActiveTab} className="w-full">
        <div className="border-b">
          <div className="container flex justify-between pb-2 px-4">
            <TabsList className="h-10">
              <TabsTrigger value="user" className="flex items-center px-4">
                <User className="h-4 w-4 mr-2" />
                User
              </TabsTrigger>
              <TabsTrigger value="organization" className="flex items-center px-4">
                <Building className="h-4 w-4 mr-2" />
                Organization
              </TabsTrigger>
              <TabsTrigger value="security" className="flex items-center px-4">
                <Shield className="h-4 w-4 mr-2" />
                Security
              </TabsTrigger>
            </TabsList>
          </div>
        </div>

        <TabsContent value="user" className="mt-0 pt-6">
          <UserSettings />
        </TabsContent>

        <TabsContent value="organization" className="mt-0 pt-6">
          <OrganizationSettings />
        </TabsContent>

        <TabsContent value="security" className="mt-0 pt-6">
          <SecuritySettings />
        </TabsContent>
      </Tabs>
    </div>
  );
}
