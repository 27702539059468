import { UpdateIssuePayload } from '@/api/issues.api';
import { BackButton } from '@/components/common/BackButton';
import { ContactSupport } from '@/components/common/ContactSupport';
import { Ellipsis } from '@/components/common/Ellipsis';
import { JiraIcon } from '@/components/icons/JiraIcon';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { toast } from '@/hooks/use-toast';
import { useAttachedImages } from '@/hooks/useAttachedImages';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { cn } from '@/lib/utils';
import { Issue } from '@/types/api/response/issues';
import { NVDVulnerability } from '@/types/api/response/nvd';
import ExpertChat from '@/v2/components/AIChat/ExpertChat';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { getSeverityDisplay } from '@/v2/components/SeverityBadge/SeverityBadge';
import {
  useDeleteIssueCache,
  useDeleteIssueMutation,
  useGetIssue,
  useUpdateIssueMutation,
  useUpdateIssuesCache,
} from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { formatShortDateTime } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import {
  Calendar,
  Clock,
  Globe,
  Loader2,
  MoreHorizontal,
  MoreHorizontalIcon,
  Network,
  Pencil,
  Send,
  Sparkles,
  Trash,
} from 'lucide-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { AssigneeSelector } from './AssigneeSelector';
import { StatusSelector } from './StatusSelector';

// Define a type for Jira ticket information
export interface JiraTicket {
  id: string;
  key: string;
  url: string;
}

// Extend the Issue type to include jira_ticket
interface IssueWithJira extends Issue {
  jira_ticket?: JiraTicket;
}

// Extend the UpdateIssuePayload to include jira_ticket
interface UpdateIssuePayloadWithJira extends UpdateIssuePayload {
  jira_ticket?: JiraTicket;
}

const IssueDetails = () => {
  const { id } = useParams();
  const { mutate: updateIssue } = useUpdateIssueMutation();
  const { mutate: deleteIssue, isPending: isDeleting } = useDeleteIssueMutation();
  const { data: issue, isPending: loading } = useGetIssue(Number(id));
  const { data: users, isPending: loadingUsers } = useQuery(getUsersQuery());
  const navigate = useNavigate();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const { addImage, resetImages, imageMap } = useAttachedImages();
  const [isChatVisible, setIsChatVisible] = useState(true);
  const updateIssuesCache = useUpdateIssuesCache();
  const deleteIssueCache = useDeleteIssueCache();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const { navigateWithParams } = useNavigateWithParams();

  const handleChatVisibilityChange = (isVisible: boolean) => {
    setIsChatVisible(isVisible);
  };
  const shortVulnerability =
    issue && issue.resource_vulnerabilities?.length > 0
      ? {
          name: issue.resource_vulnerabilities[0]?.vulnerability_name ?? '',
          id: issue.resource_vulnerabilities[0]?.id ?? 0,
          type: issue.resource_vulnerabilities[0]?.vulnerability_type ?? '',
          references: issue.resource_vulnerabilities[0]?.vulnerability?.references ?? [],
        }
      : {
          name: issue?.vulnerability?.cve_id ?? '',
          id: Number(issue?.vulnerability?.id) ?? 0,
          type: issue?.vuln_model_type ?? '',
          references: issue?.vulnerability?.references ?? [],
        };

  const handleImageUpload = async (file: File) => {
    const url = await addImage(file);
    return url;
  };

  const handlePublish = () => {
    handleUpdate({ status: 'new' });
    updateIssuesCache({ ...issue, status: 'new' });
    toast({
      title: 'Issue published',
      description: (
        <div className="flex flex-col gap-1 items-start">
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">#{issue.issue_number}</span>
            <span>—</span>
            <span className="text-sm text-muted-foreground">
              {<Ellipsis text={issue.title} maxLength={30} />}
            </span>
          </div>
          <Button
            variant="link"
            className="p-0 h-auto font-normal text-left text-blue-500 hover:text-blue-600 hover:no-underline w-fit"
            onClick={() => navigate(`/issues/${issue.issue_number}`)}
          >
            View issue
          </Button>
        </div>
      ),
      duration: 5000,
    });
    navigate('/issues');
  };

  const handleDelete = () => {
    deleteIssue(issue.issue_number);
    setShowConfirmDelete(false);
    deleteIssueCache(issue.issue_number);
    toast({
      title: 'Issue deleted',
      description: (
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">#{issue.issue_number}</span>
            <span>—</span>
            <span className="text-sm text-muted-foreground">{issue.title}</span>
          </div>
        </div>
      ),
      duration: 5000,
    });
    navigate('/issues');
  };

  const handleUpdate = async (
    updates: Partial<UpdateIssuePayloadWithJira & { vulnerability: NVDVulnerability | null }>,
  ) => {
    if (!issue) return;
    try {
      const updatesWithScreenshots: Partial<UpdateIssuePayloadWithJira> =
        Object.keys(imageMap).length > 0
          ? { ...updates, _uploaded_screenshots: imageMap }
          : updates;

      updateIssue({
        issueId: issue.issue_number,
        updates: {
          ...issue,
          ...updatesWithScreenshots,
        } as any, // Type assertion to avoid type error
      });

      resetImages();
    } catch (err) {
      console.error('Failed to update issue:', err);
    }
  };

  // Handle Jira ticket creation success
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleJiraSuccess = (ticket: JiraTicket) => {
    // Update the issue with the Jira ticket information
    handleUpdate({
      jira_ticket: {
        id: ticket.id,
        key: ticket.key,
        url: ticket.url,
      },
    });

    // Update the cache to reflect the change
    updateIssuesCache({
      ...issue,
      jira_ticket: {
        id: ticket.id,
        key: ticket.key,
        url: ticket.url,
      },
    } as IssueWithJira);
  };

  const handleTitleBlur = () => {
    handleUpdate({ title: editedTitle });
    setIsEditingTitle(false);
  };

  const handleDescriptionBlur = () => {
    handleUpdate({ description: editedDescription });
    setIsEditingDescription(false);
  };

  const cancelDescriptionEdit = () => {
    setIsEditingDescription(false);
    setEditedDescription(issue.description);
  };

  const getAffectedResourceDisplay = (issue: Issue) => {
    if (!issue.resource_vulnerabilities[0]) return '-';
    const affectedResource = issue.resource_vulnerabilities[0].http_asset;
    const resourceType = issue.resource_vulnerabilities[0].resource_type;
    switch (resourceType) {
      case 'ipaddressv4':
        return (
          <LinkWithAction
            showAsButton={true}
            buttonIcon={<Network className="h-4 w-4" />}
            to={navigateWithParams(
              'ipaddress',
              affectedResource.ipaddress,
              `issues/${issue.issue_number}`,
            )}
            dataClickBypass={true}
            title="View IP Address"
            target="_self"
            actions={[]}
          >
            <Ellipsis text={affectedResource?.ipaddress ?? '-'} maxLength={25} />
          </LinkWithAction>
        );
      case 'httpasset':
        return (
          <LinkWithAction
            to={navigateWithParams(
              'assets',
              affectedResource.id.toString(),
              `issues/${issue.issue_number}`,
            )}
            buttonIcon={<Globe className="h-4 w-4" />}
            showAsButton={true}
            dataClickBypass={true}
            title="View Asset"
            target="_self"
            actions={[]}
          >
            <Ellipsis text={affectedResource?.hostname ?? '-'} maxLength={25} />
          </LinkWithAction>
        );
      case 'domains':
        return (
          <LinkWithAction
            showAsButton={true}
            buttonIcon={<Globe className="h-4 w-4" />}
            to={navigateWithParams(
              `/assets/domains?domain_search_value=${affectedResource.domainname}`,
              '',
              `issues/${issue.issue_number}`,
            )}
            dataClickBypass={true}
            title="View Domain"
            target="_self"
            actions={[]}
          >
            <Ellipsis text={affectedResource?.domainname ?? '-'} maxLength={25} />
          </LinkWithAction>
        );
      default:
        return '-';
    }
  };

  function getVulnURL(vulnerability: Issue['resource_vulnerabilities'][0], from: string) {
    return vulnerability
      ? `/investigation/${vulnerability?.id}?from=${from}&type=${vulnerability.vulnerability_type === 'nucleitemplates' ? 'nuclei' : 'passive'}`
      : `/vulnerabilities/${vulnerability?.vulnerability_name}?from=${from}`;
  }

  const issueDetailsV3 = () => {
    return (
      <div
        className={`container mr-auto ${isChatVisible ? 'max-w-[calc(100vw-600px)]' : 'max-w-[calc(100vw-400px)]'} p-2`}
      >
        <div className="relative">
          <Card className="border-0 shadow-none">
            <CardHeader className="p-0 mb-6">
              <div className="flex flex-row items-center justify-between">
                <div className="space-y-1">
                  <CardTitle className="flex flex-row items-center gap-4 text-xl font-semibold text-gray-800 mb-2">
                    {isEditingTitle ? (
                      <Input
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        onBlur={handleTitleBlur}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleTitleBlur();
                          }
                        }}
                        className="text-xl font-semibold h-auto py-1 w-[600px]"
                        autoFocus
                      />
                    ) : (
                      <h1
                        className="text-xl font-semibold cursor-pointer hover:text-muted-foreground transition-colors"
                        onClick={() => {
                          setEditedTitle(issue.title);
                          setIsEditingTitle(true);
                        }}
                      >
                        <Ellipsis text={issue.title} maxLength={100} />
                      </h1>
                    )}
                    <div className="flex flex-wrap gap-2 mt-2">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="outline" size="sm" className="h-8 px-2">
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start">
                          {issue.status === 'draft' && (
                            <DropdownMenuItem className="cursor-pointer" onClick={handlePublish}>
                              <Send className="h-4 w-4 mr-2" />
                              Publish
                            </DropdownMenuItem>
                          )}
                          <DropdownMenuItem
                            className="text-destructive cursor-pointer"
                            onClick={() => setShowConfirmDelete(true)}
                          >
                            <Trash className="h-4 w-4 mr-2" />
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </CardTitle>
                </div>
              </div>
            </CardHeader>
            <CardContent className="p-0">
              <div className="space-y-8">
                {/* Key details in a simple grid */}
                <div className="grid grid-cols-3 2xl:grid-cols-4 gap-y-4">
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-1">Issue ID</h3>
                    <div className="text-sm font-medium">{issue.issue_number}</div>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-1">Severity</h3>
                    {getSeverityDisplay({
                      severity: issue.severity,
                      showLabel: true,
                      showIcon: true,
                    })}
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-1">Status</h3>
                    <StatusSelector
                      currentStatus={issue.status}
                      onStatusChange={(status) => handleUpdate({ status })}
                    />
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-1">Assignee</h3>
                    <AssigneeSelector
                      users={users?.entries}
                      assigneeId={issue.assigned_to?.user?.id}
                      assigneeName={
                        issue.assigned_to?.user
                          ? `${issue.assigned_to.user.first_name} ${issue.assigned_to.user.last_name}`
                          : 'Unassigned'
                      }
                      onAssigneeChange={(userId: number | null) => {
                        handleUpdate({
                          assigned_to_id: userId ?? null,
                        });
                      }}
                      loading={loadingUsers}
                    />
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-2">Resource</h3>
                    {getAffectedResourceDisplay(issue)}
                  </div>

                  <div className="">
                    <h3 className="text-sm font-medium text-muted-foreground mb-2">
                      Vulnerability
                    </h3>
                    {issue.resource_vulnerabilities.length > 0
                      ? issue.resource_vulnerabilities.map((vuln, index) => {
                          return (
                            <LinkWithAction
                              to={getVulnURL(vuln, `issues/${issue.issue_number}`)}
                              dataClickBypass={true}
                              title="View Vulnerability Details"
                              actions={[]}
                              showAsButton={true}
                              buttonIcon={<Globe className="h-4 w-4" />}
                              target={'_self'}
                              key={index}
                            >
                              <Ellipsis text={vuln.vulnerability_name} maxLength={30} />
                            </LinkWithAction>
                          );
                        })
                      : '-'}
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-2">Created</h3>
                    <div className="flex items-center gap-2">
                      <span className="text-sm">{formatShortDateTime(issue.created_at)}</span>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground mb-2">Updated</h3>
                    <div className="flex items-center gap-2">
                      <span className="text-sm">{formatShortDateTime(issue.updated_at)}</span>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium mb-2 text-muted-foreground">Jira Ticket</h3>
                    {issue.jira_ticket_url ? (
                      <LinkWithAction
                        to={issue.jira_ticket_url}
                        dataClickBypass={true}
                        title="View in Jira"
                        actions={[]}
                        showAsButton={true}
                        buttonIcon={<JiraIcon className="h-4 w-4" />}
                        target="_blank"
                      >
                        {issue.jira_ticket_id}
                      </LinkWithAction>
                    ) : (
                      <ContactSupport
                        text="Contact our support team at support@specular.ai"
                        buttonText="Create Jira Ticket"
                        buttonClassName="w-fit"
                        buttonVariant="outline"
                        showIcon={false}
                        title="Ticketing Solution Integrations"
                        buttonIcon={<JiraIcon className="h-4 w-4" />}
                        className="p-0 border-none"
                      />
                    )}
                  </div>
                </div>

                {/* Description */}
                <div className="-mt-2">
                  <div
                    className={cn(
                      'flex justify-between items-center py-2 bg-background',
                      isEditingDescription && 'sticky top-0 z-50',
                    )}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <h3 className="text-sm font-medium text-muted-foreground">Description</h3>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="text-muted-foreground"
                        onClick={() => {
                          if (isEditingDescription) {
                            cancelDescriptionEdit();
                            return;
                          }
                          setEditedDescription(issue.description);
                          setIsEditingDescription(true);
                        }}
                      >
                        <Pencil className="h-4 w-4" />
                      </Button>
                    </div>

                    {isEditingDescription && (
                      <div className={'flex items-center gap-2 transition-opacity'}>
                        <Button size="sm" variant="outline" onClick={cancelDescriptionEdit}>
                          Cancel
                        </Button>

                        <Button size="sm" onClick={handleDescriptionBlur}>
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                  {isEditingDescription ? (
                    <MarkdownEditor
                      className="issue-details-editor"
                      markdown={editedDescription}
                      onChange={(markdown) => setEditedDescription(markdown)}
                      imageUploadHandler={handleImageUpload}
                      autoFocus
                      embedded
                    />
                  ) : (
                    <div className="rounded-lg border p-4 overflow-hidden">
                      <MarkdownViewer markdown={issue.description} className="-mt-6" />
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
          {isChatVisible && (
            <>
              <ExpertChat
                context={[
                  { type: 'issue', id: issue.issue_number.toString(), name: issue.title },
                  {
                    type: 'vulnerability',
                    id: shortVulnerability?.id.toString(),
                    name: shortVulnerability?.name,
                    from: `issues/${issue.issue_number}`,
                    url:
                      issue.resource_vulnerabilities?.length > 0
                        ? `/investigation/${shortVulnerability?.id}?from=issues/${issue.issue_number}&type=${issue.resource_vulnerabilities[0]?.vulnerability_type === 'nucleitemplates' ? 'nuclei' : 'passive'}`
                        : `/vulnerabilities/${shortVulnerability?.name}?from=issues/${issue.issue_number}`,
                    title: 'View Vulnerability Details',
                  },
                ]}
                chatId={`issue:${issue.issue_number}`}
                title={issue.title}
                onClose={() => handleChatVisibilityChange(false)}
                onOpen={() => handleChatVisibilityChange(true)}
              />
            </>
          )}
          {!isChatVisible && (
            <div className="fixed right-2 bottom-0 h-full flex items-center group">
              <div className="bg-v2-orange border border-v2-orange/50 p-2 rounded-l-lg shadow-lg flex flex-col gap-2">
                <Button
                  variant="ghost"
                  aria-label="Restore chat"
                  className="group-hover:brightness-110 transition-all hover:bg-v2-orange/50"
                  onClick={() => handleChatVisibilityChange(true)}
                >
                  <Sparkles className="h-8 w-8 text-white" />
                  <span className="text-white font-medium text-sm">Specular AI</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="bg-background">
        <div className="px-6 container mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <BackButton />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-[1fr,400px] gap-6">
            <div className="space-y-6 min-w-0 h-full">
              <Card className="h-full">
                <CardHeader>
                  <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-6">
                    <div>
                      <div className="flex items-center gap-3 space-y-4 mb-8">
                        <Skeleton className="h-10 w-full" />
                      </div>
                      <div className="space-y-4">
                        <h3 className="text-sm font-medium mb-2 text-muted-foreground">Severity</h3>
                        <Skeleton className="h-10 w-72" />
                      </div>
                      <div>
                        <h3 className="text-sm font-medium mb-2 mt-4 text-muted-foreground">
                          Resource
                        </h3>
                        <Skeleton className="h-10 w-72" />
                      </div>
                      <div>
                        <h3 className="text-sm font-medium mb-2 mt-4 text-muted-foreground">
                          Vulnerability
                        </h3>
                        <Skeleton className="h-10 w-72" />
                      </div>
                    </div>
                    <div className="space-y-6">
                      <div className="flex items-end justify-end w-full">
                        <MoreHorizontalIcon className="text-muted-foreground" />
                      </div>
                      <Card>
                        <CardContent className="pt-6">
                          <div className="space-y-4">
                            <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                              <h3 className="text-sm font-medium text-muted-foreground">
                                Issue ID
                              </h3>
                              <Skeleton className="h-10 w-32" />
                            </div>
                            <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                              <h3 className="text-sm font-medium text-muted-foreground">
                                Assignee
                              </h3>
                              <Skeleton className="h-10 w-32" />
                            </div>
                            <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                              <h3 className="text-sm font-medium text-muted-foreground">Status</h3>
                              <Skeleton className="h-10 w-32" />
                            </div>

                            <Separator />
                            <div className="space-y-2 text-sm text-muted-foreground">
                              <div className="flex items-center gap-2">
                                <Clock className="h-4 w-4" />
                                <Skeleton className="h-5 w-32" />
                              </div>
                              <div className="flex items-center gap-2">
                                <Calendar className="h-4 w-4" />
                                <Skeleton className="h-5 w-32" />
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-sm font-medium text-muted-foreground">Description</h3>
                    </div>
                    <Skeleton className="h-[200px] w-full" />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full bg-background">
      <div className="px-6 mx-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <BackButton />
          </div>
        </div>
        <Helmet>
          <title>{`Issues > ${issue ? issue.title : ''}`}</title>
        </Helmet>
        {issueDetailsV3()}
        <AlertDialog open={showConfirmDelete} onOpenChange={setShowConfirmDelete}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Issue Deletion</AlertDialogTitle>
              <AlertDialogDescription>
                <p className="text-sm text-muted-foreground">
                  This action cannot be undone. Are you sure you want to permanently delete this
                  issue?
                </p>
                <Card className="mt-4 border-muted">
                  <CardHeader className="flex items-start text-sm">
                    <Ellipsis text={issue.title} maxLength={50} />
                  </CardHeader>
                  <CardContent className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <h3 className="text-sm font-medium text-muted-foreground">Issue ID:</h3>
                      <span className="text-sm">{issue.issue_number}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <h3 className="text-sm font-medium text-muted-foreground">Severity:</h3>
                      <span className="text-sm">
                        {getSeverityDisplay({
                          severity: issue.severity,
                          showLabel: true,
                          showIcon: false,
                        })}
                      </span>
                    </div>
                  </CardContent>
                </Card>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="flex justify-end gap-2">
              <AlertDialogCancel onClick={() => setShowConfirmDelete(false)}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDelete}
                className="bg-destructive text-white hover:bg-red-700"
              >
                {isDeleting ? <Loader2 className="h-4 w-4 animate-spin" /> : 'Delete'}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
};

export default IssueDetails;
