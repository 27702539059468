import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { ChevronDown, Pencil, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { Skeleton } from '../ui/skeleton';

interface SuggestionsSelectorProps<T> {
  modelTypes: string[];
  selectedModelType: string | null;
  selectedItem: T | null;
  onSelect: (modelType: string | null, item: T | null) => void;
  onClear: () => void;
  fetchSuggestions: (modelType: string, query: string) => Promise<T[]>;
  getDisplayValue: (item: T, modelType: string, target?: string) => React.ReactNode;
  getDisplaySuggestion: (item: T, modelType: string) => React.ReactNode;
  placeholderText?: string;
  loading?: boolean;
  target?: string;
  isEditable?: boolean;
}

export function SuggestionsSelector<T>({
  modelTypes,
  selectedModelType,
  selectedItem,
  onSelect,
  onClear,
  fetchSuggestions,
  getDisplayValue,
  getDisplaySuggestion,
  placeholderText = 'Select Type',
  loading = false,
  target = '_self',
  isEditable = true,
}: SuggestionsSelectorProps<T>) {
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [modelType, setModelType] = useState<string | null>(selectedModelType);
  const [isEditing, setIsEditing] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length > 1 && modelType) {
      const data = await fetchSuggestions(modelType, value);
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion: T) => {
    setIsEditing(false);
    onSelect(modelType, suggestion);
    setModelType(modelType);
    setSuggestions([]);
    setInputValue('');
  };

  const handleModelTypeSelect = (modelType: string) => {
    onSelect(modelType, null);
    setModelType(modelType);
    setInputValue('');
    setSuggestions([]);
  };

  const handleEdit = () => {
    setIsEditing(true);
    onSelect(selectedModelType, null);
    if (selectedItem) {
      const displayText = getDisplaySuggestion(selectedItem, selectedModelType!).toString();
      setInputValue(displayText);
    }
    setSuggestions([]);
  };

  const handleRemove = () => {
    onClear();
    setSuggestions([]);
    setModelType(null);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    if (selectedItem) {
      onSelect(selectedModelType, selectedItem);
    }
    setModelType(selectedModelType);
    setInputValue('');
    setSuggestions([]);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      // Check if the click is inside the component or inside any dropdown menu
      const isInsideComponent = componentRef.current && componentRef.current.contains(target);
      const isInsideDropdown = !!document.querySelector('[role="menu"]')?.contains(target);

      if (!isInsideComponent && !isInsideDropdown && isEditing) {
        handleCancelEdit();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, selectedItem, selectedModelType]);

  return (
    <div className={`flex flex-col space-y-2`} ref={componentRef}>
      {selectedItem && !isEditing ? (
        <div className="flex items-center group w-fit">
          <div className="flex-grow rounded">
            <span className="text-black dark:text-white flex items-center justify-start">
              {loading ? (
                <Skeleton className="w-[150px] h-[36px] animate-pulse rounded-full" />
              ) : (
                getDisplayValue(selectedItem, selectedModelType!, target!)
              )}
              {!loading && isEditable && (
                <div className="flex items-center gap-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                  <Button variant="ghost" size="icon" onClick={handleEdit}>
                    <Pencil className="h-4 w-4" />
                  </Button>
                </div>
              )}
            </span>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`flex flex-col items-start gap-2`}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                handleCancelEdit();
              }
            }}
          >
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="justify-between bg-black text-white hover:bg-black hover:text-white/80"
                >
                  {modelType ? modelType : placeholderText}
                  <ChevronDown className="ml-2 h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="bottom" align="start" className="w-[160px]">
                {modelTypes.map((type) => (
                  <DropdownMenuItem key={type} onClick={() => handleModelTypeSelect(type)}>
                    {type}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>

            {modelType && (
              <div className="flex flex-col space-y-2 relative w-full">
                <div className="flex flex-row items-center gap-2">
                  <Input
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        setSuggestions([]);
                        setInputValue('');
                      }
                    }}
                    placeholder={`Search ${modelType}`}
                    className="bg-transparent border-gray-500 text-black dark:text-white placeholder:text-gray-500 w-[350px]"
                  />

                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleRemove}
                    className="h-8 w-8 p-0"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
                {inputValue && suggestions.length > 0 && (
                  <div
                    role="listbox"
                    className="text-sm absolute z-50 top-8 w-[300px] bg-white shadow-md rounded-md max-h-40 overflow-y-auto border border-gray-200 overflow-x-hidden"
                    aria-label={`${modelType} suggestions`}
                  >
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        role="option"
                        tabIndex={0}
                        aria-selected={false}
                        className="p-2 cursor-pointer hover:bg-gray-200 text-black focus:bg-gray-200 first:rounded-t-md last:rounded-b-md"
                        onClick={() => handleSuggestionSelect(suggestion)}
                        onKeyDown={(e) => {
                          if (e.key === 'Escape') {
                            setSuggestions([]);
                            setInputValue('');
                          }
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            handleSuggestionSelect(suggestion);
                          }
                        }}
                      >
                        {getDisplaySuggestion(suggestion, modelType)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
