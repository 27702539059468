import { Tip } from '@/components/common/Tip';
import { Button } from '@/components/ui/button';
import { MultiSelect } from '@/components/ui/multi-select';
import useParamState from '@/hooks/useParamState';
import { AssetURL } from '@/types/api/response/assets';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { getAssetURLsOverviewQuery, useGetAssetURLsQuery } from '@/v2/queries/assets';
import { formatShortDateTime } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

interface URLDisplayProps {
  httpAssetId: number;
}

const URLDisplay = ({ httpAssetId }: URLDisplayProps) => {
  const [currentPage, setCurrentPage] = useParamState<number>('urlPage', 1);

  const [selectedTechnology, setSelectedTechnology] = useParamState('urlTechnology', []);
  const [selectedExtension, setSelectedExtension] = useParamState('extension', []);
  const [selectedStatusCode, setSelectedStatusCode] = useParamState('statusCode', []);

  const { data: urlStats, isFetching: loadingStats } = useQuery(
    getAssetURLsOverviewQuery(httpAssetId),
  );

  const {
    data: urls,
    isLoading: urlsLoading,
    error: urlsError,
  } = useGetAssetURLsQuery({
    assetId: httpAssetId,
    page: currentPage,
    technology: selectedTechnology.join(','),
    extension: selectedExtension.join(','),
    statusCode: selectedStatusCode.join(','),
  });

  const columnHelper = createColumnHelper<AssetURL>();

  const TechnologiesList = ({ technologies }: { technologies: string[] }) => {
    const filteredTech = technologies.map((tech) => {
      if (tech.startsWith('Font Awesome:')) {
        return 'Font Awesome';
      }
      return tech;
    });

    const maxLength = 30;
    let displayedTech = '';
    let totalLength = 0;
    const remainingTech = [];

    for (const tech of filteredTech) {
      if (totalLength + tech.length > maxLength) {
        remainingTech.push(tech);
      } else {
        displayedTech += (displayedTech ? ', ' : '') + tech;
        totalLength += tech.length + 2;
      }
    }

    const remainingCount = remainingTech.length;

    const technologiesTip = (
      <div>
        <span className="font-bold">All technologies</span>
        <span>
          {filteredTech.map((tech) => (
            <div key={tech}>
              {tech}
              <br />
            </div>
          ))}
        </span>
      </div>
    );

    return (
      <div className="flex items-center text-xs text-nowrap">
        {displayedTech || '-'}
        {remainingCount > 0 && (
          <Tip content={technologiesTip}>
            <Button
              variant="link"
              className="text-slate-500 px-0 py-0 hover:underline ml-1 text-xs"
            >
              +{remainingCount} more
            </Button>
          </Tip>
        )}
      </div>
    );
  };

  const columns: ColumnDef<AssetURL>[] = [
    columnHelper.accessor('url', {
      header: 'URL',
      cell: ({ row }) => {
        const url = new URL(row.original.url);
        const hostname = url.hostname.replace(/^www\./, '');
        const displayText = hostname + url.pathname + url.search + url.hash;
        return (
          <Link
            target="_blank"
            to={row.original.url}
            className="w-[350px] line-clamp-1 text-sm hover:underline"
          >
            {displayText}
          </Link>
        );
      },
    }),
    columnHelper.accessor('status_code', {
      header: 'Status Code',
      cell: ({ row }) => row.original.status_code,
    }),
    columnHelper.accessor('technologies', {
      header: 'Technologies',
      cell: ({ row }) => <TechnologiesList technologies={row.original.technologies} />,
    }),
    columnHelper.accessor('redirect_url', {
      header: 'Redirect Domain',
      cell: ({ row }) => {
        const redirectUrl = row.original.redirect_url;
        if (!redirectUrl) return 'N/A';
        try {
          return new URL(redirectUrl).hostname;
        } catch (e: unknown) {
          console.error(e);
          return '-';
        }
      },
    }),
    columnHelper.accessor('sources', {
      header: 'Sources',
      cell: ({ row }) => Object.keys(row.original.sources).join(', '),
    }),
    columnHelper.accessor('last_scanned', {
      header: 'Last Seen',
      cell: ({ row }) => (
        <span className="text-xs w-[300px]">{formatShortDateTime(row.original.last_scanned)}</span>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => (
        <Button
          className="text-xs bg-v2-orange text-white"
          onClick={() => window.open(`/http_analyzer?url=${row.original.url}`, '_blank')}
        >
          Analyze
        </Button>
      ),
    }),
  ];

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const onExtensionChange = (value: string[]) => {
    setSelectedExtension(value);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const onStatusCodeChange = (value: string[]) => {
    setSelectedStatusCode(value);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const onTechnologyChange = (value: string[]) => {
    setSelectedTechnology(value);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const mappedTechnologies =
    urlStats?.technology_counts?.map((technology) => ({
      value: technology.technology,
      label: `${technology.technology} (${technology.count})`,
    })) || [];

  const mappedExtensions =
    urlStats?.extension_counts?.map((extension) => ({
      value: extension.extension,
      label: `${extension.extension ? extension.extension : 'Unknown'} (${extension.count})`,
    })) || [];

  const mappedStatusCodes =
    urlStats?.status_code_counts?.map((statusCode) => ({
      value: statusCode.status_code,
      label: `${statusCode.status_code} (${statusCode.count})`,
    })) || [];

  return (
    <div>
      {urlsLoading ? (
        <div className="flex justify-center items-center gap-2 h-full text-muted-foreground text-sm font-medium pb-4">
          <Loader2 className="h-4 w-4 animate-spin" />
          Loading...
        </div>
      ) : urlsError ? (
        <h1 className="text-red-500">{urlsError.message}</h1>
      ) : (
        <>
          {loadingStats ? (
            <div className="flex items-center gap-2 h-full text-muted-foreground text-sm font-medium pb-4">
              <Loader2 className="h-4 w-4 animate-spin" />
              Loading filters...
            </div>
          ) : (
            urlStats && (
              <div className="flex gap-2 mb-6">
                <MultiSelect
                  options={mappedTechnologies}
                  value={selectedTechnology}
                  placeholder="Select Technology"
                  onValueChange={onTechnologyChange}
                  className="w-fit"
                />
                <MultiSelect
                  options={mappedExtensions}
                  value={selectedExtension}
                  placeholder="Select File Extension"
                  onValueChange={onExtensionChange}
                  className="w-fit"
                />
                <MultiSelect
                  options={mappedStatusCodes}
                  value={selectedStatusCode}
                  placeholder="Select Status Code"
                  onValueChange={onStatusCodeChange}
                  className="w-fit"
                />
              </div>
            )
          )}
          {urls?.results.length > 0 ? (
            <DataTable
              columns={columns}
              data={urls?.results ?? []}
              currentPage={currentPage}
              totalPages={urls?.total_pages ?? 0}
              totalEntries={urls?.total_items ?? 0}
              onPageChange={handlePageChange}
              error={urlsError}
              loading={urlsLoading}
              maxWidth="max-w-[1800px]"
              tableHeight="calc(100vh - 19rem)"
            />
          ) : (
            <h1>No Known URLs</h1>
          )}
        </>
      )}
    </div>
  );
};

export default URLDisplay;
