import { fetchFeatureFlagsAPI } from '@/api/feature-flag.api';
import { FeatureFlags, storeFeatureFlag } from '@/common/lib/feature-flags';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, ReactNode, useContext } from 'react';

interface FeatureFlagContextType {
  featureFlags: FeatureFlags;
  setFeatureFlag: (name: string, value: boolean) => void;
  hasFeatureFlag: (name: string) => boolean;
  isLoading: boolean;
  error: Error | null;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined);

interface FeatureFlagProviderProps {
  children: ReactNode;
}

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const queryClient = useQueryClient();

  const {
    data: featureFlags = {},
    isLoading,
    error,
  } = useQuery({
    queryKey: ['featureFlags'],
    queryFn: async () => {
      return {} as FeatureFlags;
    },
  });

  const setFeatureFlag = async (name: string, value: boolean) => {
    await storeFeatureFlag(name, value);
    queryClient.setQueryData(['featureFlags'], (oldData: FeatureFlags = {}) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const hasFeatureFlag = (name: string): boolean => {
    if (featureFlags[name] === undefined) {
      // Start a background fetch if we don't have this flag
      queryClient.prefetchQuery({
        queryKey: ['featureFlag', name],
        queryFn: async () => {
          try {
            const response = await fetchFeatureFlagsAPI(name);
            // Store in cache
            queryClient.setQueryData(['featureFlags'], (oldData: FeatureFlags = {}) => ({
              ...oldData,
              [name]: response.enabled,
            }));
            await storeFeatureFlag(name, response.enabled);
            return response.enabled;
          } catch (error) {
            console.error(`Feature flag ${name} not found or error occurred`, error);
            return false;
          }
        },
      });
    }
    return featureFlags[name] ?? false;
  };

  const value = {
    featureFlags,
    setFeatureFlag,
    hasFeatureFlag,
    isLoading,
    error: error as Error | null,
  };

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
}

export function useFeatureFlags() {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
}

// Custom hook for getting a specific feature flag
export function useFeatureFlag(flagName: string): boolean {
  const queryClient = useQueryClient();

  const { data: isEnabled = null } = useQuery({
    queryKey: ['featureFlag', flagName],
    queryFn: async () => {
      try {
        const response = await fetchFeatureFlagsAPI(flagName);
        // Store in main cache
        queryClient.setQueryData(['featureFlags'], (oldData: FeatureFlags = {}) => ({
          ...oldData,
          [flagName]: Boolean(response.enabled),
        }));
        await storeFeatureFlag(flagName, Boolean(response.enabled));
        return Boolean(response.enabled);
      } catch (error) {
        console.error(`Feature flag ${flagName} not found or error occurred:`, error);
        return false;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  return isEnabled;
}
