import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Note } from '@/types/api/response/notes';
import { formatShortDateTime } from '@/v2/utils';
import {
  Calendar,
  Clock,
  Download,
  Edit,
  Loader2,
  MoreHorizontal,
  StickyNote,
  Trash,
} from 'lucide-react';
import { useState } from 'react';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import NoteCreator from '@/Notes/NoteCreator';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { getUserNotesQuery } from '@/v2/queries/notes';
import { Tip } from '@/components/common/Tip';

interface NotesListProps {
  notes: Note[];
  onDelete: (noteId: string) => void;
  assetId?: number;
  hostname?: string;
  currentUserEmail?: string;
  showCreateNoteButton?: boolean;
  isLoading?: boolean;
}

export function NotesList({
  notes,
  onDelete,
  assetId,
  hostname,
  currentUserEmail,
  showCreateNoteButton = true,
  isLoading,
}: NotesListProps) {
  const [showNoteCreator, setShowNoteCreator] = useState<boolean>(false);
  const [editNote, setEditNote] = useState<Note | null>(null);
  const handleOpenNoteCreator = (note: Note | null) => {
    setEditNote(note);
    setShowNoteCreator(true);
  };

  const handleCloseNoteCreator = () => {
    setEditNote(null);
    setShowNoteCreator(false);
  };

  const handleDeleteNote = (noteId: string) => {
    onDelete(noteId);
  };

  const handleAfterSave = async () => {
    setShowNoteCreator(false);
    setEditNote(null);
    await getUserNotesQuery({
      verbose: true,
      httpAsset: assetId,
      search: '',
      filter: [],
    });
  };

  const preloadImages = (element: HTMLElement) => {
    const images: HTMLImageElement[] = Array.from(element.getElementsByTagName('img'));
    const loadPromises = images.map(
      (image) =>
        new Promise((resolve, reject) => {
          if (image.complete && image.naturalHeight !== 0) {
            resolve(void 0);
          } else {
            image.onload = resolve;
            image.onerror = reject;
          }
        }),
    );
    return Promise.all(loadPromises);
  };

  const handleDownloadNote = async (note: Note) => {
    const markdownPreviewElement: HTMLElement = document.querySelector(
      `#markdown-preview-${note.id}`,
    ) as HTMLElement;

    if (markdownPreviewElement) {
      await preloadImages(markdownPreviewElement);

      const canvas = await html2canvas(markdownPreviewElement, { useCORS: true });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`note-${note.id}.pdf`);
    }
  };

  return (
    <div>
      <div className="flex justify-between mb-4">
        <div className="flex flex-col">
          <span className="text-sm font-medium">User Notes</span>
          <span className="text-sm text-muted-foreground">Documentation and observations</span>
        </div>
        {showCreateNoteButton && (
          <Button
            className="bg-v2-orange text-white flex items-center gap-2"
            onClick={() => handleOpenNoteCreator(null)}
          >
            <StickyNote className="h-4 w-4" />
            Create Note
          </Button>
        )}
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-row items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span className="text-sm text-muted-foreground">Loading...</span>
          </div>
        </div>
      ) : notes.length > 0 ? (
        <ScrollArea className="h-[calc(100vh-275px)]">
          <div className="flex flex-col gap-8 border-gray-200 border p-4 rounded-md">
            {notes.map((note) => (
              <div key={note.id} className="bg-white rounded-md shadow-sm border border-gray-200">
                <div className="p-4">
                  {/* Note Header */}
                  <div className="flex justify-between items-center mb-4 text-sm">
                    <span className="font-medium">Created by {note.user}</span>
                    <div className="flex items-center gap-4">
                      <Tip content={'Created at'}>
                        <div className="flex items-center gap-1 text-muted-foreground">
                          <Calendar className="h-4 w-4" />
                          <span className="text-sm">{formatShortDateTime(note.created)}</span>
                        </div>
                      </Tip>

                      <Tip content={'Last modified'}>
                        <div className="flex items-center gap-1 text-muted-foreground">
                          <Clock className="h-4 w-4" />
                          <span className="text-sm">{formatShortDateTime(note.last_modified)}</span>
                        </div>
                      </Tip>
                      {currentUserEmail === note.user && (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="icon" className="h-8 w-8">
                              <MoreHorizontal className="h-4 w-4" />
                              <span className="sr-only">More options</span>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              className="cursor-pointer"
                              onClick={() => handleOpenNoteCreator(note)}
                            >
                              <Edit className="mr-2 h-4 w-4" />
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="cursor-pointer"
                              onClick={() => handleDownloadNote(note)}
                            >
                              <Download className="mr-2 h-4 w-4" />
                              Download
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              className="cursor-pointer text-red-500"
                              onClick={() => handleDeleteNote(note.id)}
                            >
                              <Trash className="mr-2 h-4 w-4" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                    </div>
                  </div>
                  <Separator />
                  <div id={`markdown-preview-${note.id}`}>
                    <MarkdownViewer markdown={note.text} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      ) : (
        <div className="flex flex-col gap-4 border-gray-200 border p-4 rounded-md border-gray-200">
          <span className="text-center text-muted-foreground text-sm">No notes found</span>
        </div>
      )}
      {showNoteCreator && (
        <NoteCreator
          headerContent={
            editNote
              ? `Note created on ${formatShortDateTime(editNote.created)}`
              : assetId
                ? `New Note for ${hostname || 'Asset'}`
                : 'New Note'
          }
          initialContent={editNote ? editNote.text : ''}
          noteId={editNote ? editNote.id : null}
          assetId={assetId?.toString()}
          onAfterSave={handleAfterSave}
          onCloseAction={handleCloseNoteCreator}
        />
      )}
    </div>
  );
}
