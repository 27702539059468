import { cn } from '@/lib/utils';
import { Tabs, TabsContent } from '@/components/ui/tabs';
import { useState } from 'react';
import { GEN_AI_TABS } from './constants';
import { LayoutDashboard } from 'lucide-react';
import RemediationAI from '@/v2/icons/Global/RemediationAI';
import AnalysisAI from '@/v2/icons/Global/AnalysisAI';

interface TabProps {
  title: string;
  icon: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const Tab = ({ title, icon, isActive, onClick }: TabProps) => (
  <button
    onClick={onClick}
    className={cn(
      'flex items-center gap-2 px-6 py-3 text-sm font-medium transition-all duration-200 ease-in-out',
      'border-b-2 hover:bg-muted/50',
      isActive
        ? 'border-v2-orange text-v2-orange'
        : 'border-transparent text-muted-foreground hover:text-foreground',
    )}
  >
    {icon}
    <span className="mb-0.5">{title}</span>
  </button>
);

interface TabContent {
  component: React.ComponentType<any>;
  props?: Record<string, any>;
}

interface GenAITabsProps {
  tabContents: Record<keyof typeof GEN_AI_TABS, TabContent>;
  maxHeight?: string;
}

export const GenAITabs = ({ tabContents, maxHeight }: GenAITabsProps) => {
  const [activeTab, setActiveTab] = useState<(typeof GEN_AI_TABS)[keyof typeof GEN_AI_TABS]>(
    GEN_AI_TABS.overview,
  );

  return (
    <Tabs
      value={activeTab}
      onValueChange={(value) =>
        setActiveTab(value as (typeof GEN_AI_TABS)[keyof typeof GEN_AI_TABS])
      }
      className="w-full"
    >
      <div className="flex border-b -mt-2 mb-4">
        <Tab
          title="Overview"
          icon={<LayoutDashboard className="h-4 w-4" />}
          isActive={activeTab === GEN_AI_TABS.overview}
          onClick={() => setActiveTab(GEN_AI_TABS.overview)}
        />
        <Tab
          title="Analysis"
          icon={<AnalysisAI className={cn('w-6 h-6')} />}
          isActive={activeTab === GEN_AI_TABS.analysis}
          onClick={() => setActiveTab(GEN_AI_TABS.analysis)}
        />
        <Tab
          title="Remediation"
          icon={<RemediationAI className={cn('w-6 h-6')} />}
          isActive={activeTab === GEN_AI_TABS.remediation}
          onClick={() => setActiveTab(GEN_AI_TABS.remediation)}
        />
      </div>
      {Object.entries(GEN_AI_TABS).map(([key, value]) => {
        const TabComponent = tabContents[key as keyof typeof GEN_AI_TABS].component;
        const tabProps = tabContents[key as keyof typeof GEN_AI_TABS].props || {};

        return (
          <TabsContent key={value} value={value} className={maxHeight}>
            <TabComponent {...tabProps} />
          </TabsContent>
        );
      })}
    </Tabs>
  );
};
