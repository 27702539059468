import { BackButton } from '@/components/common/BackButton';
import { Button } from '@/components/ui/button';
import { useBestStorage } from '@/hooks/useBestStorage';
import { ResourceVulnerabilityObject } from '@/types/api/response/investigations';
import { NVDCVEvulnscan } from '@/types/api/response/nvd';
import ExpertChat from '@/v2/components/AIChat/ExpertChat';
import { GenAITabs } from '@/v2/components/GenAITabs/GenAITabs';
import {
  DynamicAnalysis,
  DynamicOverview,
  DynamicRemediation,
  PassiveAnalysis,
  PassiveOverview,
  PassiveRemediation,
  VulnerabilityTabsSkeleton,
} from '@/v2/components/VulnerabilityDetails/VulnerabilityTabs';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getPassiveScanResultDetailsQuery,
  getVulnerabilityScanResultDetailsQuery,
} from '../../queries/investigation';
import { PlusIcon, Sparkles } from 'lucide-react';

// Define types for the vulnerability type
export type VulnerabilityType = 'passive' | 'nuclei' | 'other';

// Define type for the context
type ContextType = {
  type: 'vulnerability' | 'httpasset' | 'nuclei';
  id?: string;
  name: string;
  from?: string;
};

export default function PassiveDetails() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [isChatVisible, setIsChatVisible] = useState(true);
  const navigate = useNavigate();
  const vulnerabilityType = (searchParams.get('type') as VulnerabilityType) || 'passive';
  const [, setCreateIssue] = useBestStorage(`resourcevulnerability:${id}:createissue`, null);

  // Use the appropriate query based on vulnerability type
  const passiveQueryOptions = getPassiveScanResultDetailsQuery(id as string);
  const dynamicQueryOptions = getVulnerabilityScanResultDetailsQuery(id as string);

  const passiveQuery = useQuery({
    ...passiveQueryOptions,
    enabled: !!id && vulnerabilityType === 'passive',
  });

  const dynamicQuery = useQuery({
    ...dynamicQueryOptions,
    enabled: !!id && vulnerabilityType === 'nuclei',
  });

  // Determine which query result to use
  const { data: vulnerability, isLoading } =
    vulnerabilityType === 'nuclei' ? dynamicQuery : passiveQuery;

  const handleChatVisibilityChange = (isVisible: boolean) => {
    setIsChatVisible(isVisible);
  };

  const handleCreateIssue = async () => {
    if (!vulnerability) return;

    const descriptionplusAnalysisandRemediation = `
## Description
### Overview
${vulnerability.ai_analysis?.description || 'No description available'}

${
  vulnerability.ai_analysis?.impact
    ? `### Impact
${vulnerability.ai_analysis?.impact}`
    : ''
}

${
  vulnerability.ai_analysis?.evidence
    ? `### Evidence
${vulnerability.ai_analysis?.evidence}`
    : ''
}

## Analysis
${
  vulnerability.ai_KEV
    ? `### Threat Actor Activity
${vulnerability.ai_threat_actor_activity || 'No threat actor activity information available'}
${(() => {
  const refs = vulnerability.ai_threat_actor_activity_references as string[] | null;
  return refs && Array.isArray(refs) && refs.length > 0
    ? `\n**References:**\n${refs.map((ref) => `- ${ref}`).join('\n')}`
    : '';
})()}
`
    : ''
}
### Validation Steps
${
  vulnerability.vulnerability_type === 'nucleitemplates'
    ? vulnerability.ai_validation?.steps
      ? vulnerability.ai_validation.steps.map((step, index) => `${index + 1}. ${step}`).join('\n')
      : 'No validation steps available'
    : vulnerability.ai_validation?.steps.steps
      ? vulnerability.ai_validation.steps.steps
          .map((step, index) => `${index + 1}. ${step}`)
          .join('\n')
      : 'No validation steps available'
}

${
  vulnerability.vulnerability_type !== 'nucleitemplates' && vulnerability.ai_validation?.code
    ? `
### Validation Code
\`\`\`python
${vulnerability.ai_validation?.code}
\`\`\`
`
    : ''
}

${(() => {
  const refs = vulnerability.ai_validation_references as string[] | null;
  return refs && Array.isArray(refs) && refs.length > 0
    ? `\n### Validation References\n${refs.map((ref) => `- ${ref}`).join('\n')}`
    : '';
})()}

## Remediation

### Brief Overview
${vulnerability.ai_remediation?.brief || 'No brief remediation available'}

### Remediation Steps
${
  Array.isArray(vulnerability.ai_remediation?.steps)
    ? vulnerability.ai_remediation.steps.map((step, index) => `${index + 1}. ${step}`).join('\n')
    : 'No remediation steps available'
}

${(() => {
  const refs = vulnerability.ai_remediation_references as string[] | null;
  return refs && Array.isArray(refs) && refs.length > 0
    ? `\n### Remediation References\n${refs.map((ref) => `- ${ref}`).join('\n')}`
    : '';
})()}

${
  vulnerability.ai_mitigation
    ? `
## Mitigation

### Brief Overview
${vulnerability.ai_mitigation.brief || 'No mitigation brief available'}

### Mitigation Steps
${
  Array.isArray(vulnerability.ai_mitigation.steps)
    ? vulnerability.ai_mitigation.steps.map((step, index) => `${index + 1}. ${step}`).join('\n')
    : 'No mitigation steps available'
}

${(() => {
  const refs = vulnerability.ai_mitigation_references as string[] | null;
  return refs && Array.isArray(refs) && refs.length > 0
    ? `\n### Mitigation References\n${refs.map((ref) => `- ${ref}`).join('\n')}`
    : '';
})()}`
    : ''
}`;

    setCreateIssue({
      resourceType: 'HTTPAsset',
      resourceId: vulnerability.resource_object_id?.toString() || '',
      resourceData: {
        id: vulnerability.resource_object_id,
        hostname: vulnerability.hostname,
        port: vulnerability.port,
      },
      vulnType:
        vulnerability.vulnerability_type === 'nucleitemplates'
          ? 'NucleiTemplates'
          : 'NVDVulnerabilities',
      vulnId: vulnerability.vuln_object_id?.toString() || '',
      vulnData: vulnerability,
      description: descriptionplusAnalysisandRemediation,
      resource_vulnerability_object_id: vulnerability.id.toString(),
    });

    navigate(`/issues/new?id=${id}&from=${`investigation/${id}&type=passive`}`);
  };

  if (isLoading || !vulnerability) {
    return <VulnerabilityTabsSkeleton />;
  }

  // Function to determine the correct components based on vulnerability type
  const getTabComponents = () => {
    switch (vulnerabilityType) {
      case 'nuclei':
        return {
          overview: {
            component: () => DynamicOverview(vulnerability as ResourceVulnerabilityObject),
          },
          analysis: {
            component: () => DynamicAnalysis(vulnerability as ResourceVulnerabilityObject),
          },
          remediation: {
            component: () => DynamicRemediation(vulnerability as ResourceVulnerabilityObject),
          },
        };
      case 'passive':
      default:
        return {
          overview: {
            component: () => PassiveOverview(vulnerability as ResourceVulnerabilityObject),
          },
          analysis: {
            component: () => PassiveAnalysis(vulnerability as ResourceVulnerabilityObject),
          },
          remediation: {
            component: () => PassiveRemediation(vulnerability as ResourceVulnerabilityObject),
          },
        };
    }
  };

  // Get expert chat context based on vulnerability type
  const getChatContext = (): ContextType[] => {
    if (vulnerabilityType === 'nuclei') {
      const dynamicVuln = vulnerability as ResourceVulnerabilityObject;
      return [
        {
          type: 'nuclei',
          name: dynamicVuln.vulnerability_name || 'Unknown',
          id: dynamicVuln.vuln_object_id?.toString() || '',
        },
        {
          type: 'httpasset',
          name: dynamicVuln?.hostname
            ? dynamicVuln.hostname.slice(0, 20) + (dynamicVuln.hostname.length > 20 ? '...' : '')
            : 'Unknown asset',
          id: dynamicVuln.resource_object_id?.toString() || '',
          from: `investigation/${dynamicVuln.id}?type=nuclei`,
        },
      ];
    } else {
      const passiveVuln = vulnerability as NVDCVEvulnscan;
      return [
        {
          type: 'vulnerability',
          name: passiveVuln.vulnerability_name || 'Unknown',
          id: passiveVuln.id.toString(),
          from: `investigation/${passiveVuln.id}?type=passive`,
        },
        {
          type: 'httpasset',
          name: passiveVuln.hostname
            ? passiveVuln.hostname.slice(0, 20) + (passiveVuln.hostname.length > 20 ? '...' : '')
            : 'Unknown asset',
          id: passiveVuln.resource_object_id?.toString() || '',
          from: `investigation/${passiveVuln.id}?type=passive`,
        },
      ];
    }
  };

  const tabContents = getTabComponents();
  const chatContext = getChatContext();
  const chatId = `resourcevulnerability:${vulnerability.id}`;
  const chatTitle =
    vulnerabilityType === 'nuclei'
      ? (vulnerability as ResourceVulnerabilityObject).vulnerability_name || 'Vulnerability Details'
      : (vulnerability as NVDCVEvulnscan).vulnerability_name || 'Vulnerability Details';

  return (
    <div className="bg-background">
      <Helmet>
        <title>{`Investigation > ${vulnerability.vulnerability_name}`}</title>
      </Helmet>
      <div className="px-4 container mx-auto">
        <div className="relative">
          <div
            className={`container mr-auto ${isChatVisible ? 'max-w-[calc(100vw-500px)]' : 'max-w-[calc(100vw-300px)]'} p-2`}
          >
            <div className="flex items-center justify-between">
              <BackButton />
            </div>
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-semibold">{vulnerability.vulnerability_name}</h1>
              <Button onClick={handleCreateIssue} className="bg-v2-orange text-white">
                <PlusIcon className="w-4 h-4" /> New Issue
              </Button>
            </div>
            <GenAITabs
              tabContents={tabContents}
              maxHeight="h-[calc(100vh-16rem)] overflow-y-auto"
            />
          </div>
          {isChatVisible && (
            <>
              <ExpertChat
                context={chatContext}
                chatId={chatId}
                title={chatTitle}
                onClose={() => handleChatVisibilityChange(false)}
                onOpen={() => handleChatVisibilityChange(true)}
              />
            </>
          )}
          {!isChatVisible && (
            <div className="fixed right-2 bottom-0 h-full flex items-center group">
              <div className="bg-v2-orange border border-v2-orange/50 p-2 rounded-l-lg shadow-lg flex flex-col gap-2">
                <Button
                  variant="ghost"
                  aria-label="Restore chat"
                  className="group-hover:brightness-110 transition-all hover:bg-v2-orange/50"
                  onClick={() => handleChatVisibilityChange(true)}
                >
                  <Sparkles className="h-8 w-8 text-white" />
                  <span className="text-white font-medium text-sm">Specular AI</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
