import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import AssetSources from '@/GridComponents/AssetSources';
import { useSyncedParams } from '@/hooks/useSynchedState';
import { AssetDetailsWithDetails } from '@/types/api/response/assets';
import { ImageWithModal } from '@/v2/components/ImageWithModal/ImageWithModal';
import { AssetsCurrentStateIcon } from '@/v2/icons/Assets/AssetsSVG';
import { useGetAssetDetails, useGetAssetHistoryQuery } from '@/v2/queries/assets';
import URLDisplay from '@/v2/views/Assets/AssetURLView';
import {
  AlertCircle,
  ArrowRight,
  Calendar,
  Clock,
  Database,
  FileText,
  Globe,
  Hash,
  Info,
  Network,
  Server,
  ShieldAlert,
  User,
} from 'lucide-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HistoryView from './AssetHistoryView';
import AssetDetailVulnerabilities from './AssetVulnerabilitiesView';
import { BackButton } from '@/components/common/BackButton';
import { formatShortDateTime } from '@/v2/utils';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';
import { Badge } from '@/components/ui/badge';
import AssetDetailInfos from './AssetInfosView';
import { Helmet } from 'react-helmet';
import NotesTabDisplay from '@/Notes/NotesTabDisplay';

const tabs = [
  'current state',
  'vulnerabilities',
  'infos',
  'history',
  'urls',
  'Sourced From',
  'Notes',
] as const;

function AssetDetailSkeleton() {
  return (
    <div className="grid grid-cols-2 gap-4 pb-8">
      <div className="space-y-6">
        <div className="mb-4 flex flex-col gap-2">
          <Skeleton className="h-8 w-3/4" />
          <div className="flex flex-row items-center gap-2">
            <Skeleton className="h-6 w-24" />
          </div>
          <Skeleton className="h-6 w-32" />
        </div>

        <div className="space-y-2">
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
        </div>

        <div className="space-y-2">
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
        </div>

        <div className="space-y-2">
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-6 w-full" />
        </div>

        <div className="space-y-2">
          <Skeleton className="h-4 w-48" />
          <Skeleton className="h-4 w-48" />
        </div>
      </div>
      <div>
        <Skeleton className="w-full h-full rounded-lg" />
      </div>
    </div>
  );
}

function AssetDetailCurrentState({ asset }: { asset: AssetDetailsWithDetails }) {
  const ipAddresses = asset.dns_record.ipaddress;
  const { navigateWithParams } = useNavigateWithParams();

  return (
    <div className="grid grid-cols-2 gap-4 pb-8">
      <div className="space-y-6">
        <div className="mb-4 flex flex-col gap-2">
          <h2 className="text-2xl font-bold">Hostname: {asset.hostname}</h2>
          <div className="flex flex-row items-center gap-2">
            <p className="text-lg">
              <span className="font-semibold">IP Address:</span>
            </p>
            {ipAddresses.map((ip) => (
              <LinkWithAction
                showAsButton={true}
                buttonIcon={<Network className="h-4 w-4" />}
                key={ip.ipaddress}
                to={navigateWithParams('ipaddress', ip.ipaddress, `assets/${asset.id}`)}
                dataClickBypass={true}
                title="View IP Address details"
                actions={[]}
              >
                {ip.ipaddress}
              </LinkWithAction>
            ))}
          </div>
          <p className="text-lg">
            <span className="font-semibold">Port:</span> {asset.port}
          </p>
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">DNS Information</h3>
          <p>
            <Globe className="inline mr-2" /> CNAME: {asset.cname ?? 'N/A'}
          </p>
          {asset.dns_record.ipaddress.length > 0 && (
            <>
              <p>
                <Hash className="inline mr-2" /> ASN #: {asset.asnDetails.asn}
              </p>
              <p>
                <Network className="inline mr-2" /> IP range: {asset.asnDetails.iprange}
              </p>
              <p>
                <User className="inline mr-2" /> Owner: {asset.asnDetails.owner}
              </p>
            </>
          )}
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">HTTP Information</h3>
          <p>
            <Server className="inline mr-2" /> Server Header:{' '}
            {asset.homepage_server_header ?? 'N/A'}
          </p>
          <p>
            <FileText className="inline mr-2" /> HTML Title: {asset.homepage_title ?? 'N/A'}
          </p>
          <p>
            <ArrowRight className="inline mr-2" /> Redirect URL:{' '}
            {asset.homepage_redirect_url ?? 'N/A'}
          </p>
          <p>
            <AlertCircle className="inline mr-2" /> HTTP Status Code:{' '}
            {asset.homepage_status_code ?? 'N/A'}
          </p>
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">Technologies</h3>
          <p>
            <Database className="inline mr-2" />
            {asset.technologies.length > 0
              ? asset.technologies.join(', \n')
              : 'No technologies identified'}
          </p>
        </div>

        <div className="space-y-2 text-sm text-gray-600">
          <p>
            <Clock className="inline mr-2" /> Last Scanned:{' '}
            {formatShortDateTime(asset.last_scanned)}
          </p>
          <p>
            <Calendar className="inline mr-2" /> Created At: {formatShortDateTime(asset.created_at)}
          </p>
        </div>
      </div>
      <div>
        <ImageWithModal imageUrl={asset.homepage_image} hostname={asset.hostname} />
      </div>
    </div>
  );
}

type AssetSearchParamsType = {
  tab: (typeof tabs)[number];
  from: string;
  cve?: string;
};

export default function AssetDetailView() {
  const { synchedState, setSynchedState } = useSyncedParams<AssetSearchParamsType>({
    tab: 'current state',
    from: '',
    cve: '',
  });
  const { tab: selectedTab } = synchedState;
  const { id } = useParams();
  const { data: asset, isFetching } = useGetAssetDetails(Number(id));

  const { data: historyData, isFetching: isHistoryFetching } = useGetAssetHistoryQuery({
    assetId: Number(id),
  });

  useEffect(() => {
    if (synchedState.cve) {
      setSynchedState({ tab: 'vulnerabilities' });
    }
  }, [synchedState.cve]);

  const handleSelectedTab = (tab: (typeof tabs)[number]) => {
    setSynchedState({ tab, cve: null });
  };

  const CountBadge = ({ count }: { count: number }) => {
    if (count > 0) {
      return (
        <Badge className="bg-red-500 font-bold text-white text-xs px-1.5 py-0.5 rounded-md">
          {count}
        </Badge>
      );
    }
    return null;
  };

  const TabButton = ({
    tab,
    icon,
    label,
    count,
  }: {
    tab: (typeof tabs)[number];
    icon: React.ReactNode;
    label: string;
    count: number;
  }) => {
    return (
      <Button
        key={tab}
        className="p-0 h-auto rounded-none hover:bg-transparent"
        variant="ghost"
        onClick={() => handleSelectedTab(tab)}
      >
        <div
          className={`flex items-center gap-2 px-4 py-2 m-1 ${selectedTab === tab ? 'bg-white rounded-md text-black shadow-sm border border-gray-100' : 'text-gray-500 hover:text-black'}`}
        >
          {icon}
          <span>{label}</span>
          <CountBadge count={count} />
        </div>
      </Button>
    );
  };

  const renderTab = (tab: (typeof tabs)[number]) => {
    switch (tab) {
      case 'current state':
        return (
          <TabButton tab={tab} icon={<AssetsCurrentStateIcon />} label="Current State" count={0} />
        );
      case 'vulnerabilities':
        return (
          <TabButton
            tab={tab}
            icon={<ShieldAlert className="h-4 w-4" />}
            label="Vulnerabilities"
            count={asset?.vulnerabilities.length}
          />
        );
      case 'infos':
        return (
          <TabButton
            tab={tab}
            icon={<Info className="h-4 w-4" />}
            label="Informational Findings"
            //TODO: Light asset response should include infos_count
            count={
              asset?.nuclei_vulnerabilities.filter((v) => v.nuclei_template.severity === 'info')
                .length
            }
          />
        );
      case 'history':
        return (
          <TabButton
            tab={tab}
            icon={<Clock className="h-4 w-4" />}
            label="History"
            count={asset?.history_count}
          />
        );
      case 'urls':
        return (
          <TabButton
            tab={tab}
            icon={<Globe className="h-4 w-4" />}
            label="URLs"
            count={asset?.total_urls}
          />
        );
      case 'Sourced From':
        return (
          <TabButton
            tab={tab}
            icon={<Database className="h-4 w-4" />}
            label="Sourced From"
            count={0}
          />
        );
      case 'Notes':
        return (
          <TabButton
            tab={tab}
            icon={<FileText className="h-4 w-4" />}
            label="Notes"
            count={asset?.total_notes}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="px-6">
      <Helmet>
        <title>{`Assets > ${asset?.hostname ? asset.hostname : '...'}`}</title>
      </Helmet>
      <div className="space-y-4 mx-auto justify-center">
        <div className="flex justify-between items-center">
          <BackButton />
          <h1 className="text-xl font-semibold text-black">Asset Details</h1>
        </div>

        {asset && (
          <div className="flex mt-4 mb-8 bg-[#F9FAFA] rounded-lg w-fit ml-auto">
            {tabs.map((tab) => renderTab(tab))}
          </div>
        )}

        {isFetching ? (
          <AssetDetailSkeleton />
        ) : (
          asset && (
            <>
              {selectedTab === 'current state' && <AssetDetailCurrentState asset={asset} />}
              {selectedTab === 'vulnerabilities' && <AssetDetailVulnerabilities asset={asset} />}
              {selectedTab === 'infos' && <AssetDetailInfos assetId={asset.id} />}
              {selectedTab === 'history' && (
                <HistoryView
                  rowData={asset}
                  historyData={historyData.entries}
                  isFetching={isHistoryFetching}
                />
              )}
              {selectedTab === 'urls' && <URLDisplay httpAssetId={asset.id} />}
              {selectedTab === 'Sourced From' && <AssetSources entry={asset.domain} />}
              {selectedTab === 'Notes' && (
                <NotesTabDisplay assetId={asset.id} hostname={asset.hostname} />
              )}
            </>
          )
        )}
      </div>
    </div>
  );
}
