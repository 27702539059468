import {
  fetchAssetsStatisticsApi,
  FetchAssetsStatisticsParams,
  fetchIssuesResolutionStatisticsApi,
  FetchIssuesResolutionStatisticsParams,
  fetchIssuesStatisticsApi,
  fetchVulnerabilitiesStatisticsApi,
  FetchVulnerabilitiesStatisticsParams,
  fetchVulnerabilitiesTrendApi,
  FetchVulnerabilitiesTrendParams,
} from '@/api/analytics.api';
import { useQuery } from '@tanstack/react-query';

export const useGetVulnerabilitiesTrendQuery = ({
  start_date,
  end_date,
}: FetchVulnerabilitiesTrendParams) =>
  useQuery({
    queryKey: ['vulnerabilities-trend', start_date, end_date],
    queryFn: () => fetchVulnerabilitiesTrendApi({ start_date, end_date }),
  });

export const useGetVulnerabilitiesStatisticsQuery = ({
  aggregate,
  field,
  remediated_start_date,
  remediated_end_date,
  dashboard_period,
}: FetchVulnerabilitiesStatisticsParams) =>
  useQuery({
    queryKey: [
      'vulnerabilities-statistics',
      aggregate,
      field,
      remediated_start_date,
      remediated_end_date,
      dashboard_period,
    ],
    queryFn: () =>
      fetchVulnerabilitiesStatisticsApi({
        aggregate,
        field,
        remediated_start_date,
        remediated_end_date,
        dashboard_period,
      }),
  });

export const useGetAssetsStatisticsQuery = ({
  start_date,
  end_date,
  dashboard_period,
}: FetchAssetsStatisticsParams) =>
  useQuery({
    queryKey: ['assets-statistics', start_date, end_date, dashboard_period],
    queryFn: () => fetchAssetsStatisticsApi({ start_date, end_date, dashboard_period }),
  });

interface FetchIssuesStatisticsParams {
  dashboard_period: number;
}

export const useGetIssuesStatisticsQuery = ({ dashboard_period }: FetchIssuesStatisticsParams) =>
  useQuery({
    queryKey: ['issues-statistics', dashboard_period],
    queryFn: () => fetchIssuesStatisticsApi({ dashboard_period }),
  });

export const useGetIssuesResolutionStatisticsQuery = ({
  dashboard_period,
}: FetchIssuesResolutionStatisticsParams) =>
  useQuery({
    queryKey: ['issues-resolution-statistics', dashboard_period],
    queryFn: () => fetchIssuesResolutionStatisticsApi({ dashboard_period }),
  });
