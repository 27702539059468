import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { pluralize } from '@/v2/utils';
import { Book, ChevronDown, FileText, Globe } from 'lucide-react';

const extensions = [
  '.pdf',
  '.html',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.json',
];

interface ReferencesCardProps {
  references: string[];
  tab: string;
  defaultOpen?: boolean;
}

export const ReferencesCard = ({ references, tab, defaultOpen = true }: ReferencesCardProps) => {
  const groupedReferences = references.reduce(
    (acc, reference) => {
      const url = new URL(reference);
      const hostname = url.hostname;
      if (!acc[hostname]) acc[hostname] = [];
      acc[hostname].push(reference);
      return acc;
    },
    {} as Record<string, string[]>,
  );

  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex items-center gap-2">
          <Book
            className={`h-5 w-5 ${tab === 'remediation' ? 'text-orange-500' : 'text-red-500'}`}
          />
          <CardTitle>Researched {pluralize(references.length, 'website')}</CardTitle>
        </div>
        <CardDescription>
          {tab === 'overview' &&
            `We've gathered information from ${pluralize(references.length, 'source')} that explain this issue in detail.`}
          {tab === 'remediation' &&
            `We've compiled solutions from ${pluralize(references.length, 'source')} to help you address this issue.`}
          {tab === 'exploit' &&
            `We've analyzed information from ${pluralize(references.length, 'source')} about how this vulnerability works.`}
        </CardDescription>
      </CardHeader>
      {references && references.length > 0 && (
        <CardContent className="text-sm">
          <div className="flex flex-col gap-3">
            {Object.entries(groupedReferences).map(([hostname, refs]) => {
              if (refs.length === 1) {
                return <ReferenceRow key={hostname} reference={refs[0]} />;
              }

              return (
                <Collapsible
                  defaultOpen={defaultOpen}
                  key={hostname}
                  className="border rounded-lg text-sm"
                >
                  <CollapsibleTrigger className="flex w-full items-center justify-between px-3 py-2 hover:bg-accent/5">
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium">{hostname.replace(/^www\./, '')}</span>
                      <Badge variant="default" className="text-xs px-1.5 py-0.5">
                        {refs.length}
                      </Badge>
                    </div>
                    <ChevronDown className="h-4 w-4 transition-transform duration-200 group-data-[state=open]:rotate-180" />
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div className="flex flex-col gap-2 p-4 pt-0">
                      {refs.map((reference) => {
                        return <ReferenceRow key={reference} reference={reference} />;
                      })}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              );
            })}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

const ReferenceRow = ({ reference }: { reference: string }) => {
  const url = new URL(reference);
  const hostname = url.hostname.replace(/^www\./, '');
  const displayText = hostname + url.pathname + url.search + url.hash;
  const hasDocExtension = extensions.some((extension) => url?.pathname.includes(extension));
  return (
    <div
      className="flex flex-row items-center justify-between border bg-gray-50 bg-opacity-50 rounded-lg p-1"
      key={reference}
    >
      <div className="flex flex-row gap-2 items-center px-1 py-1">
        {hasDocExtension ? (
          <FileText className="w-4 h-4 text-gray-700 font-medium" />
        ) : (
          <Globe className="w-4 h-4 text-gray-700 font-medium" />
        )}
        <span className="text-sm font-medium text-gray-700">{hostname.replace(/^www\./, '')}</span>
      </div>
      <a
        href={reference}
        target="_blank"
        rel="noopener noreferrer"
        className="line-clamp-1 font-mono underline-offset-4 text-sm hover:underline px-1 py-1 tracking-tight text-gray-700"
      >
        {displayText.length > 75 ? displayText.slice(0, 75) + '...' : displayText}
      </a>
    </div>
  );
};
