import { ArrowLeft } from 'lucide-react';
import { Button } from '../ui/button';
import { useNavigateWithParams } from '@/hooks/useNavigateWithParams';

interface BackButtonProps {
  label?: string;
  customHandler?: () => void;
}

export function BackButton({ label, customHandler }: BackButtonProps) {
  const { handleBack, fromText } = useNavigateWithParams();

  return (
    <div className="py-2">
      <Button
        variant="ghost"
        size="default"
        onClick={customHandler || handleBack}
        className="text-muted-foreground hover:bg-transparent hover:border-transparent px-0"
      >
        <ArrowLeft className="h-2 w-2" />
        {label || fromText()}
      </Button>
    </div>
  );
}
