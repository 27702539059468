import { Input } from '@/components/ui/input';
import { useEffect, useRef, useState } from 'react';
import { AvailableFilter } from '../UniversalFilter/UniversalFilter';

interface NormalTextFilterProps {
  filter: AvailableFilter;
  onUpdate: (filter: AvailableFilter, value: string) => void;
}

export default function NormalTextFilter({ filter, onUpdate }: NormalTextFilterProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(filter.state as string);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Input
      placeholder={filter.placeholder}
      className="min-w-[200px] w-full"
      value={value}
      type="text"
      onChange={(e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (newValue === '') {
          onUpdate(filter, '');
          filter.onSearch?.();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onUpdate(filter, value);
          filter.onSearch?.();
        }
      }}
      showClearButton={!!value}
      onClear={() => {
        setValue('');
        onUpdate(filter, '');
      }}
      ref={inputRef}
    />
  );
}
