import { Tip } from '@/components/common/Tip';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { AlertCircle, AlertTriangle, FileWarning, Info, Siren } from 'lucide-react';

interface SeverityDisplayProps {
  severity: string;
  showLabel?: boolean;
  showIcon?: boolean;
  size?: number;
}

const severityIcon = (severity: string, textColor: string, showLabel: boolean) => {
  const icons = {
    critical: <Siren size={18} className={textColor} />,
    high: <AlertCircle size={18} className={textColor} />,
    medium: <AlertTriangle size={18} className={textColor} />,
    low: <FileWarning size={18} className={textColor} />,
    informational: <Info size={18} className={textColor} />,
  };

  if (showLabel) {
    return icons[severity as keyof typeof icons];
  }

  return (
    <Tip content={severity.charAt(0).toUpperCase() + severity.slice(1)}>
      {icons[severity as keyof typeof icons]}
    </Tip>
  );
};

export const severityConfig = {
  critical: {
    bgColor: 'bg-red-50',
    textColor: 'text-red-500',
    borderColor: 'border-red-500',
    label: 'Critical',
    color: '#ef4444',
  },
  high: {
    bgColor: 'bg-orange-50',
    textColor: 'text-orange-500',
    borderColor: 'border-orange-500',
    label: 'High',
    color: '#f97316',
  },
  medium: {
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-500',
    borderColor: 'border-yellow-500',
    label: 'Medium',
    color: '#eab308',
  },
  low: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-500',
    borderColor: 'border-green-500',
    label: 'Low',
    color: '#22c55e',
  },
  informational: {
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-500',
    borderColor: 'border-blue-500',
    label: 'Info',
    color: '#0ea5e9',
  },
};

export const getSeverityDisplay = ({
  severity,
  showLabel = true,
  showIcon = true,
}: SeverityDisplayProps) => {
  const config =
    severityConfig[severity as keyof typeof severityConfig] || severityConfig.informational;

  return (
    <div className="flex items-center gap-2">
      {showIcon && (
        <div className={`w-8 h-8 rounded-full ${config.bgColor} flex items-center justify-center`}>
          {severityIcon(severity, config.textColor, showLabel)}
        </div>
      )}
      <Badge
        variant="outline"
        className={cn(config.bgColor, config.textColor, config.borderColor, 'rounded-full')}
      >
        <span className="tracking-wide">{showLabel && config.label.toUpperCase()}</span>
      </Badge>
    </div>
  );
};
