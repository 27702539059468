import { z } from 'zod';

export const AffectedResourceIPAddressV4Schema = z.object({
  type: z.literal('ipaddress'),
  id: z.number(),
  ipaddress: z.string(),
  asn: z.null(),
});
export type AffectedResourceIPAddressV4 = z.infer<typeof AffectedResourceIPAddressV4Schema>;

export const AffectedResourceHTTPAssetSchema = z.object({
  type: z.literal('http_asset'),
  id: z.number(),
  hostname: z.string(),
  ipaddressv4: z.null(),
  domain: z.object({
    id: z.number(),
    domainname: z.string(),
    resolves: z.boolean(),
    alternate_dns_entries: z.array(z.string()),
    dns_record: z.object({
      domainname: z.string(),
      cname: z.string(),
      ipaddress: z.array(
        z.object({
          id: z.number(),
          ipaddress: z.string(),
          asn: z.object({
            iprange: z.string(),
            asn: z.number(),
            owner: z.string(),
          }),
        }),
      ),
      last_seen: z.string(),
    }),
    sources: z.record(z.unknown()),
  }),
  port: z.number(),
  dns_record: z.object({
    domainname: z.string(),
    cname: z.string(),
    ipaddress: z.array(
      z.object({
        id: z.number(),
        ipaddress: z.string(),
        asn: z.object({
          iprange: z.string(),
          asn: z.number(),
          owner: z.string(),
        }),
      }),
    ),
    last_seen: z.string(),
  }),
  alternative_dns_resolution: z.null(),
  last_scanned: z.string(),
  created_at: z.string(),
});
export type AffectedResourceHTTPAsset = z.infer<typeof AffectedResourceHTTPAssetSchema>;

export const AffectedResourceDomainSchema = z.object({
  type: z.literal('domain'),
  id: z.number(),
  domainname: z.string(),
  resolves: z.boolean(),
  alternate_dns_entries: z.array(z.string()),
  dns_record: z.null(),
  sources: z.record(z.unknown()),
});
export type AffectedResourceDomain = z.infer<typeof AffectedResourceDomainSchema>;

export const CustomerSchema = z.object({
  customername: z.string(),
  icon_url: z.string(),
});
export type Customer = z.infer<typeof CustomerSchema>;

export const UserSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  username: z.string(),
  email: z.string(),
  is_staff: z.boolean(),
});
export type User = z.infer<typeof UserSchema>;

export const AssignedToSchema = z.object({
  id: z.number(),
  user: UserSchema,
  customer: CustomerSchema,
});
export type AssignedTo = z.infer<typeof AssignedToSchema>;

export const IssueVulnerabilitySchema = z.object({
  id: z.number(),
  cve_id: z.string(),
  cvss3_base_score: z.number().nullable(),
  cvss3_exploitability_score: z.number().nullable(),
  description: z.string(),
  cpe_uris: z.record(z.unknown()),
  cisa_kev: z.boolean(),
  references: z.array(z.string()),
});
export type IssueVulnerability = z.infer<typeof IssueVulnerabilitySchema>;

export const IssueSchema = z.object({
  id: z.number().readonly(),
  issue_number: z.number().readonly(),
  customer: z.string().readonly(),
  severity: z.enum(['high', 'low', 'medium', 'critical', 'informational']),
  title: z.string().min(1).max(255),
  description: z.string().min(1),
  status: z.enum([
    'draft',
    'new',
    'acknowledged',
    'in_progress',
    'mitigated',
    'resolved',
    'ignored',
  ]),
  is_resolved: z.boolean(),
  affected_resource_model_type: z.string().min(1).nullable(),
  affected_resource_object_id: z.number().nullable(),
  vuln_model_type: z.string().min(1).nullable(),
  vuln_object_id: z.number().nullable(),
  vulnerability: IssueVulnerabilitySchema.nullable(),
  created_by: AssignedToSchema,
  assigned_to_id: z.number().nullable(),
  assigned_to: AssignedToSchema.nullable(),
  resolved_at: z.string().datetime().nullable().readonly(),
  updated_at: z.string().datetime().readonly(),
  created_at: z.string().datetime().readonly(),
  errors: z.array(z.string()).nullable(),
  resource_vulnerabilities: z
    .array(
      z.object({
        id: z.number(),
        http_asset: z
          .object({
            id: z.number(),
            hostname: z.string().nullable(),
            port: z.number().nullable(),
            ipaddress: z.string().nullable(),
            asn: z.string().nullable(),
            domainname: z.string().nullable(),
          })
          .optional(),
        resource_type: z.string(),
        vulnerability: z.object({
          id: z.number(),
          template_id: z.string(),
          name: z.string(),
          creator: z.string(),
          severity: z.string(),
          description: z.string(),
          impact: z.string(),
          remediation: z.string(),
          references: z.array(z.string()),
          tags: z.array(z.string()),
          cpe: z.unknown().nullable(),
          raw_template: z.unknown().nullable(),
          ai_analysis: z.unknown().nullable(),
          ai_remediation: z.unknown().nullable(),
          ai_validation: z.unknown().nullable(),
          ai_last_modified_at: z.unknown().nullable(),
          ai_remediation_references: z.unknown().nullable(),
          ai_validation_references: z.unknown().nullable(),
        }),
        vulnerability_name: z.string(),
        vulnerability_type: z.string(),
        date_added: z.string().datetime(),
        scanned_at: z.string().datetime(),
      }),
    )
    .optional(),
  jira_ticket_created_at: z.string().nullable(),
  jira_ticket_id: z.string().nullable(),
  jira_ticket_url: z.string().nullable(),
});

export const IssuesResponseSchema = z.object({
  entries: z.array(IssueSchema),
  page_number: z.string(),
  total_pages: z.number(),
  total_count: z.number(),
});

export const JiraUserSchema = z.object({
  accountId: z.string(),
  displayName: z.string(),
  emailAddress: z.string(),
  active: z.boolean(),
  avatarUrls: z.object({
    '48x48': z.string(),
    '24x24': z.string(),
    '16x16': z.string(),
    '32x32': z.string(),
  }),
  self: z.string(),
  timeZone: z.string(),
});

export const JiraUsersResponseSchema = z.object({
  success: z.boolean(),
  users: z.array(JiraUserSchema),
});

export type JiraUser = z.infer<typeof JiraUserSchema>;
export type JiraUsersResponse = z.infer<typeof JiraUsersResponseSchema>;

export type Issue = z.infer<typeof IssueSchema>;
export type IssuesResponse = z.infer<typeof IssuesResponseSchema>;
export const ISSUE_STATUSES = {
  draft: 'bg-white-400 border-gray-300 hover:bg-gray-300 text-gray-700',
  new: 'bg-red-200 border-red-400 hover:bg-red-300 text-red-700',
  acknowledged: 'bg-yellow-200 border-yellow-400 hover:bg-yellow-300 text-yellow-700',
  in_progress: 'bg-orange-200 border-orange-400 hover:bg-orange-300 text-orange-700',
  mitigated: 'bg-blue-200 border-blue-400 hover:bg-blue-300 text-blue-700',
  resolved: 'bg-green-200 border-green-400 hover:bg-green-300 text-green-700',
  ignored: 'bg-gray-200 border-gray-400 hover:bg-gray-300 text-gray-700',
} as const;

export const ISSUE_STATUSES_NAMES = {
  draft: 'Draft',
  new: 'New',
  acknowledged: 'Acknowledged',
  in_progress: 'In Progress',
  mitigated: 'Mitigated',
  resolved: 'Resolved',
  ignored: 'Ignored',
} as const;
