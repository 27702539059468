import { BackButton } from '@/components/common/BackButton';
import { useVulnerabilityDetails } from '@/v2/queries/vulnerabilities';
import { useParams } from 'react-router-dom';
import { GenAITabs } from '@/v2/components/GenAITabs/GenAITabs';
import { useState } from 'react';
import {
  CVEAnalysis,
  CVEOverview,
  CVERemediation,
  VulnerabilityTabsSkeleton,
} from '@/v2/components/VulnerabilityDetails/VulnerabilityTabs';
import { Helmet } from 'react-helmet';

export default function VulnerabilityDetails() {
  const { id } = useParams();
  const { data, isLoading: isVulnerabilityLoading } = useVulnerabilityDetails(id);
  const [assetFilter, setAssetFilter] = useState<'all' | 'solved' | 'unsolved'>('all');
  const filteredAssets = data?.http_assets.filter((asset) => {
    if (assetFilter === 'all') return true;
    if (assetFilter === 'solved') return !asset.vulnerability_status.detected_last_scan;
    return asset.vulnerability_status.detected_last_scan;
  });

  if (isVulnerabilityLoading) {
    return <VulnerabilityTabsSkeleton />;
  }

  if (!data) return null;

  const tabContents = {
    overview: {
      component: CVEOverview,
      props: {
        data,
        filteredAssets,
        assetFilter,
        setAssetFilter,
      },
    },
    analysis: {
      component: CVEAnalysis,
      props: { data },
    },
    remediation: {
      component: CVERemediation,
      props: { data },
    },
  };

  return (
    <>
      <div className="container mx-auto p-6">
        <Helmet>
          <title>{`Vulnerability > ${data.cve_id}`}</title>
        </Helmet>
        {window.history.length > 1 && <BackButton />}
        <div className="mb-4">
          <h1 className="text-3xl font-bold mb-2">{data?.cve_id}</h1>
          <p className="text-gray-700">{data?.ai_analysis.brief || data?.description}</p>
        </div>
        <GenAITabs tabContents={tabContents} maxHeight="h-[calc(100vh-19rem)] overflow-y-auto" />
      </div>
    </>
  );
}
