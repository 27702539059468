import { Tip } from './Tip';

export const Ellipsis = ({ text, maxLength }: { text: string; maxLength: number }) => {
  if (text.length <= maxLength) return <span>{text}</span>;

  const displayedText = text.slice(0, maxLength) + '...';

  return (
    <Tip content={<div className="whitespace-pre-wrap">{text}</div>}>
      <span className="text-left">{displayedText}</span>
    </Tip>
  );
};
