import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert';
import { Wrench, Terminal, ListChecks, Shield } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { ReferencesCard } from '../GenAITabs/AIReferences';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';

interface AIRemediationProps {
  brief?: string;
  steps?: string[];
  remediationReferences?: string[];
  mitigation?: boolean;
}

export function AIRemediationSection({
  brief,
  steps,
  remediationReferences,
  mitigation,
}: AIRemediationProps) {
  const title = mitigation ? 'Mitigation' : 'Remediation';
  return (
    <Card className="h-full w-full overflow-y-auto min-h-[calc(100vh-32rem)]">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2">
            {mitigation ? (
              <Shield className="h-5 w-5 text-orange-500" />
            ) : (
              <Wrench className="h-5 w-5 text-orange-500" />
            )}
            {title}
          </div>
        </CardTitle>
        <CardDescription>
          <span className="flex items-center gap-2">
            {mitigation
              ? 'AI-powered mitigation steps to reduce vulnerability impact'
              : 'AI-powered remediation steps to address the vulnerability'}
            <InfoTooltip
              content={
                !mitigation
                  ? 'Fully resolving the issue by eliminating the vulnerability or threat. This is a more permanent fix that ensures the risk is no longer present. Example: Applying a security patch that completely fixes a software vulnerability.'
                  : 'Reducing the impact or likelihood of a threat but not necessarily eliminating it. This often involves implementing controls or workarounds to lessen risk while a full fix is developed or if complete remediation is not feasible.'
              }
            />
          </span>
        </CardDescription>
      </CardHeader>
      <CardContent>
        {brief || steps ? (
          <div className="grid gap-6">
            {brief && (
              <Alert variant="destructive" className="bg-orange-50 border-orange-200">
                <div className="flex items-center gap-2">
                  <Terminal className="h-4 w-4 text-orange-500" />
                  <AlertTitle className="text-orange-700">Overview</AlertTitle>
                </div>
                <AlertDescription className="text-orange-900">
                  <MarkdownViewer
                    markdown={brief}
                    classNameBody="text-sm text-orange-900 -mt-2 -mb-4"
                  />
                </AlertDescription>
              </Alert>
            )}

            {steps && steps.length > 0 && (
              <Card className="min-w-0">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <ListChecks className="h-5 w-5 text-orange-500" />
                    {title} Steps
                  </CardTitle>
                </CardHeader>
                <CardContent className="grid gap-6">
                  {steps.map((step, idx) => (
                    <div key={idx} className="grid gap-2 min-w-0">
                      <div className="flex gap-3 items-start min-w-0">
                        <Badge
                          variant="outline"
                          className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-orange-50 text-orange-700 border-orange-200 mt-0.5"
                        >
                          {idx + 1}
                        </Badge>
                        <div className="flex-1 min-w-0">
                          <MarkdownViewer className="-mt-5" markdown={step} />
                        </div>
                      </div>
                    </div>
                  ))}
                </CardContent>
              </Card>
            )}

            {remediationReferences && remediationReferences.length > 0 && (
              <ReferencesCard references={remediationReferences} tab="remediation" />
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center p-8">
            <div className="text-gray-500">No {title} Steps available yet</div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
