import { z } from 'zod';

/**
 * Standard AI response schema for vulnerability analysis.
 * Contains structured data including risk assessment, analysis details,
 * validation steps, and remediation recommendations provided by the AI system.
 */
export const aiResponseSchema = z.object({
  references: z.array(z.string()),
  ai_risk_score: z.number().nullable(),
  ai_analysis: z.object({
    brief: z.string().nullable(),
    impact: z.string().nullable(),
    evidence: z.string().nullable(),
    description: z.string(),
  }),
  ai_validation: z.object({
    code: z.string().nullable(),
    steps: z.any(),
  }),
  ai_validation_references: z.null(),
  ai_remediation: z.object({
    brief: z.string(),
    steps: z.any(),
  }),
  ai_remediation_references: z.null(),
  ai_mitigation: z.object({
    brief: z.string(),
    steps: z.any(),
  }),
  ai_mitigation_references: z.null(),
  ai_KEV: z.boolean(),
  ai_threat_actor_activity: z.string(),
  ai_threat_actor_activity_references: z.array(z.string()).nullable(),
});

export const severityMap = {
  CRITICAL: 'critical',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
  INFO: 'info',
} as const;

export const VulnerabilityTypeMap = {
  NUCLEI: 'nucleitemplates',
  NVD: 'nvdvulnerabilities',
} as const;

export const ResourceTypeMap = {
  HTTPASSET: 'httpasset',
  IPADDRESSV4: 'ipaddressv4',
  DOMAINNAME: 'domainname',
} as const;

export const ASNOverviewSchema = z.object({
  asn: z.number().nullable(),
  owner: z.string().nullable(),
  count: z.number(),
});

export type ASNOverview = z.infer<typeof ASNOverviewSchema>;

export const resourceTypeEnum = z.enum(Object.values(ResourceTypeMap) as [string, ...string[]]);

export const vulnerabilityTypeEnum = z.enum(
  Object.values(VulnerabilityTypeMap) as [string, ...string[]],
);

// Zod enum for severity levels derived from severityMap values
export const severityEnum = z.enum(Object.values(severityMap) as [string, ...string[]]);

export type Severity = (typeof severityMap)[keyof typeof severityMap];

// Type that can be used with the Zod schema
export type SeverityEnum = z.infer<typeof severityEnum>;

export type AIResponse = z.infer<typeof aiResponseSchema>;
