import { getServices } from '@/api/services.api';
import { Switch } from '@/components/ui/switch';
import { ServiceEntry, ServiceResponse } from '@/types/api/response/services';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { ElementWithActions } from '@/v2/components/ElementWithActions/ElementWithActions';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, {
  AvailableFilter,
  FilterOption,
} from '@/v2/components/UniversalFilter/UniversalFilter';
import { formatShortDateTime } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Filter, Network } from 'lucide-react';
import { useQueryState } from 'nuqs';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { Helmet } from 'react-helmet';

const Services = () => {
  const [showChanges, setShowChanges] = useQueryState<boolean>('show_changes', {
    defaultValue: false,
    parse: (value) => value === 'true',
    serialize: (value) => value.toString(),
  });

  const [fromDate, setFromDate] = useQueryState<string>('from_date', {
    defaultValue: '',
    parse: String,
    serialize: String,
  });

  const [toDate, setToDate] = useQueryState<string>('to_date', {
    defaultValue: '',
    parse: String,
    serialize: String,
  });

  // Existing services states
  const [selectedPorts, setSelectedPorts] = useQueryState<string[]>('ports', {
    defaultValue: [],
    parse: (value) => value.split(',').filter(Boolean),
    serialize: (value) => value.join(','),
  });
  const [selectedServices, setSelectedServices] = useQueryState<string[]>('services', {
    defaultValue: [],
    parse: (value) => value.split(',').filter(Boolean),
    serialize: (value) => value.join(','),
  });
  const [selectedBanners, setSelectedBanners] = useQueryState<string[]>('banners', {
    defaultValue: [],
    parse: (value) => value.split(',').filter(Boolean),
    serialize: (value) => value.join(','),
  });
  const [selectedASNs, setSelectedASNs] = useQueryState<string[]>('asns', {
    defaultValue: [],
    parse: (value) => value.split(',').filter(Boolean),
    serialize: (value) => value.join(','),
  });
  const [page, setPage] = useQueryState<number>('page', {
    defaultValue: 1,
    parse: Number,
    serialize: String,
  });
  const [selectedIP, setSelectedIP] = useQueryState<string>('ipaddress', {
    defaultValue: '',
    parse: String,
    serialize: String,
  });

  const [quickSelect, setQuickSelect] = useState<string | null>(null);

  const sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7))
    .toISOString()
    .split('T')[0];
  const today = new Date().toISOString().split('T')[0];

  // Services query
  const {
    data: servicesData,
    isLoading: isServicesLoading,
    error: servicesError,
  } = useQuery<ServiceResponse>({
    queryKey: [
      'services',
      page,
      selectedPorts,
      selectedServices,
      selectedBanners,
      selectedASNs,
      selectedIP,
      fromDate,
      toDate,
    ],
    queryFn: async () => {
      const response = await getServices({
        page,
        ports: selectedPorts.join(','),
        services: selectedServices.join(','),
        serviceversion: selectedBanners.join(','),
        asn: selectedASNs.join(','),
        ipaddress: selectedIP,
        from_date: fromDate,
        to_date: toDate,
      });
      return response.data;
    },
  });

  // Prefetch 7-day changes query
  useQuery<ServiceResponse>({
    queryKey: [
      'services',
      page,
      selectedPorts,
      selectedServices,
      selectedBanners,
      selectedASNs,
      selectedIP,
      sevenDaysAgo,
      today,
    ],
    queryFn: async () => {
      const response = await getServices({
        page,
        ports: selectedPorts.join(','),
        services: selectedServices.join(','),
        serviceversion: selectedBanners.join(','),
        asn: selectedASNs.join(','),
        ipaddress: selectedIP,
        from_date: sevenDaysAgo,
        to_date: today,
      });
      return response.data;
    },
    enabled: !showChanges, // Only prefetch when not showing changes
    staleTime: 5 * 60 * 1000, // Consider the data fresh for 5 minutes
  });

  const ports = servicesData?.port_overview
    ? servicesData.port_overview.map((port) => ({
        label: `${port.port} (${port.count})`,
        value: port.port.toString(),
      }))
    : [];
  const services = servicesData?.service_overview
    ? servicesData.service_overview.map((service) => ({
        label: `${service.service} (${service.count})`,
        value: service.service,
      }))
    : [];
  const banners = servicesData?.service_version_overview
    ? servicesData.service_version_overview.map((banner) => ({
        label: `${
          banner.service_version === '' ? 'Unknown' : banner.service_version
        } (${banner.count})`,
        value: banner.service_version,
      }))
    : [];
  const asns = servicesData?.asn_overview
    ? servicesData.asn_overview.map((asn) => ({
        label: `${asn.owner} (${asn.count})`,
        value: asn.asn.toString(),
      }))
    : [];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDateRangeChange = (range: DateRange | undefined) => {
    if (range?.from) {
      setFromDate(range.from.toISOString().split('T')[0]);
    }
    if (range?.to) {
      setToDate(range.to.toISOString().split('T')[0]);
    }
    setPage(1);
  };

  const handleShowChangesChange = (value: boolean) => {
    if (!value) {
      setQuickSelect(null);
      setFromDate(null);
      setToDate(null);
    } else {
      setFromDate(
        new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
      );
      setToDate(new Date().toISOString().split('T')[0]);
      setQuickSelect('7days');
      setPage(1);
    }
    setShowChanges(value);
  };

  const handleClearAllFilters = () => {
    setSelectedPorts(null);
    setSelectedServices(null);
    setSelectedBanners(null);
    setSelectedASNs(null);
    setSelectedIP(null);
    if (showChanges) {
      setFromDate(
        new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
      );
      setToDate(new Date().toISOString().split('T')[0]);
      setQuickSelect('7days');
    }
    setPage(1);
  };

  const portOptions: FilterOption[] = ports.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  const serviceOptions: FilterOption[] = services.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  const bannerOptions: FilterOption[] = banners.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  const asnOptions: FilterOption[] = asns.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  const servicesFilters: AvailableFilter[] = [
    {
      label: 'Port',
      state: selectedPorts,
      type: 'multiSelect',
      key: 'ports',
      setState: (values: string[]) => {
        setSelectedPorts(values);
        setPage(1);
      },
      placeholder: 'Select Ports',
      options: portOptions,
    },
    {
      label: 'Service',
      state: selectedServices,
      type: 'multiSelect',
      key: 'services',
      setState: (values: string[]) => {
        setSelectedServices(values);
        setPage(1);
      },
      placeholder: 'Select Services',
      options: serviceOptions,
    },
    {
      label: 'Service Version',
      state: selectedBanners,
      type: 'multiSelect',
      key: 'banners',
      setState: (values: string[]) => {
        setSelectedBanners(values);
        setPage(1);
      },
      placeholder: 'Select Service Versions',
      options: bannerOptions,
    },
    {
      label: 'ASN',
      state: selectedASNs,
      type: 'multiSelect',
      key: 'asn',
      setState: (values: string[]) => {
        setSelectedASNs(values);
        setPage(1);
      },
      placeholder: 'Select ASNs',
      options: asnOptions,
    },
    {
      label: 'IP Address',
      state: selectedIP,
      type: 'text',
      key: 'ipaddress',
      setState: (value: string) => {
        setSelectedIP(value);
        setPage(1);
      },
      placeholder: 'Search IP Addresses',
    },
  ];

  if (showChanges) {
    servicesFilters.push({
      type: 'date-input',
      key: 'date-input',
      label: 'Date Range',
      fromDate,
      toDate,
      state: quickSelect,
      setState: setQuickSelect as (value: string | null) => void,
      placeholder: 'Select date range',
      setDateRange: handleDateRangeChange,
    });
  }

  // Helper functions for change indicators
  const serviceChangesIndicator = (current: string, previous: string) => {
    if (current === previous) {
      return current;
    }

    if (previous === '') {
      return <span className="text-green-800 font-semibold">{current} (New)</span>;
    }

    return (
      <span>
        <span className="text-red-800 line-through font-semibold">{previous}</span>{' '}
        <span className="text-green-800 font-semibold">{current}</span>
      </span>
    );
  };

  const versionChangesIndicator = (current: string, previous: string) => {
    if (!current && !previous) {
      return 'N/A';
    }

    if (current === previous) {
      return current;
    }

    if (current === '') {
      return 'N/A';
    }

    if (previous === '') {
      return <span className="text-green-800 font-semibold">{current} (New)</span>;
    }

    return (
      <span>
        <span className="text-red-800 line-through font-semibold">{previous}</span>{' '}
        <span className="text-green-800 font-semibold">{current}</span>
      </span>
    );
  };

  // Unified columns that show both current state and changes
  const unifiedColumns: ColumnDef<ServiceEntry>[] = [
    {
      header: 'IP Address',
      accessorKey: 'ps_ipaddress',
      cell: ({ row }) => {
        return (
          <LinkWithAction
            to={`/ipaddress/${row.original.ps_ipaddress}?from=assets/services`}
            title="View IP Address"
            dataClickBypass={true}
            actions={[
              {
                label: 'Set IP Filter',
                onClick: () => {
                  setSelectedIP(row.original.ps_ipaddress);
                  setPage(1);
                },
                icon: <Filter className="h-4 w-4" />,
              },
            ]}
            showAsButton={true}
            buttonIcon={<Network className="h-4 w-4" />}
          >
            {row.original.ps_ipaddress}
          </LinkWithAction>
        );
      },
    },
    {
      header: 'Port',
      accessorKey: 'ps_port',
      cell: ({ row }) => {
        return (
          <ElementWithActions
            actions={[
              {
                label: 'Set Port Filter',
                onClick: () => {
                  setSelectedPorts([row.original.ps_port.toString()]);
                  setPage(1);
                },
                icon: <Filter className="h-4 w-4" />,
              },
            ]}
          >
            {row.original.ps_port || 'N/A'}
          </ElementWithActions>
        );
      },
    },
    {
      header: 'Service',
      accessorKey: 'ss_service',
      cell: ({ row }) => {
        const hasServiceChanges =
          showChanges && row.original.ss_previous_service !== row.original.ss_service;

        return (
          <ElementWithActions
            actions={[
              {
                label: 'Set Service Filter',
                onClick: () => {
                  setSelectedServices([row.original.ss_service]);
                  setPage(1);
                },
                icon: <Filter className="h-4 w-4" />,
              },
            ]}
          >
            <div className={hasServiceChanges ? 'bg-green-100 p-2 rounded-md' : ''}>
              {hasServiceChanges
                ? serviceChangesIndicator(row.original.ss_service, row.original.ss_previous_service)
                : row.original.ss_service || 'N/A'}
            </div>
          </ElementWithActions>
        );
      },
    },
    {
      header: 'Service Version',
      accessorKey: 'ss_serviceversion',
      cell: ({ row }) => {
        const hasVersionChanges =
          showChanges && row.original.ss_previous_serviceversion !== row.original.ss_serviceversion;

        return (
          <ElementWithActions
            actions={[
              {
                label: 'Set Version Filter',
                onClick: () => {
                  setSelectedBanners([row.original.ss_serviceversion]);
                  setPage(1);
                },
                icon: <Filter className="h-4 w-4" />,
              },
            ]}
          >
            <div className={hasVersionChanges ? 'bg-green-100 p-2 rounded-md' : ''}>
              {hasVersionChanges
                ? versionChangesIndicator(
                    row.original.ss_serviceversion,
                    row.original.ss_previous_serviceversion,
                  )
                : row.original.ss_serviceversion || 'N/A'}
            </div>
          </ElementWithActions>
        );
      },
    },
    {
      header: 'ASN',
      accessorKey: 'asn_information',
      cell: ({ row }) => {
        return <div>{row.original.asn_information?.owner || 'N/A'}</div>;
      },
    },
    {
      header: 'Last Scanned',
      accessorKey: 'ps_last_scanned',
      cell: ({ row }) => {
        return <div>{formatShortDateTime(row.original.ps_last_scanned)}</div>;
      },
    },
  ];

  return (
    <>
      <div className="p-4 max-w-[2000px] mx-auto">
        <Helmet>
          <title>{`Assets > Services`}</title>
        </Helmet>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-4">
            <UniversalFilter filters={servicesFilters} clearAllFilters={handleClearAllFilters} />
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 whitespace-nowrap">
                <Switch
                  checked={showChanges}
                  onCheckedChange={handleShowChangesChange}
                  id="show-changes"
                />
                <label htmlFor="show-changes" className="text-sm font-medium">
                  Show Changes
                </label>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={unifiedColumns}
          data={servicesData?.entries ?? []}
          loading={isServicesLoading}
          currentPage={page}
          totalPages={servicesData?.total_pages ?? 1}
          totalEntries={servicesData?.total_count ?? 0}
          onPageChange={handlePageChange}
          error={servicesError}
          tableHeight="calc(100vh - 220px)"
        />
      </div>
    </>
  );
};

export default Services;
